import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import print from 'print-js';
import { combineLatest, from } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  AuthService,
  ClientUxService,
  CompanyUxService,
  InsuranceProviderUxService,
  PromptService,
  ServiceLogUxService,
  UserUxService,
} from 'src/app/services';
import { ServiceLog, UxQuery, UxState, UxStore } from 'src/app/store';
import {
  convertToCSVAndDownload,
  downloadUrl,
  printableCss,
} from 'src/app/util/client-util';
import { PaginatedResults } from 'src/app/util/pageinated-results.interface';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FunctionService,
  LogForReport,
} from 'src/app/services/function.service';
import { Compat } from '@firebase/util';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { getDateTime } from 'src/app/util/date-util';
import { DialogFormComponent, DialogFormOptions } from 'src/app/formly';
import { MatDialog } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'app-service-log',
  templateUrl: './service-log-report.component.html',
  styles: [``],
})
export class ServiceLogReportComponent implements OnInit {
  data: PaginatedResults<ServiceLog> = null;

  loading: boolean;

  get resultsAvailable() {
    return !this.loading && this.data?.results?.length;
  }

  filterModel: UxState['reports'];

  columns: string[] = [
    'client',
    'dateString',
    'staff',
    'minutes',
    'units',
    'location',
    'category',
    'billing',
    'edit',
  ];

  constructor(
    private serviceUx: ServiceLogUxService,
    private uxQuery: UxQuery,
    private uxStore: UxStore,
    private userUxService: UserUxService,
    private clientUxService: ClientUxService,
    private insuranceProviderUx: InsuranceProviderUxService,
    private prompt: PromptService,
    private auth: AuthService,
    private fnc: FunctionService,
    private companyUx: CompanyUxService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.filterModel = this.uxQuery.getValue().reports;

    const logs$ = this.uxQuery.selectReportFilters.pipe(
      skip(1),
      distinctUntilChanged((a, b) => {
        return (
          a.dateStart.toDateString() === b.dateStart.toDateString() &&
          a.dateEnd.toDateString() === b.dateEnd.toDateString() &&
          a.dateFilter === b.dateFilter &&
          a.clientId === b.clientId &&
          a.userId === b.userId
        );
      }),
      tap(() => (this.loading = true)),
      switchMap(f =>
        from(
          this.serviceUx.getForDates(
            f.dateFilter,
            f.dateStart,
            f.dateEnd,
            f.userId,
            f.clientId
          )
        )
      ),
      tap(() => (this.loading = false))
    );

    combineLatest([this.uxQuery.selectReportFilters, logs$])
      .pipe(
        map(([filters, logs]) => {
          const filtered: ServiceLog[] = logs
            .filter(l =>
              filters.locations.length
                ? filters.locations.includes(l.location)
                : true
            )
            .filter(l =>
              filters.billingType.length
                ? filters.billingType.includes(l.billingStatus)
                : true
            );

          return {
            results: filtered.slice(
              filters.pageSize * filters.page,
              filters.pageSize * filters.page + filters.pageSize
            ),
            allResults: filtered,
            pageIndex: filters.page,
            length: filtered.length,
            pageSize: filters.pageSize,
          };
        }),
        untilDestroyed(this)
      )
      .subscribe(val => {
        this.data = val;
      });
  }

  download() {
    this.prompt
      .confirm('This will download ALL the results as a CSV file')
      .subscribe(val => {
        if (!val) {
          return;
        }

        convertToCSVAndDownload(
          this.normalizeLogs(this.data.allResults),
          'service-log-report'
        );
      });
  }

  normalizeLogs(logs: ServiceLog[]): LogForReport[] {
    return logs.map(d => {
      const staff = this.userUxService.getEntity(d.userId);
      const ip = this.insuranceProviderUx.getEntity(d.insuranceProviderId);
      return {
        client: this.clientUxService.getEntity(d.clientId)?.displayName,
        staff: staff?.displayName,
        date: d.dateString,
        time: `${d.startTimeString}-${d.endTimeString}`,
        minutes: d.minutes,
        units: d.units,
        hours: (d.minutes / 60).toFixed(2),
        ...(this.auth.isProjectAdmin
          ? {
              rate: staff?.payRate,
              amount: ((staff?.payRate || 0) * (d.minutes / 60)).toFixed(2),
            }
          : {}),
        location: d.location,
        category: d.category,
        billingStatus: d.billingStatus || 'NOT BILLED YET',
        insurance: ip?.name,
        notes: d.notes,
      };
    });
  }

  pageChange(val: PageEvent) {
    this.uxStore.updateReport({
      page: val.pageIndex,
      pageSize: val.pageSize,
    });
    document.getElementsByTagName('app-report-filters')[0].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }

  pdf() {
    const fields: FormlyFieldConfig[] = [
      {
        template: `<p>This will generate a audit style PDF document based on your current search results (all pages). You can include additional information that will be included in the top of the report here</p>`,
      },
      {
        key: 'notes',
        type: 'textarea',
        templateOptions: {
          label: 'Notes',
        },
      },
      {
        key: 'author',
        type: 'input',
        defaultValue: this.auth.projectUser.displayName,
        templateOptions: {
          label: 'Author',
        },
      },
      {
        key: 'date',
        type: 'input',
        defaultValue: getDateTime().toLocaleString(),
        templateOptions: {
          label: 'Date',
        },
      },
    ];

    const options: DialogFormOptions = {
      fields: fields,
      model: {},
      title: 'Generate Audit Report',
      labels: { primary: 'Create Report' },
      saveOnPristine: true,
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    dialog.afterClosed().subscribe(val => {
      if (val) {
        this.doPdf(val);
      }
    });
  }

  doPdf(meta: { author: string; date: string; notes: string }) {
    this.loading = true;
    const reportFilters = this.uxStore.getValue().reports;
    const company = this.companyUx.getActive();
    const user = this.userUxService.getEntity(reportFilters.userId);
    const client = this.clientUxService.getEntity(reportFilters.clientId);
    const filters = {
      company: company.name,
      dates: `${reportFilters.dateStart.toDateString()}-${reportFilters.dateEnd.toDateString()}`,
      dateFilter:
        reportFilters.dateFilter === 'dateString'
          ? 'Service Date'
          : 'Billed Date',
      billingType: reportFilters.billingType.join(', '),
      staff: user?.displayName,
      client: client?.displayName,
      location: reportFilters.locations.join(', '),
    };

    this.fnc
      .caseNotePdf(this.normalizeLogs(this.data.allResults), filters, meta)
      .subscribe(val => {
        this.loading = false;
        if (!val.success) {
          this.prompt.alert('There was an error creating the PDF');
          return;
        }
        const newWin = window.open(val.data.url);
        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
          this.prompt
            .alert({
              text: 'You browser is blocking pop-ups. Click here to open the PDF',
              okText: 'Open PDF',
            })
            .subscribe(resp => {
              if (resp) {
                window.open(val.data.url);
              }
            });
        }

        console.log(val);
      });
  }

  print() {
    if (this.data.length <= this.data.pageSize) {
      this.doPrint();
      return;
    }
    this.prompt
      .alert(
        'Note this will only print the current page of results. You can increase your page size, or use one of the other options (PDF, SpreadSheet) '
      )
      .subscribe(() => {
        this.doPrint();
      });
  }

  doPrint() {
    print({
      header: 'Case Note Report',
      printable: 'log-report-services-table',
      type: 'html',
      // targetStyles: '*',
      style: printableCss,
      // scanStyles: true,
    });
  }
}
