import { Injectable } from '@angular/core';

import { QueryConfig, QueryEntity } from '@datorama/akita';

import { LocationState, LocationStore } from './location.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'name' })
export class LocationQuery extends QueryEntity<LocationState> {
  constructor(protected store: LocationStore) {
    super(store);
  }
}
