import { Component, OnInit } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { FieldType } from '@ngx-formly/core';

import { calcUnits, InsuranceProvider } from '../store';

@UntilDestroy()
@Component({
  selector: 'formly-provider-price-check',
  template: `<mat-card>
    <mat-card-title>Check your math</mat-card-title>
    <div class="flex-row">
      <mat-form-field>
        <mat-label>Test value</mat-label>
        <input
          matInput
          placeholder="Minutes"
          #testValInput
          (keyup)="setTestValue(testValInput.value)"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Calculated Units</mat-label>
        <input readonly matInput placeholder="Units" [value]="calcValue" />
      </mat-form-field>
    </div>
  </mat-card>`,
  styles: [
    `
      :host ::ng-deep .mat-form-field-infix {
        width: 100px;
      }
      .flex-row {
        justify-content: space-around;
      }
    `,
  ],
})
export class FormlyProviderPriceCheck extends FieldType implements OnInit {
  get value(): InsuranceProvider {
    return this.model;
  }

  calcValue: number = 0;
  testValue: number = 0;

  ngOnInit(): void {}

  setTestValue(val: string) {
    this.testValue = Number(val);
    this.doMath();
  }

  doMath() {
    this.calcValue = calcUnits(this.value, this.testValue);
    // if (!this.testValue) {
    //   this.calcValue = 0;
    //   return;
    // }
    // let val: number;
    // val = this.testValue / (this.value.minutesToUnits || 0);
    // if (this.value.alwaysRoundUp) {
    //   this.calcValue = Math.ceil(val);
    // } else {
    //   this.calcValue = round(val);
    // }
  }
}
