import { Component, Input, OnInit } from '@angular/core';

import { Client } from 'src/app/store';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
})
export class ClientInfoComponent implements OnInit {
  @Input() client: Client = {};

  showSSn = false;

  get photoUrl(): string {
    if (this.client?.hasPhoto) {
      return `https://s3.amazonaws.com/plakeside.com/${this.client.photoUrl}`;
    }
    return '/assets/profile.png';
  }

  get hiddenSSN() {
    return this.client?.socialSecurityNumber
      ? '*'.repeat(this.client.socialSecurityNumber.length - 4) +
          this.client.socialSecurityNumber.slice(
            this.client.socialSecurityNumber.length - 4
          )
      : null;
  }

  constructor() {}

  ngOnInit(): void {}
}
