import { Injectable } from '@angular/core';

import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { ApiFile } from './file.model';

export interface FilesState
  extends EntityState<ApiFile, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'files' })
export class FilesStore extends EntityStore<FilesState> {
  constructor() {
    super();
  }
}
