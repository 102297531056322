import { Injectable } from '@angular/core';

import {
  QueryConfig,
  QueryEntity,
} from '@datorama/akita';

import {
  InsuranceProviderState,
  InsuranceProviderStore,
} from './insurance-provider.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'name' })
export class InsuranceProviderQuery extends QueryEntity<InsuranceProviderState> {
  constructor(protected store: InsuranceProviderStore) {
    super(store);
  }
}
