<div class="login-container">
  <div class="login-sub-container">
    <div class="logo-parent">
      <div class="logo-container">
        <mat-icon class="main-logo">edit_square</mat-icon>
      </div>
    </div>
    <mat-toolbar color="primary">
      <h1>Case Notes Online</h1>
    </mat-toolbar>
    <mat-progress-bar
      *ngIf="submitting"
      mode="indeterminate"
    ></mat-progress-bar>
    <p style="text-align: center">
      You must login using the email address associated with your account.
    </p>
    <ng-container *ngIf="!emailLinkRequested">
      <div class="login-buttons">
        <div
          role="button"
          class="login-button mat-elevation-z4"
          (click)="loginWithGoogle()"
        >
          <img class="login-icon" src="/assets/google-icon.png" />
          <span>Login with Google</span>
        </div>
        <div
          role="button"
          class="login-button mat-elevation-z4"
          (click)="loginWithMicrosoft()"
        >
          <img class="login-icon" src="/assets/ms-icon.png" />
          <span>Login with Microsoft</span>
        </div>
      </div>
      <div class="explainer">
        <div>Use Google if you have a @gmail or your company uses GSuite</div>
        <div>
          Use Microsoft if you have a @live, @hotmail or your company uses
          Office 365
        </div>
      </div>
      <div class="or-container">- or -</div>
      <!-- Email Link -->
      <mat-card class="mat-elevation-z4">
        <div
          role="button"
          class="login-button"
          (click)="showEmailLinkForm = true"
        >
          <img class="login-icon" src="/assets/email-link-icon.png" />
          <span>Login with email link sent to you</span>
        </div>
        <ng-container *ngIf="showEmailLinkForm">
          <mat-card-content>
            <form>
              <mat-form-field>
                <mat-label>Email Address</mat-label>
                <input
                  #emailLinkInput
                  matInput
                  type="email"
                  required
                  placeholder="Email Address"
                />
                <mat-error> Email address required </mat-error>
              </mat-form-field>
            </form>
          </mat-card-content>
          <mat-card-actions>
            <div class="flex-row">
              <button mat-button (click)="showEmailLinkForm = false">
                Cancel
              </button>
              <div class="flex"></div>
              <button
                [disabled]="submitting || !isEmailValid(emailLinkInput.value)"
                mat-raised-button
                color="primary"
                (click)="loginWithMagicLink(emailLinkInput.value)"
              >
                Send Link
              </button>
            </div>
          </mat-card-actions>
        </ng-container>
      </mat-card>
    </ng-container>
    <ng-container *ngIf="emailLinkRequested">
      <mat-card>
        <mat-card-content>
          <p>
            Your email link has been requested for {{ emailLinkRequested }}.
            Check your email (check your spam). This window can be closed.
          </p>
        </mat-card-content>
        <mat-card-actions>
          <div class="flex-row">
            <button
              mat-button
              (click)="emailLinkRequested = null; showEmailLinkForm = false"
            >
              Reset
            </button>
            <div class="flex"></div>
            <button mat-raised-button color="primary" (click)="closeWindow()">
              Close window
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </ng-container>
  </div>
  <div class="help-container">
    <button mat-button (click)="help()">
      <mat-icon>help_center</mat-icon>
      <span> Help</span>
    </button>
  </div>
</div>
