import { Component, OnInit } from '@angular/core';
import {
  collectionChanges,
  collectionGroup,
  Firestore,
  query,
  where,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';

import { startWith } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PromptService } from '../services';
import { AuthService } from '../services/auth-service.service';
import { Company, CompanyService, UxStore } from '../store';

@UntilDestroy()
@Component({
  selector: 'app-company-selection',
  template: `
    <mat-card *ngIf="!loading">
      <mat-card-title> Choose a company </mat-card-title>
      <mat-nav-list role="list" *ngIf="companies.length">
        <a
          mat-list-item
          *ngFor="let company of companies"
          [routerLink]="['comp', company.id]"
          >{{ company.name }}</a
        >
      </mat-nav-list>
      <mat-card-actions>
        <div *ngIf="!companies.length">
          <p class="no-companies-found">
            There is no access granted for "{{ currentUserId }}". You must login
            with email address associated with your account. If you feel you
            have not made an error please contact your administrator to have you
            added.
          </p>
        </div>
        <button mat-button routerLink="/logout">Logout</button>
      </mat-card-actions>
    </mat-card>
    <div class="help-container">
      <button mat-button (click)="help()">
        <mat-icon>help_center</mat-icon>
        <span> Help</span>
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
      }
      mat-card {
        max-width: 100%;
        width: 400px;
        margin: 0 auto;
      }
      .help-container {
        text-align: center;
        position: fixed;
        bottom: 25px;
        width: 100%;
      }
    `,
  ],
})
export class CompanySelectionComponent implements OnInit {
  companies: Company[] = [];
  loading = true;
  currentUserId: string;

  constructor(
    private firestore: Firestore,
    private companyService: CompanyService,
    private auth: AuthService,
    private router: Router,
    private uxStore: UxStore,
    private prompt: PromptService
  ) {}

  ngOnInit(): void {
    this.currentUserId = this.auth.currentUserId;

    collectionChanges(
      query(
        collectionGroup(this.firestore, 'users'),
        where('id', '==', this.currentUserId || ''),
        where('access', '!=', 'disabled')
      )
    )
      .pipe(startWith([]), untilDestroyed(this))
      .subscribe(e => {
        this.loading = true;
        const companyIds = e.map(i => i.doc.data().companyId);
        if (companyIds.length === 0) {
          this.noCompaniesFound();
        } else if (companyIds.length === 1) {
          this.oneCompanyFound(companyIds[0]);
        } else {
          this.uxStore.updateApp({
            multiCompany: true,
          });
          this.companyService.getValue(companyIds).then(val => {
            const companies = val.sort((a, b) => a.name.localeCompare(b.name));
            this.manyCompaniesFound(companies);
          });
        }
        this.loading = false;
      });
  }

  manyCompaniesFound(companies: Company[]) {
    this.companies = companies;
    this.loading = false;
  }

  noCompaniesFound() {
    this.companies = [];
    this.loading = false;
  }

  oneCompanyFound(id: string) {
    console.log('redirect');
    this.router.navigate(['/app/comp', id]);
  }

  help() {
    this.prompt.alert({
      title: 'Contact Us!',
      html: `
        <p>
          Please contact us at 
          <a href="mailto:support@casenotesonline.com">support@casenotesonline.com</a>
          with any issues you have.
        </p>
      `,
    });
  }
}
