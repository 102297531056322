import { Component, OnInit } from '@angular/core';

import { NoteUxService } from 'src/app/services/note-ux.service';
import { Note } from 'src/app/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-client-note-table',
  template: ` <table
      class="table-alpha"
      mat-table
      #table
      [dataSource]="notes"
      *ngIf="notes.length"
    >
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Created At</th>
        <td mat-cell *matCellDef="let row">
          {{ row.createdAt.seconds | timestampDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef>Note</th>
        <td mat-cell *matCellDef="let row">{{ row.note }}</td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef>createdBy</th>
        <td mat-cell *matCellDef="let row">{{ row.createdBy | user }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="action">
          <button mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        class="pointer"
        (click)="edit(row)"
        *matRowDef="let row; columns: columns"
      ></tr>
    </table>
    <app-no-results *ngIf="!notes.length"></app-no-results>`,
  styles: [
    `
      .mat-column-createdBy,
      .mat-column-createdAt {
        width: 175px;
        max-width: 175px;
      }
    `,
  ],
})
export class ClientNoteTableComponent implements OnInit {
  notes: Note[] = [];

  columns = ['createdAt', 'note', 'createdBy', 'edit'];

  constructor(private uxService: NoteUxService) {}

  ngOnInit(): void {
    this.uxService
      .selectAllForActiveClient()
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.notes = val;
      });
  }

  edit(note: Note) {
    this.uxService.edit(note).subscribe();
  }
}
