import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-no-results',
  template: ` <mat-card
    ><p>
      <span> No {{ label }} </span> <ng-content></ng-content>
    </p>
  </mat-card>`,
  styles: [
    `
      :host {
        display: block;
        margin: 25px;
      }
      p {
        text-align: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoResultsComponent implements OnInit {
  @Input() label = 'Records';

  constructor() {}

  ngOnInit(): void {}
}
