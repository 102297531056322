import { Injectable } from '@angular/core';
import { Auth, authState, signOut, User as FbUser } from '@angular/fire/auth';

import { map, tap } from 'rxjs/operators';

import { User as ProjectUser, UserService } from '../store';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser!: FbUser;

  projectUser: ProjectUser = null;

  isProjectAdmin: boolean = false;

  get currentUserId() {
    return (
      this.currentUser?.email ||
      this.currentUser.providerData[0]?.email ||
      this.currentUser.providerData[1]?.email
    );
  }

  constructor(private auth: Auth, private userService: UserService) {}

  isLoggedIn() {
    return authState(this.auth).pipe(
      tap(v => {
        this.currentUser = v!;
      }),
      map(v => !!v)
    );
  }

  logout() {
    return signOut(this.auth);
  }
}
