import { Injectable } from '@angular/core';

import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { Template } from './template.model';

export interface TemplateState
  extends EntityState<Template, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'template', resettable: true })
export class TemplateStore extends EntityStore<TemplateState> {
  constructor() {
    super();
  }
}
