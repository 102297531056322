import { Component } from '@angular/core';
import { doc, docSnapshots, Firestore } from '@angular/fire/firestore';

import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UAParser } from 'ua-parser-js';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import versionInfo from '../version.json';
import { PromptService } from './services';

@UntilDestroy()
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>
    <div *ngIf="isSafari" class="banner-footer">
      Please note we are working on issues with Safari. Please use Chrome or
      FireFox temporarily while we work on these issues.
    </div>
    <div *ngIf="hasUpdate" class="banner-footer">
      The system has been updated. Please click
      <a href="#" (click)="reload()">HERE</a> to reload.
    </div>`,
  styles: [
    `
      .banner-footer {
        display: block;
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 99999;
        border: 1px solid black;
        padding: 5px;
        font-size: 18px;
        border-radius: 5px;
        color: white;
        background-color: red;
        margin: 5px;
        margin-inline-start: 200px;
      }
    `,
  ],
})
export class AppComponent {
  hasUpdate = false;
  isSafari = false;

  constructor(private db: Firestore, private prompt: PromptService) {
    const parser = new UAParser();
    this.isSafari = parser.getResult().browser.name === 'Safari';

    const versionUpdate = docSnapshots(doc(this.db, '/version/admin'));

    versionUpdate
      .pipe(untilDestroyed(this))
      .pipe(
        filter(val => val.exists()),
        map(val => val.data()),
        pluck('updatedAt'),
        distinctUntilChanged()
      )
      .subscribe(val => {
        this.checkVal(val);
      });
  }

  checkVal(fbDate: string) {
    console.log('[Version: FIREBASE]', fbDate);
    if (environment.production && fbDate > versionInfo.date) {
      this.hasUpdate = true;
      this.promptReload();
    }
  }

  promptReload() {
    this.prompt
      .confirm(
        'The system received a software update. Please hit OK, to restart the system. Or, if you are in the middle of something, hit cancel and refresh your browser when you are done.'
      )
      .subscribe(val => {
        if (!val) {
          return;
        }
        this.reload();
      });
  }

  reload() {
    window.location.reload();
  }
}
