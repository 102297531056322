import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { UntilDestroy } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { PromptService } from '../services/prompt/prompt.service';

export interface DialogCloseEvent {
  type: 'Escape' | 'Cancel' | 'Backdrop';
}

export interface DsAction<T = string | number, M = any> {
  type: T;
  payload?: M;
  label?: string;
  icon?: string;
  subMenu?: DsAction<T, M>[];
  disabled?: ((m: M) => boolean) | boolean;
  stopAutoClose?: boolean | number;
}

export interface DialogFormOptions<T = any> {
  fields: FormlyFieldConfig[];
  formGroup?: UntypedFormGroup;
  model: T;
  labels?: { close?: string; primary?: string };
  submit?: (payload: any) => Observable<boolean>;
  saveOnPristine?: boolean;
  closeOnEscape?: boolean;
  closeOnBackdropClick?: boolean;
  title?: string;
  remove?: () => any;
  readonly?: boolean;
  readonlyMessage?: string;
}

@UntilDestroy({ arrayName: 'subs' })
@Component({
  selector: 'app-dialog-form',
  template: `
    <h1 mat-dialog-title *ngIf="title">{{ title }}</h1>
    <mat-dialog-content>
      <app-form
        #formEl
        [fields]="fields"
        [model]="model"
        [form]="form"
        (action)="doAction($event)"
      >
      </app-form>
      <p *ngIf="readonly && readonlyMessage" class="readonly-message">
        {{ readonlyMessage }}
      </p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        *ngIf="!readonly && data.remove"
        type="button"
        mat-button
        color="warn"
        (click)="remove()"
        matTooltip="Delete"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <div style="flex: 1"></div>
      <button type="button" mat-button (click)="closeDialog($event)">
        {{ labels?.close }}
      </button>
      <button
        *ngIf="!readonly"
        type="button"
        mat-stroked-button
        color="primary"
        [disabled]="!allowSave"
        (click)="formEl.submit()"
      >
        {{ labels?.primary }}
      </button>
    </mat-dialog-actions>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
        width: 400px;
        max-width: 95%;
      }

      .readonly-message {
        text-align: center;
        padding: 5px;
        border: 3px solid #ffd587;
        border-radius: 5px;
      }
    `,
  ],
})
export class DialogFormComponent implements OnDestroy, OnInit {
  public form = new UntypedFormGroup({});
  private sub: Subscription | undefined;
  private isDirty = false;
  model: any = {};
  readonly = false;
  readonlyMessage = '';

  closeOnBackDropClick: boolean | undefined = false;
  closeOnEscape: boolean | undefined = true;

  labels: DialogFormOptions['labels'] = {
    close: 'Cancel',
    primary: 'Save',
  };

  title: string = '';

  get allowSave() {
    return (this.data.saveOnPristine || this.form.dirty) && this.form.valid;
  }

  constructor(
    public dialogRef: MatDialogRef<DialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogFormOptions,
    private askService: PromptService
  ) {
    dialogRef.disableClose = true;

    this.readonly = !!this.data.readonly;
    this.readonlyMessage = this.data.readonlyMessage;

    this.labels = {
      ...this.labels,
      ...this.data.labels,
    };

    if (this.data.title) {
      this.title = this.data.title;
    }

    if (
      data.closeOnBackdropClick === false ||
      this.closeOnBackDropClick === true
    ) {
      this.closeOnBackDropClick = data.closeOnBackdropClick;
    }

    if (data.closeOnEscape === true || this.closeOnEscape === false) {
      this.closeOnEscape = data.closeOnEscape;
    }

    if (data.formGroup) {
      this.form = data.formGroup;
    }

    this.model = JSON.parse(JSON.stringify(data.model));
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.dialogRef
      .backdropClick()
      .subscribe((result: MouseEvent) =>
        this.handleDialogClose(result, { type: 'Backdrop' })
      );
    this.dialogRef
      .keydownEvents()
      .subscribe((result: KeyboardEvent) =>
        result && result.code === 'Escape'
          ? this.handleDialogClose(result, { type: 'Escape' })
          : ''
      );
  }

  closeDialog($event: MouseEvent): void {
    this.handleDialogClose($event, { type: 'Cancel' });
  }

  private confirmDialogClose(): Subscription {
    return this.askService
      .confirm({
        title: `The form has pending changes, are you sure you want to close it?`,
      })
      .pipe(filter(Boolean))
      .subscribe(() => this.dialogRef.close());
  }

  doAction({ type, payload }: DsAction): any {
    switch (type) {
      case 'SUBMIT':
        return this.sendForm(payload);
      case 'ISDIRTY':
        return this.updateIsDirty(payload);
      default:
        console.log('Unhandled action', { type, payload });
    }
  }

  private handleDialogClose(
    $event: MouseEvent | KeyboardEvent,
    payload: DialogCloseEvent
  ): Subscription | void {
    if (payload && payload.type === 'Escape' && !this.closeOnEscape) {
      return;
    }

    if (payload && payload.type === 'Backdrop' && !this.closeOnBackDropClick) {
      return;
    }

    $event.stopPropagation();

    if (payload && this.isDirty) {
      return this.confirmDialogClose();
    } else {
      this.dialogRef.close();
    }
  }

  sendForm(payload: any): void {
    if (this.readonly) {
      console.log('Form is readonly, can not be saved.');
      return;
    }
    if (!this.data.submit) {
      return this.dialogRef.close(payload);
    }
    this.sub = this.data.submit(payload).subscribe(success => {
      if (success) {
        this.dialogRef.close(payload);
      }
    });
  }

  get fields(): FormlyFieldConfig[] {
    return this.data.fields;
  }

  updateIsDirty(payload: any): any {
    this.isDirty = payload;
  }

  remove() {
    if (this.data.remove) {
      this.data.remove();
    }
  }
}
