import { Injectable } from '@angular/core';

import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { ClientInsuranceProvider } from './client-insurance-provider.model';

export interface ClientInsuranceProviderState
  extends EntityState<ClientInsuranceProvider, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'client-insurance-provider', resettable: true })
export class ClientInsuranceProviderStore extends EntityStore<ClientInsuranceProviderState> {
  constructor() {
    super();
  }
}
