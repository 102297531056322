import { Component, OnInit } from '@angular/core';

import { RouterQuery } from '@datorama/akita-ng-router-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-company-shell',
  template: `
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        *ngFor="let link of links"
        [routerLink]="[link.route]"
        (click)="activeLink = link.route"
        [active]="link.route == activeLink"
      >
        {{ link.label }}
      </a>
    </nav>
    <router-outlet></router-outlet>
  `,
  styles: [],
})
export class CompanyShellComponent implements OnInit {
  activeLink: string | undefined;
  links = [
    {
      label: 'Information',
      route: 'company-settings',
    },
    {
      label: 'Categories',
      route: 'categories',
    },
    {
      label: 'Templates',
      route: 'templates',
    },
    {
      label: 'Locations',
      route: 'locations',
    },
    {
      label: 'Insurance',
      route: 'insurance',
    },
  ];

  constructor(private routerQuery: RouterQuery) {}

  ngOnInit(): void {
    this.routerQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(val => {
        this.activeLink = val.state?.urlAfterRedirects.split('/').reverse()[0];
      });
  }
}
