<app-title-bar [header]="header">
  <button mat-button (click)="add()" color="primary">
    <mat-icon>add_box</mat-icon>
    <span>Add</span>
  </button>
  <button mat-button (click)="download()">
    <mat-icon>download</mat-icon>
    <span>Download</span>
  </button>
</app-title-bar>
<mat-card class="page-container search-filters">
  <app-form
    #formEl
    [fields]="filterFields"
    [model]="filterModel"
    [form]="filterForm"
  >
  </app-form>
</mat-card>
<app-service-table
  [sortable]="true"
  [loading]="loading"
  [services]="data.results"
  [sortActive]="filterModel.sortBy" 
  [sortDirection]="filterModel.sortByOrder"
  (sortChange)="sortChange($event)"></app-service-table>
<mat-paginator
  class="page-container"
  [length]="data.length"
  [pageSize]="filterModel.pageSize"
  [pageIndex]="filterModel.page"
  (page)="pageChange($event)"
  [pageSizeOptions]="[25, 50, 100, 500]"
>
</mat-paginator>
