import { startCase } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormlyFieldConfig } from '@ngx-formly/core';

import { User } from '../store';
import { rxConvertModelsToOptions } from '../util/custom-rx-pipes';
import { toJsDate } from '../util/date-util';

export type SelectOptions = {
  value: any;
  label: any;
}[];

export const booleanSelect = [
  {
    value: false,
    label: 'NO',
  },
  {
    value: true,
    label: 'YES',
  },
];

export const booleanSelectWithEmpty = (label = 'Select') => {
  return [
    {
      value: null,
      label: label,
    },
    {
      value: false,
      label: 'NO',
    },
    {
      value: true,
      label: 'YES',
    },
  ];
};

export const isBlockSelect = [
  {
    value: false,
    label: 'At start time',
  },
  {
    value: true,
    label: 'Anytime during block',
  },
];

export const isBlockTemplateOptions = {
  label: 'Member should arrive',
  required: true,
  options: isBlockSelect,
};

export function convertListToOptions(
  list: (string | number)[] = []
): SelectOptions {
  return list.map((i: string | number) => ({ label: i, value: i }));
}

export function convertModelsToOptions<M extends { id?: string }>(
  models: M[],
  label: keyof M,
  value: keyof M | 'self' = 'id'
): SelectOptions {
  return models.map(m => ({
    label: m[label],
    value: value === 'self' ? m : m[value],
  }));
}

export function formlyInput(
  props: { type?: string; key: string; label?: string; required?: boolean },
  config?: FormlyFieldConfig
): FormlyFieldConfig {
  return {
    type: props.type || 'input',
    key: props.key,
    templateOptions: {
      label: props.label || startCase(props.key),
      required: props.required === false ? false : true,
    },
    ...config,
  };
}

export function formlySelect(
  props: {
    type?: string;
    key: string;
    label?: string;
    required?: boolean;
    options: (string | number)[];
  },
  config?: FormlyFieldConfig
): FormlyFieldConfig {
  return {
    type: props.type || 'select',
    key: props.key,
    templateOptions: {
      label: props.label || startCase(props.key),
      required: props.required === false ? false : true,
      options: convertListToOptions(props.options),
    },
    ...config,
  };
}

export function booleanSelectInput(
  key: string,
  label: string,
  required = true
): FormlyFieldConfig {
  return <FormlyFieldConfig>{
    key,
    type: 'select',
    templateOptions: {
      label,
      required,
      options: booleanSelect,
    },
  };
}

const currentYear = toJsDate().getFullYear();
export const yearOptions = convertListToOptions(
  new Array(20).fill('').map((x, i) => currentYear - i)
);

export const monthOptions = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((l, i) => {
  return {
    label: l,
    value: i + 1,
  };
});

export function userSelectInput(opts: {
  users: Observable<User[]>;
  defaultValue?: string;
  label?: string;
  required?: boolean;
  emptyOption?: {
    label: string;
    value: any;
  };
}): FormlyFieldConfig {
  return {
    key: 'userId',
    type: 'select',
    defaultValue: opts.defaultValue,
    templateOptions: {
      compareWith: (a: string, b: string) => a && b && a === b,
      label: opts.label || 'Performed By',
      options: opts.users.pipe(
        rxConvertModelsToOptions('displayName', 'id'),
        map(v => (opts.emptyOption ? [opts.emptyOption, ...v] : v))
      ),
      required: opts.required !== false,
    },
  };
}

export function billingOptions(
  opts: {
    all?: boolean;
    null?: boolean;
  } = {}
): SelectOptions {
  const options = [
    {
      value: 'OPEN',
      label: 'OPEN - Submitted',
    },
    {
      value: 'PAID',
      label: 'CLOSED - Paid',
    },
    {
      value: 'PARTIAL',
      label: 'CLOSED - Partial',
    },
    {
      value: 'UNPAID',
      label: 'CLOSED - Unpaid',
    },
    {
      value: 'NON-BILLABLE',
      label: 'NON-BILLABLE',
    },
  ];

  if (opts.null) {
    options.unshift({
      value: null,
      label: 'NOT BILLED',
    });
  }

  return options;
}
