import { Component, OnInit } from '@angular/core';

import { groupBy } from 'lodash';
import print from 'print-js';
import { combineLatest, from } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  share,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  ClientUxService,
  PromptService,
  ServiceLogUxService,
  UserUxService,
} from 'src/app/services';
import { ServiceLog, UxQuery } from 'src/app/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { convertToCSVAndDownload } from '../../../util/client-util';

interface PayrollDisplayRow {
  user: string;
  client: string;
  date: string;
  minutes: number;
  units: number;
  amount: number;
  hours: number;
}

interface PayrollSummaryRow {
  user: string;
  rate: number;
  minutes: number;
  units: number;
  hours: number;
  amount: number;
  rows: PayrollDisplayRow[];
}

@UntilDestroy()
@Component({
  selector: 'app-payroll-report',
  templateUrl: './payroll-report.component.html',
  styles: [
    `
      .summary-row {
        font-weight: bold;
      }
    `,
  ],
})
export class PayrollReportComponent implements OnInit {
  results: PayrollSummaryRow[] = null;

  columns = ['user', 'date', 'hours', 'rate', 'amount'];

  loading: boolean;

  showDetail = false;

  constructor(
    private serviceUx: ServiceLogUxService,
    private uxQuery: UxQuery,
    private userUxQuery: UserUxService,
    private clientUx: ClientUxService,
    private prompt: PromptService
  ) {}

  ngOnInit(): void {
    const logs$ = this.uxQuery.selectReportFilters.pipe(
      skip(1),
      distinctUntilChanged((a, b) => {
        return (
          a.dateStart.toDateString() === b.dateStart.toDateString() &&
          a.dateEnd.toDateString() === b.dateEnd.toDateString() &&
          a.dateFilter === b.dateFilter &&
          a.userId === b.userId
        );
      }),
      tap(() => (this.loading = true)),
      switchMap(f =>
        from(
          this.serviceUx.getForDates(
            f.dateFilter,
            f.dateStart,
            f.dateEnd,
            f.userId
          )
        )
      ),
      tap(() => (this.loading = false))
    );

    combineLatest([this.uxQuery.selectReportFilters, logs$])
      .pipe(
        map(([filters, logs]) => {
          this.showDetail = filters.showDetail;
          const filtered = logs
            .filter(l =>
              filters.billingType.length
                ? filters.billingType.includes(l.billingStatus)
                : true
            )
            .filter(l => (filters.userId ? filters.userId === l.userId : true));
          const results = this.groupByUser(filtered);
          return results;
        }),
        share(),
        untilDestroyed(this)
      )
      .subscribe(val => {
        this.results = val;
      });
  }

  download() {
    this.prompt
      .confirm('This will download the results as a CSV file')
      .subscribe(val => {
        if (!val) {
          return;
        }
        if (this.showDetail) {
          const stuff: PayrollDisplayRow[] = [];
          this.results.forEach(i => {
            stuff.push(...i.rows);
          });
          convertToCSVAndDownload(stuff, 'payroll-report');
        } else {
          convertToCSVAndDownload(this.results, 'payroll-report', [
            'user',
            'rate',
            'minutes',
            'units',
            'hours',
            'amount',
          ]);
        }
      });
  }

  groupByUser(logs: ServiceLog[]): PayrollSummaryRow[] {
    return Object.values(groupBy(logs, m => m.userId))
      .map(subLogs => {
        let user = this.userUxQuery.getEntity(subLogs[0].userId);
        if (!user) {
          console.log('user missing');
          console.log(subLogs[0]);
          user = {
            displayName: '',
            payRate: 0,
          };
        }
        const sums = this.getSums(subLogs);
        const hours = sums.minutes / 60;
        return {
          user: user.displayName,
          rate: user.payRate,
          date: subLogs[0].dateString,
          amount: hours * user.payRate,
          hours,
          ...sums,
          rows: this.groupByDay(subLogs),
        };
      })
      .sort((a, b) => a.user.localeCompare(b.user));
  }

  groupByDay(logs: ServiceLog[]): PayrollDisplayRow[] {
    return logs
      .map(log => {
        const client = this.clientUx.getEntity(log.clientId);
        const user = this.userUxQuery.getEntity(log.userId);
        const hours = log.minutes / 60;
        return {
          user: user.displayName,
          client: client.displayName,
          date: log.dateString,
          hours,
          minutes: log.minutes,
          amount: hours * user.payRate,
          units: log.units,
        };
      })
      .sort((a, b) => a.date.localeCompare(b.date));
  }

  getSums(logs: ServiceLog[]): {
    minutes: number;
    units: number;
  } {
    return logs.reduce<{
      minutes: number;
      units: number;
    }>(
      (a, c) => {
        a.minutes += c.minutes || 0;
        a.units += c.units || 0;
        return a;
      },
      { minutes: 0, units: 0 }
    );
  }

  print() {
    print({
      header: 'Payrol Report',
      printable: 'print-table',
      type: 'html',
      targetStyles: '*',
    });
  }
}
