export const environment = {
  firebase: {
    projectId: 'casenotes-alpha',
    appId: '1:612342990769:web:30f71f3048e0885be61f90',
    storageBucket: 'casenotes-alpha.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyC7ssoJD5KcW-SzpXmREVfAGC8CAmfQBIE',
    authDomain: 'go.casenotesonline.com',
    messagingSenderId: '612342990769',
    measurementId: 'G-37GNFW8T4Z',
  },
  production: true,
};
