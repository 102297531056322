import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FormlyFieldConfig } from '@ngx-formly/core';

import {
  DialogFormComponent,
  DialogFormOptions,
} from '../formly/form-dialog.component';
import {
  booleanSelect,
  booleanSelectInput,
  convertListToOptions,
  convertModelsToOptions,
  formlyInput,
} from '../formly/formly-helpers';
import {
  InsuranceProvider,
  InsuranceProviderQuery,
  InsuranceProviderService,
  ServiceLogService,
} from '../store';
import { rxConvertModelsToOptions } from '../util/custom-rx-pipes';
import { PromptService } from './';
import { ToastService } from './toast.service';
import { limit, where } from 'firebase/firestore';

@Injectable({ providedIn: 'root' })
export class InsuranceProviderUxService {
  constructor(
    private service: InsuranceProviderService,
    private query: InsuranceProviderQuery,
    private dialog: MatDialog,
    private prompt: PromptService,
    private toast: ToastService,
    private logService: ServiceLogService
  ) {}

  providerSelectInput(
    openSelectBox: boolean = false,
    required = true,
    addEmpty = false,
    includeDisabled = false
  ): FormlyFieldConfig {
    return {
      type: 'select-search',
      key: 'insuranceProviderId',
      templateOptions: {
        openSelectBox: openSelectBox,
        required: required,
        label: 'Insurance Provider',
        labelProp: 'name',
        valueProp: 'id',
        compareWith: (a: InsuranceProvider, b: InsuranceProvider) =>
          a && b && a === b,
        searchObs: (term: string, value: string) => {
          const _term = term.toLocaleLowerCase();
          return this.query
            .selectAll({
              filterBy: i =>
                (includeDisabled || i.isActive) &&
                i.name!.toLocaleLowerCase().includes(_term),
            })
            .pipe(
              map(items => {
                if (addEmpty) {
                  return [{ name: 'All', id: null }, ...items];
                }
                return items;
              })
            );
        },
      },
    };
  }

  sync() {
    return this.service.syncCollection({ reset: true });
  }

  getRecord(id: string) {
    return this.query.getEntity(id);
  }

  getAll() {
    return this.query.getAll();
  }

  getEntity(id: string) {
    return this.query.getEntity(id);
  }

  selectEntity(id: string) {
    return this.query.selectEntity(id);
  }

  selectAll() {
    return this.query.selectAll();
  }

  getModelOptions() {
    return convertModelsToOptions(this.getAll(), 'name');
  }

  selectModelOptions() {
    return this.selectAll().pipe(rxConvertModelsToOptions('name', 'id'));
  }

  getEditFields(
    entry: Partial<InsuranceProvider> = {},
    keys: string[] = []
  ): FormlyFieldConfig[] {
    return (
      [
        formlyInput({ key: 'name' }),
        formlyInput({ key: 'address', type: 'textarea', required: false }),
        formlyInput({ key: 'phoneNumber', required: false }),
        formlyInput({ key: 'faxNumber', required: false }),
        formlyInput({ key: 'emailAddress', required: false }),
        formlyInput({ key: 'website', required: false }),
        formlyInput({ key: 'notes', type: 'textarea', required: false }),
        booleanSelectInput('isActive', 'Is Active'),
        formlyInput(
          { key: 'billServiceBy', type: 'select' },
          {
            templateOptions: {
              required: true,
              label: 'Bill Service By',
              options: convertListToOptions(['GROUP', 'INDIVIDUAL']),
            },
          }
        ),
        formlyInput(
          { key: 'minutesToUnits' },
          {
            templateOptions: {
              type: 'number',
              required: true,
              label: 'Minutes to Units',
              description:
                'The amount of time (in minutes) that equal 1 whole unit. Ex 15 minutes equals 1 whole unit.',
            },
          }
        ),
        formlyInput(
          { key: 'alwaysRoundUp', type: 'select' },
          {
            defaultValue: false,
            templateOptions: {
              type: 'number',
              required: true,
              label: 'Always Round Up',
              options: booleanSelect,
              description:
                'If you work for 1 minute does that always round up or does it round to the nearest whole number of units?',
            },
          }
        ),
        formlyInput(
          { key: 'rate' },
          {
            templateOptions: {
              type: 'number',
              required: true,
              placeholder: '0.00',
              label: 'Rate',
              description:
                'The amount of money (no $ dollar sign) that you can bill for 1 whole UNIT.',
            },
          }
        ),
        {
          key: null,
          type: 'provider-price-check',
        },
      ] as FormlyFieldConfig[]
    ).filter(thing =>
      keys && keys.length ? keys.includes(thing.key as string) : true
    );
  }

  edit(insuranceProvider: InsuranceProvider = {}) {
    const options: DialogFormOptions = {
      fields: this.getEditFields(insuranceProvider),
      model: insuranceProvider,
      title: insuranceProvider.id
        ? 'Edit Insurance Provider'
        : 'Add Insurance Provider',
      remove: insuranceProvider.id
        ? () => this.remove(insuranceProvider)
        : undefined,
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    return dialog.afterClosed().pipe(
      switchMap(val => {
        if (!val) {
          this.toast.open(
            `${
              insuranceProvider.id
                ? 'Edit Insurance Provider'
                : 'Add Insurance Provider'
            } cancelled`
          );
          return of();
        }
        return from(this.saveRecord(val));
      })
    );
  }

  async saveRecord(model: InsuranceProvider): Promise<string> {
    if (model.id) {
      return this.service.update(model).then(() => {
        this.toast.success(`"${model.name}" Updated`);
        return model.id as string;
      });
    }
    return this.service.add(model).then(val => {
      this.toast.success(`"${model.name}" Added`);
      return val;
    });
  }

  async remove(model: InsuranceProvider) {
    const hasLogs = await this.logService.getValue([
      where('insuranceProviderId', '==', model.id),
      limit(1),
    ]);
    if (hasLogs) {
      this.prompt.alert(
        'There are logs associated with this provider. You should not delete it and instead mark it as inactive.'
      );
      return;
    }
    this.prompt
      .confirm(`Delete insuranceProvider: "${model.name}"`)
      .subscribe(val => {
        if (!val) {
          return;
        }
        this.dialog.closeAll();
        this.service
          .remove(model.id as string)
          .then(() => this.toast.success(`"${model.name}" removed`));
      });
  }
}
