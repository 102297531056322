import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { enableAkitaProdMode } from '@datorama/akita';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  AppShellComponent,
  BillingShellComponent,
  BlankOutletComponent,
  CategoriesComponent,
  ClientDetailComponent,
  ClientInfoComponent,
  ClientListComponent,
  ClientNoteTableComponent,
  ClientProviderTableComponent,
  CompanyDetailsComponent,
  CompanySelectionComponent,
  CompanyShellComponent,
  ContactsTableComponent,
  HomeComponent,
  InsuranceProvidersComponent,
  LocationsComponent,
  LoginComponent,
  NoResultsComponent,
  ServiceListComponent,
  ServiceTableComponent,
  SubmittedBillingComponent,
  TemplatesComponent,
  TitleBarComponent,
  UnprocessedBillingComponent,
  UserListComponent,
} from './components';
import { BillingDetailComponent } from './components/billing-detail/billing-detail.component';
import { FileTableComponent } from './components/file-table.component';
import { FoobarComponent } from './components/foobar.component';
import { LogoutComponent } from './components/logout.component';
import { PaymentTableComponent } from './components/payment-table.component';
import { ReportsShellComponent } from './components/reports-shell.component';
import { PayrollReportComponent } from './components/reports/payroll-report/payroll-report.component';
import { ReportFiltersComponent } from './components/reports/report-filters.component';
import { ReportListComponent } from './components/reports/report-list.component';
import { ServiceLogReportComponent } from './components/reports/service-log/service-log-report.component';
import { UnitReportComponent } from './components/reports/unit-report/unit-report.component';
import { UnitInfoComponent } from './components/unit-info.component';
import { ArrayMatCardTypeComponent } from './formly/array-type';
import { FileValueAccessor, FormlyFieldFile } from './formly/file-upload.type';
import { DialogFormComponent } from './formly/form-dialog.component';
import { FormComponent } from './formly/form.component';
import { formly_components, formly_types } from './formly/formly.config';
import { FormlyFieldSearchSelect } from './formly/search-select-type';
import { FormlyFieldCustomTemplate } from './formly/template.type';
import { PromptDialogComponent } from './services';
import { BooleanPipe } from './util/boolean.pipe';
import { ClientMinutesPipe } from './util/client.pipe';
import { myDatePipe, timestampDatePipe, yearsAgoPipe } from './util/date-util';
import { NonePipe } from './util/none.pipe';
import {
  ClientInsuranceProviderPipe,
  ClientPipe,
  InsuranceProviderPipe,
  UserPipe,
} from './util/store.pipe';

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'auto',
};

const defaultDialogOptions: MatDialogConfig = {
  disableClose: false,
};

if (environment.production) {
  enableAkitaProdMode();
} else {
  // setLogLevel('verbose');
}

@NgModule({
  declarations: [
    AppComponent,
    BlankOutletComponent,
    FormComponent,
    PromptDialogComponent,
    DialogFormComponent,
    ArrayMatCardTypeComponent,
    FileValueAccessor,
    TitleBarComponent,
    FormlyFieldSearchSelect,
    FormlyFieldCustomTemplate,
    FormlyFieldFile,
    LoginComponent,
    AppShellComponent,
    HomeComponent,
    ServiceListComponent,
    ServiceTableComponent,
    ClientDetailComponent,
    ClientListComponent,
    CompanyShellComponent,
    BillingShellComponent,
    UserListComponent,
    TemplatesComponent,
    LocationsComponent,
    InsuranceProvidersComponent,
    CompanyDetailsComponent,
    CategoriesComponent,
    CompanySelectionComponent,
    NoResultsComponent,
    timestampDatePipe,
    myDatePipe,
    yearsAgoPipe,
    BooleanPipe,
    ...formly_components,
    ContactsTableComponent,
    ClientInfoComponent,
    NonePipe,
    ClientProviderTableComponent,
    InsuranceProviderPipe,
    ClientNoteTableComponent,
    UserPipe,
    ClientInsuranceProviderPipe,
    UnprocessedBillingComponent,
    SubmittedBillingComponent,
    ClientPipe,
    BillingDetailComponent,
    PaymentTableComponent,
    ReportsShellComponent,
    FoobarComponent,
    FileTableComponent,
    ClientMinutesPipe,
    ReportListComponent,
    ReportFiltersComponent,
    PayrollReportComponent,
    UnitReportComponent,
    ServiceLogReportComponent,
    LogoutComponent,
    UnitInfoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    providePerformance(() => getPerformance()),
    BrowserAnimationsModule,
    CommonModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatExpansionModule,
    FormlyModule.forRoot({
      types: formly_types,
      validationMessages: [
        { name: 'required', message: 'This field is required' },
      ],
    }),
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: matFormFieldDefaultOptions,
    },
    // { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: defaultDialogOptions },
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
