<app-title-bar [header]="header" [showBack]="true" [backRoute]="['../']">
  <button mat-button color="primary" [matMenuTriggerFor]="clientDetailMenu">
    <span>Edit Client</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</app-title-bar>

<mat-menu #clientDetailMenu="matMenu" xPosition="before">
  <button mat-menu-item (click)="edit()">
    <mat-icon>edit</mat-icon>
    <span>Edit Details</span>
  </button>
  <button mat-menu-item (click)="edit()">
    <mat-icon>portrait</mat-icon>
    <span>Edit Photo</span>
  </button>
  <button mat-menu-item (click)="addContact()">
    <mat-icon>phone</mat-icon>
    <span>Add Contact</span>
  </button>
  <button mat-menu-item (click)="addInsuranceProvider()">
    <mat-icon>business</mat-icon>
    <span>Add Insurance Provider</span>
  </button>
  <button mat-menu-item (click)="addFile()">
    <mat-icon>upload_file</mat-icon>
    <span>Add File</span>
  </button>
  <button mat-menu-item (click)="addService()">
    <mat-icon>handshake</mat-icon>
    <span>Add Service</span>
  </button>
  <button mat-menu-item (click)="addNote()">
    <mat-icon>note_alt</mat-icon>
    <span>Add Note</span>
  </button>
  <hr />
  <button mat-menu-item (click)="remove()" color="warn">
    <mat-icon>delete</mat-icon>
    <span>Remove Client</span>
  </button>
</mat-menu>

<mat-accordion class="page-container" [multi]="true">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title> Detail </mat-panel-title>
    </mat-expansion-panel-header>
    <app-client-info [client]="client!"></app-client-info>
    <mat-action-row>
      <button mat-button (click)="edit()">
        <mat-icon>edit</mat-icon>
        <span>Edit Details</span>
      </button>
      <button mat-button (click)="addNewPhoto()" *ngIf="!client?.hasPhoto">
        <mat-icon>portrait</mat-icon>
        <span>Edit Photo</span>
      </button>
      <button mat-button (click)="removePhoto()" *ngIf="client?.hasPhoto">
        <mat-icon>no_photography</mat-icon>
        <span>Remove Photo</span>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title> Contacts </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <!-- contacts -->
      <app-contacts-table></app-contacts-table>
    </ng-template>
    <mat-action-row>
      <button mat-button (click)="addContact()">
        <mat-icon>phone</mat-icon>
        <span>Add Contact</span>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Insurance Providers </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <!-- insurance providers -->
      <app-client-provider-table></app-client-provider-table>
    </ng-template>
    <mat-action-row>
      <button mat-button (click)="addInsuranceProvider()">
        <mat-icon>business</mat-icon>
        <span>Add Insurance Provider</span>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Stats </mat-panel-title>
    </mat-expansion-panel-header>
    <table class="table-alpha">
      <thead>
        <tr>
          <th>Year - Month</th>
          <th>Minutes</th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
   
          <tr *ngFor="let stat of stats">
            <td class="center">{{ monthNames[stat.month - 1] }} {{ stat.year }}</td>
            <td class="center">{{ stat.minutes }}</td>
            <td class="center">{{ stat.units }}</td>
          </tr>
  
      </tbody>
    </table>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Files </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <!-- files -->
      <app-file-table></app-file-table>
    </ng-template>
    <mat-action-row>
      <button mat-button (click)="addFile()">
        <mat-icon>upload_file</mat-icon>
        <span>Add File</span>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Case Notes </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <!-- service -->
      <app-service-table [services]="service | async"></app-service-table>
    </ng-template>
    <mat-action-row>
      <button mat-button (click)="addService()">
        <mat-icon>handshake</mat-icon>
        <span>Add Service</span>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Notes </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <!-- notes -->
      <app-client-note-table></app-client-note-table>
    </ng-template>
    <mat-action-row>
      <button mat-button (click)="addNote()">
        <mat-icon>note_alt</mat-icon>
        <span>Add Note</span>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>
