import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';

import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PromptService } from 'src/app/services/prompt';

import { FunctionResponse } from './function-response.interface';

export interface AssignMemberToBlockInput {
  memberId: string;
  appointmentBlockId: string;
  projectId: string;
  orgId: string;
}

export interface LogForReport {
  client: string;
  staff: string;
  date: string;
  time: string;
  minutes: string | number;
  units: string | number;
  location: string;
  category: string;
  billingStatus: string;
  insurance: string;
  notes: string;
}

type FncResponse<T = string> = Observable<FunctionResponse<T>>;

@Injectable({ providedIn: 'root' })
export class FunctionService {
  constructor(private fns: Functions, private prompt: PromptService) {}

  handleError(resp: FunctionResponse) {
    if (!resp.success) {
      this.prompt.alert(`Error: ${resp.message}`);
    }
  }

  assignUserCustomClaims() {
    return from(httpsCallable(this.fns, 'auth-assignUserCustomClaims')({}));
  }

  caseNotePdf(
    logs: LogForReport[] = [],
    filters: Record<string, string>,
    meta: Record<string, string>
  ): FncResponse<{ url: string }> {
    return from(
      httpsCallable<
        {
          logs: LogForReport[];
          filters: Record<string, string>;
          meta: Record<string, string>;
        },
        FunctionResponse<{ url: string }>
      >(
        this.fns,
        'pdf-serviceLogAuditReport'
      )({
        logs,
        filters,
        meta,
      })
    ).pipe(map(v => v.data));
  }
}
