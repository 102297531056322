import { Injectable } from '@angular/core';

import {
  Order,
  QueryConfig,
  QueryEntity,
} from '@datorama/akita';

import {
  ClientState,
  ClientStore,
} from './client.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'sortProp',
  sortByOrder: Order.ASC,
})
export class ClientQuery extends QueryEntity<ClientState> {
  constructor(protected store: ClientStore) {
    super(store);
  }
}
