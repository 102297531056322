import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { AuthService } from '../services';

interface ProviderLogout {
  providerId: string;
  url: string;
  label: string;
  image: string;
}

@Component({
  template: ` <div class="login-container">
    <mat-card>
      <mat-card-title>
        <h2>You have successfully logged out.</h2>
      </mat-card-title>
      <mat-card-content>
        <div *ngIf="providers.length" class="providers">
          <div>
            <p>
              If you are logged in on a <strong>shared machine</strong> you
              should also log out of the following providers.
            </p>
          </div>
          <div class="login-buttons">
            <div
              *ngFor="let provider of providers"
              role="button"
              class="login-button mat-elevation-z4"
              (click)="logout(provider.url)"
            >
              <img class="login-icon" [src]="provider.image" />
              <span>{{ provider.label }}</span>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button color="primary" [routerLink]="['/login']">
          Return to login screen
        </button>
      </mat-card-actions>
    </mat-card>
  </div>`,
  styles: [
    `
      :host {
        display: block;
      }
      .login-container {
        width: 600px;
        max-width: 95%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
      }
      .login-icon {
        width: 35px;
        margin-right: 15px;
      }

      .login-button {
        display: flex;
        padding: 10px 15px;
        align-items: center;
        font-size: 18px;
        margin-bottom: 15px;
        margin-right: auto;
        margin-left: auto;
        width: 250px;
        border-radius: 5px;
      }
      .login-button:hover {
        background-color: whitesmoke;
      }
    `,
  ],
})
export class LogoutComponent implements OnInit {
  providers: ProviderLogout[] = [];

  providerKeys: {
    [key: string]: ProviderLogout;
  } = {
    'google.com': {
      providerId: 'google.com',
      url: 'https://accounts.google.com/Logout?&continue=http://www.google.com/',
      label: 'Logout of Google',
      image: '/assets/google-icon.png',
    },
    'microsoft.com': {
      providerId: 'microsoft.com',
      url: 'https://login.live.com/logout.srf', // 'https://login.microsoftonline.com/logout.srf',
      label: 'Logout of Microsoft (live.com)',
      image: '/assets/ms-icon.png',
    },
  };

  constructor(private auxUx: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.auxUx
      .isLoggedIn()
      .pipe(take(1))
      .subscribe(val => {
        if (!val) {
          this.router.navigate(['/login']);
          return;
        }
        this.auxUx.currentUser.providerData.forEach(p => {
          if (this.providerKeys[p.providerId]) {
            this.providers.push(this.providerKeys[p.providerId]);
          }
          this.auxUx.logout();
        });
      });
  }

  logout(url: string) {
    window.open(url, '_blank');
  }
}
