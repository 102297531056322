import { Component, OnInit } from '@angular/core';
import {
  Auth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  template: ``,
})
export class FirebaseLinksComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public auth: Auth
  ) {
    const mode = (route.snapshot.queryParams.mode || '').toLowerCase();

    if (mode === 'resetpassword') {
      this.gotoResetPassword();
    } else if (mode === 'signin') {
      this.confirmLink();
    } else {
      this.gotoLogin();
    }
  }

  ngOnInit(): void {}

  gotoLogin() {
    this.router.navigate(['login']);
  }

  gotoResetPassword() {
    const code = this.route.snapshot.queryParams.oobCode;
    this.router.navigate(['password-reset', { code }]);
  }

  getEmailFromCookie() {
    let name = 'efsi=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return atob(c.substring(name.length, c.length));
      }
    }
    return '';
  }

  confirmLink() {
    if (isSignInWithEmailLink(this.auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = this.getEmailFromCookie();
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(this.auth, email!, window.location.href)
        .then(result => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          this.router.navigate(['login']);
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(error => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          // this.handleMagicLinkError(error);
          alert('An error occurred logging you in.');
          this.router.navigate(['login']);
        });
    }
  }
}
