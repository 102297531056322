import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { combineLatest } from 'rxjs';
import {
  AuthService,
  CategoryUxService,
  ClientInsuranceProviderUxService,
  ClientUxService,
  CompanyUxService,
  InsuranceProviderUxService,
  LocationUxService,
  PromptService,
  TemplateUxService,
  UserUxService,
} from 'src/app/services';
import { Company, User, UxStore } from 'src/app/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
})
export class AppShellComponent implements OnInit {
  company: Company | undefined;

  routes = [
    // {
    //   label: 'Home',
    //   route: 'home',
    // },
    {
      label: 'Case Notes',
      route: 'service',
    },
    {
      label: 'Clients',
      route: 'clients',
    },
    {
      label: 'Staff',
      route: 'staff',
      adminOnly: true,
    },
    {
      label: 'Billing',
      route: 'billing',
      adminOnly: true,
    },
    {
      label: 'Reports',
      route: 'reports',
    },
    {
      label: 'Company',
      route: 'company',
      adminOnly: true,
    },
  ];

  showSwitchCompany = false;

  currentUser: User;

  constructor(
    private router: Router,
    private authService: AuthService,
    private companyUxService: CompanyUxService,
    private categoryUxService: CategoryUxService,
    private templateUxService: TemplateUxService,
    private locationUxService: LocationUxService,
    private providerUxService: InsuranceProviderUxService,
    private userUxService: UserUxService,
    private clientUxService: ClientUxService,
    private clientProviderUx: ClientInsuranceProviderUxService,
    private uxStore: UxStore,
    private prompt: PromptService
  ) {}

  ngOnInit(): void {
    this.syncProjectLevelObjects();
    this.userUxService.updateCurrentUserLastLoginAt();

    if (!this.authService.isProjectAdmin) {
      this.routes = this.routes.filter(r => !r.adminOnly);
    }

    this.currentUser = this.authService.projectUser;

    if (
      this.authService.currentUserId === 'randallmeeker@gmail.com' ||
      this.authService.currentUserId === 'support@casenotesonline.com'
    ) {
      this.routes.push({
        label: 'Foobar',
        route: 'foobar',
      });
    }

    /**
     * Initially set userId on service filter to self
     */
    this.uxStore.updateService({
      userId: this.authService.currentUserId,
    });

    this.uxStore.updateClient({
      userId: this.authService.currentUserId,
    });

    this.showSwitchCompany = this.uxStore.getValue().app.multiCompany;

    this.companyUxService
      .selectActive()
      .pipe(untilDestroyed(this))
      .subscribe(val => (this.company = val));
  }

  syncProjectLevelObjects() {
    combineLatest([
      this.companyUxService.syncActive(),
      this.categoryUxService.sync(),
      this.templateUxService.sync(),
      this.locationUxService.sync(),
      this.providerUxService.sync(),
      this.userUxService.sync(),
      this.clientUxService.sync(),
      this.clientProviderUx.sync(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  help() {
    this.prompt.alert({
      title: 'Contact Us!',
      html: `
        <p>
          Please contact us at 
          <a href="mailto:support@casenotesonline.com">support@casenotesonline.com</a>
          with any issues you have.
        </p>
      `,
    });
  }
}
