import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { ReplaySubject, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import { FieldType } from '@ngx-formly/material/form-field';

const specialChars = /[^\w\d]/gi;

@Component({
  selector: 'formly-select-search-input',
  template: `
    <mat-select
      [formControl]="formControl"
      [formlyAttributes]="field"
      #singleSelect
      [multiple]="to.multiple"
      (selectionChange)="change()"
    >
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="Search"
          [formControl]="filterCtrl"
          [searching]="searching"
          [clearSearchInput]="false"
          noEntriesFoundLabel="No entry matches"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let item of filteredItems | async"
        [value]="getValue(item)"
        [disabled]="getIsDisabled(item)"
      >
        {{ to.labelProp ? item[to.labelProp] : item }}
      </mat-option>
    </mat-select>
  `,
})
export class FormlyFieldSearchSelect
  extends FieldType
  implements OnDestroy, OnInit, AfterViewInit
{
  formControl: UntypedFormControl;
  /** control for the MatSelect filter keyword */
  public filterCtrl: UntypedFormControl = new UntypedFormControl();

  /** list of banks filtered by search keyword */
  public filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  searching = false;

  ngOnInit() {
    // load the initial bank list
    // this.filteredItems.next(this.banks.slice());

    // listen for search field value changes
    this.filterCtrl.valueChanges
      .pipe(
        startWith(''),
        takeUntil(this._onDestroy),
        debounceTime(200),
        // map((v) => v.toLocaleLowerCase().replace(specialChars, '')),
        distinctUntilChanged(),
        switchMap(term => {
          this.searching = true;
          return this.to.searchObs(term, this.value);
        })
      )
      .subscribe((results: any) => {
        this.searching = false;
        this.filteredItems.next(results);
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
    if (this.to.openSelectBox) {
      setTimeout(() => {
        const el = this.singleSelect._elementRef.nativeElement as HTMLElement;
        const el2 = el.querySelector('.mat-select-value') as HTMLElement;
        el2.click();
      }, 250);
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredItems
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        if (this.to.compareWith) {
          this.singleSelect.compareWith = this.to.compareWith;
        } else {
          this.singleSelect.compareWith = (a: any, b: any) =>
            a && b && a.id && b.id ? a.id === b.id : a === b;
        }
      });
  }

  /**
   * Note if returning your own valueProp
   * you should also return your own compareWith fnc (see above)
   * @param item
   */
  getValue(item: any) {
    if (this.to.valueProp) {
      return item[this.to.valueProp];
    } else {
      return item;
    }
  }

  getIsDisabled(item: any) {
    if (this.to.optionDisabled) {
      return this.to.optionDisabled(item);
    }
    return false;
  }

  change() {
    if (this.to.change) {
      this.to.change(this.field);
    }
  }
}
