import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

import { getDateTime, toJsDate } from 'src/app/util/date-util';

import { Order, Store, StoreConfig } from '@datorama/akita';

export type DateTypes =
  | 'thisMonth'
  | 'lastMonth'
  | 'thisYear'
  | 'lastYear'
  | 'custom';

export const dateTypeOptions: {
  value: DateTypes;
  label: string;
}[] = [
  {
    value: 'thisMonth',
    label: `This month (${getDateTime().toFormat('MMMM')})`,
  },
  {
    value: 'lastMonth',
    label: `Last month (${getDateTime().plus({ month: -1 }).toFormat('MMMM')})`,
  },
  {
    value: 'thisYear',
    label: `This year (${getDateTime().toFormat('yyyy')})`,
  },
  {
    value: 'lastYear',
    label: `Last year (${getDateTime().plus({ year: -1 }).toFormat('yyyy')})`,
  },
  {
    value: 'custom',
    label: `Custom`,
  },
];

// export type GroupBy = 'client' | 'insuranceProvider';

// export const groupByOptions: {
//   value: GroupBy;
//   label: string;
// }[] = [
//   {
//     value: 'client',
//     label: 'Client',
//   },
//   {
//     value: 'insuranceProvider',
//     label: 'Insurance Provider',
//   },
// ];

/**
 * DB related defaults, loaded in app shell component
 */

export interface UxState {
  app: {
    multiCompany: boolean;
  };
  client: {
    q: string;
    sortBy:
      | 'firstName'
      | 'lastName'
      | 'minutesMonth'
      | 'minutesYear'
      | 'unitsMonth'
      | 'unitsYear';
    sortByOrder: SortDirection;
    hideInactive: boolean;
    userId: string;
    page: number;
    pageSize: number;
  };
  service: {
    year: number;
    month: number;
    userId: string;
    syncChange: number;
    clientId: string | null;
    sortBy: string;
    sortByOrder: SortDirection;
    page: number;
    pageSize: number;
  };
  unProcessedBilling: {
    year: number;
    insuranceProviderId: string;
    userId: string;
    clientId: string;
    sortBy: 'clientInsuranceProvider' | 'client' | 'minutes';
    sortByOrder: SortDirection;
    page: number;
    pageSize: number;
  };
  submittedBilling: {
    year: number;
    month: number;
    insuranceProviderId: string;
    status: Array<'OPEN' | 'PAID' | 'UNPAID' | 'PARTIAL' | 'NON-BILLABLE'>;
    clientId: string;
    sortBy:
      | 'provider'
      | 'client'
      | 'date'
      | 'units'
      | 'amount'
      | 'status'
      | 'paid';
    sortByOrder: SortDirection;
    page: number;
    pageSize: number;
  };
  reports: {
    userId: string;
    dateType: DateTypes;
    dateStart: Date;
    dateEnd: Date;
    dateFilter: 'dateString' | 'billedAt';
    billingType: string[];
    clientId?: string;
    page: number;
    pageSize: number;
    groupBy: 'clientId' | 'insuranceProviderId';
    locations: string[];
    showDetail: boolean;
  };
}

export function createInitialState(): UxState {
  const appDate = toJsDate();
  appDate.setDate(appDate.getDate() - 1);
  appDate.setHours(0, 0, 0, 0);
  const checkInDate = toJsDate();
  checkInDate.setHours(0, 0, 0, 0);
  return {
    app: {
      multiCompany: false,
    },
    client: {
      q: null,
      sortBy: 'lastName',
      userId: null,
      hideInactive: true,
      sortByOrder: 'asc',
      page: 0,
      pageSize: 25,
    },
    service: {
      year: toJsDate().getFullYear(),
      month: toJsDate().getMonth() + 1,
      userId: null,
      clientId: null,
      syncChange: 1,
      sortBy: 'dateString',
      sortByOrder: Order.DESC,
      page: 0,
      pageSize: 25,
    },
    unProcessedBilling: {
      year: toJsDate().getFullYear(),
      insuranceProviderId: null,
      userId: null,
      sortBy: 'clientInsuranceProvider',
      sortByOrder: Order.ASC,
      clientId: null,
      page: 0,
      pageSize: 25,
    },
    submittedBilling: {
      year: toJsDate().getFullYear(),
      month: toJsDate().getMonth() + 1,
      insuranceProviderId: null,
      status: ['OPEN'],
      sortBy: 'date',
      sortByOrder: 'asc',
      clientId: null,
      page: 0,
      pageSize: 25,
    },
    reports: {
      userId: null,
      dateType: null,
      dateStart: null,
      dateFilter: null,
      dateEnd: toJsDate(),
      billingType: [],
      page: 0,
      pageSize: 25,
      groupBy: 'clientId',
      locations: [],
      showDetail: false,
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class UxStore extends Store<UxState> {
  constructor() {
    super(createInitialState());
  }

  updateService(val: Partial<UxState['service']>) {
    this.update({
      service: {
        ...this.getValue().service,
        ...val,
      },
    });
  }

  updateBilling(val: Partial<UxState['unProcessedBilling']>) {
    this.update({
      unProcessedBilling: {
        ...this.getValue().unProcessedBilling,
        ...val,
      },
    });
  }

  updateSubmitted(val: Partial<UxState['submittedBilling']>) {
    this.update({
      submittedBilling: {
        ...this.getValue().submittedBilling,
        ...val,
      },
    });
  }

  updateClient(val: Partial<UxState['client']>) {
    this.update({
      client: {
        ...this.getValue().client,
        ...val,
      },
    });
  }

  updateApp(val: Partial<UxState['app']>) {
    this.update({
      app: {
        ...this.getValue().app,
        ...val,
      },
    });
  }

  updateReport(val: Partial<UxState['reports']>) {
    this.update({
      reports: {
        ...this.getValue().reports,
        ...val,
      },
    });
  }

  resetValues(key: keyof UxState): void {
    this.update({
      [key]: {
        ...createInitialState()[key],
      },
    });
  }
}
