import { Injectable } from '@angular/core';

import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { Client } from './client.model';

export interface ClientState extends EntityState<Client, string>, ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'client', resettable: true })
export class ClientStore extends EntityStore<ClientState> {
  constructor() {
    super();
  }
}
