<div class="photo">
  <img [src]="photoUrl">
</div>
<div class="details">
  <div class="description-item">
    <div>Address</div>
    <div class="address">{{ client?.address?.split(',').join(', ') }}</div>
  </div>
  <div class="description-item">
    <div>Date of Birth</div>
    <div>
      <span>{{ client?.dateOfBirth | myDate:'DD' }}</span>
      <span> ({{ client?.dateOfBirth | yearsAgo | number:'0.0-0'}} years old)</span>
    </div>
  </div>
  <div class="description-item">
    <div>SSN</div>
    <div class="ssn flex-row flex">
      <div *ngIf="!showSSn" class="flex">
        {{ hiddenSSN | none }}
      </div>
      <div *ngIf="showSSn" class="flex">
        {{ client?.socialSecurityNumber | none }}
      </div>
      <div *ngIf="client?.socialSecurityNumber">
        <mat-icon matTooltip="Show/Hide SSN" (click)="showSSn = !showSSn" class="visible-icon">{{ showSSn ? 'visibility' : 'visibility_off'}}</mat-icon>
      </div>
    </div>
  </div>
  <div class="description-item">
    <div>Notes</div>
    <div style="max-width: 300px;">{{ client?.notes | none }} </div>
  </div>
</div>

