import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { RouterQuery } from '@datorama/akita-ng-router-store';
import { FormlyFieldConfig } from '@ngx-formly/core';

import {
  DialogFormComponent,
  DialogFormOptions,
} from '../formly/form-dialog.component';
import { formlyInput } from '../formly/formly-helpers';
import {
  ClientInsuranceProvider,
  ClientInsuranceProviderQuery,
  ClientInsuranceProviderService,
  ClientService,
  ServiceLogService,
} from '../store';
import { PromptService } from './';
import { InsuranceProviderUxService } from './insurance-provider-ux.service';
import { ToastService } from './toast.service';
import { MatSelectChange } from '@angular/material/select';
import { limit, where } from 'firebase/firestore';

@Injectable({ providedIn: 'root' })
export class ClientInsuranceProviderUxService {
  constructor(
    private service: ClientInsuranceProviderService,
    private query: ClientInsuranceProviderQuery,
    private dialog: MatDialog,
    private prompt: PromptService,
    private toast: ToastService,
    private ipUx: InsuranceProviderUxService,
    private routerQuery: RouterQuery,
    private clientService: ClientService,
    private logService: ServiceLogService
  ) {}

  sync() {
    return this.service.syncCollection({ reset: true });
  }

  getRecord(id: string) {
    return this.query.getEntity(id);
  }

  getAll = this.query.getAll.bind(this.query);

  getEntity = this.query.getEntity.bind(this.query);

  selectEntity(id: string) {
    return this.query.selectEntity(id);
  }

  selectAll = this.query.selectAll.bind(this.query);

  selectAllForActiveClient() {
    const clientId = this.routerQuery.getParams().clientId;
    return this.query.selectAll({
      filterBy: m => m.clientId === clientId,
    });
  }

  // getListOptions() {
  //   return convertListToOptions(this.getAll().map((v) => v.name as string));
  // }

  // getModelOptions() {
  //   return convertModelsToOptions(this.getAll(), 'name');
  // }

  getEditFields(
    entry: Partial<ClientInsuranceProvider> = {},
    keys: string[] = []
  ): FormlyFieldConfig[] {
    const providerInput = this.ipUx.providerSelectInput();
    providerInput.templateOptions.change = field => {
      const providerId = field.formControl?.value;
      const name = this.ipUx.getEntity(providerId)!.name;
      if (field.form?.get('name')?.pristine) {
        field.form?.get('name')?.setValue(name);
      }
    };

    return (
      [
        providerInput,
        {
          type: 'input',
          key: 'name',
          templateOptions: {
            label: 'Nickname',
            required: true,
          },
        },
        formlyInput({ key: 'accountNumber' }),
        formlyInput({ key: 'groupId', required: false }),
        formlyInput({ key: 'policyHolder' }),
        formlyInput({ key: 'policyHolderCompany', required: false }),
        formlyInput({ key: 'notes', type: 'textarea', required: false }),
        {
          key: 'isDefault',
          type: 'checkbox',
          templateOptions: {
            label: 'Is Default',
            disabled: entry.isDefault,
          },
        },
      ] as FormlyFieldConfig[]
    ).filter(thing =>
      keys && keys.length ? keys.includes(thing.key as string) : true
    );
  }

  edit(
    clientInsuranceProvider: ClientInsuranceProvider = {} as ClientInsuranceProvider
  ): Observable<string | null> {
    const options: DialogFormOptions = {
      fields: this.getEditFields(clientInsuranceProvider),
      model: clientInsuranceProvider,
      title: clientInsuranceProvider.id
        ? 'Edit Client Insurance Provider'
        : 'Add Client Insurance Provider',
      remove: clientInsuranceProvider.id
        ? () => this.remove(clientInsuranceProvider)
        : undefined,
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    return dialog.afterClosed().pipe(
      switchMap(val => {
        if (!val) {
          this.toast.open(
            `${
              clientInsuranceProvider.id ? 'Edit provider' : 'Add provider'
            } cancelled`
          );
          return of(null);
        }
        return from(this.saveRecord(val));
      })
    );
  }

  async saveRecord(model: ClientInsuranceProvider): Promise<string> {
    if (model.id) {
      return this.service.update(model).then(() => {
        this.toast.open(`"${model?.name}" Updated`);
        if (model.isDefault) {
          this.updateDefault(model.id, model.clientId);
        }
        return model.id as string;
      });
    }
    return this.service.add(model).then(val => {
      this.toast.open(`"${model?.name}" Added`);
      if (model.isDefault) {
        this.updateDefault(val, model.clientId);
      }
      return val;
    });
  }

  updateDefault(clientProviderId: string, clientId: string) {
    const ids = this.getAll({
      filterBy: m => m.clientId === clientId && m.id !== clientProviderId,
    }).map(v => v.id);
    this.service.update(ids, {
      isDefault: false,
    });
    this.clientService.update(clientId, {
      defaultClientInsuranceProviderId: clientProviderId,
    });
  }

  async remove(model: ClientInsuranceProvider) {
    const hasLogs = await this.logService.getValue([
      where('clientInsuranceProviderId', '==', model.id),
      limit(1),
    ]);
    if (hasLogs) {
      this.prompt.alert(
        'There are logs associated with this provider. You can not delete it.'
      );
      return;
    }
    this.prompt.confirm(`Delete provider: "${model?.name}"`).subscribe(val => {
      if (!val) {
        return;
      }
      this.dialog.closeAll();
      this.service
        .remove(model.id as string)
        .then(() => this.toast.open(`"${model?.name}" removed`));
    });
  }
}
