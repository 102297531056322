import { Injectable } from '@angular/core';

import {
  CollectionConfig,
  CollectionService,
} from 'akita-ng-fire';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { Category } from './category.model';
import {
  CategoryState,
  CategoryStore,
} from './category.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'categories' })
export class CategoryService extends CollectionService<CategoryState> {
  constructor(store: CategoryStore, private routerQuery: RouterQuery) {
    super(store);
  }

  get path() {
    const companyId = this.routerQuery.getParams()?.companyId;
    return `companies/${companyId}/categories`;
  }

  formatToFirestore(model: Category) {
    return {
      ...model,
      ...this.routerQuery.getParams(),
    };
  }
}
