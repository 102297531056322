import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports-shell',
  template: `<router-outlet></router-outlet>`,
  styles: [],
})
export class ReportsShellComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
