import { Injectable } from '@angular/core';

import { Query } from '@datorama/akita';

import { UxState, UxStore } from './ux.store';

@Injectable({ providedIn: 'root' })
export class UxQuery extends Query<UxState> {
  selectServiceFilters = this.select(state => state.service);

  selectUnprocessedBillingFilters = this.select(
    state => state.unProcessedBilling
  );

  selectSubmittedBillingFilters = this.select(state => state.submittedBilling);

  selectClientFilters = this.select(state => state.client);

  selectAppFilters = this.select(state => state.app);

  selectReportFilters = this.select(state => state.reports);

  constructor(protected store: UxStore) {
    super(store);
  }
}
