import { Injectable } from '@angular/core';

import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { User } from './user.model';

export interface UserState extends EntityState<User, string>, ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends EntityStore<UserState> {
  constructor() {
    super();
  }
}
