import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-title-bar',
  template: `
    <div class="title-content">
      <div class="flex-row">
        <button
          mat-icon-button
          *ngIf="showBack"
          [routerLink]="backRoute"
          [matTooltip]="backLabel"
        >
          <mat-icon style="transform: rotate(-180deg);">forward</mat-icon>
        </button>
        <div class="mat-title" style="margin: 0 0 0 0;">{{ header }}</div>
      </div>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        background-color: white;
        margin-bottom: 10px;
        padding: 10px 0 10px;
        display: block;
        position: sticky;
        top: 0;
        z-index: 998;
      }

      .title-content {
        display: flex;
        justify-content: space-between;
        width: 95%;
        max-width: 95%;
        min-width: 95%;
        margin: 0 auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleBarComponent implements OnInit {
  @Input() header: string = '';
  @Input() backLabel: string = 'Go Back';
  @Input() backRoute: string | string[] = ['../'];
  @Input() showBack = false;

  constructor() {}

  ngOnInit(): void {}
}
