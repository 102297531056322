import { Injectable } from '@angular/core';

import {
  CollectionConfig,
  CollectionService,
} from 'akita-ng-fire';

import {
  CompanyState,
  CompanyStore,
} from './company.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'companies' })
export class CompanyService extends CollectionService<CompanyState> {
  constructor(store: CompanyStore) {
    super(store);
  }
}
