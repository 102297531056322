import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
  from,
  Observable,
  of,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { RouterQuery } from '@datorama/akita-ng-router-store';
import { FormlyFieldConfig } from '@ngx-formly/core';

import {
  DialogFormComponent,
  DialogFormOptions,
} from '../formly/form-dialog.component';
import { formlyInput } from '../formly/formly-helpers';
import {
  Company,
  CompanyQuery,
  CompanyService,
} from '../store';

@Injectable({ providedIn: 'root' })
export class CompanyUxService {
  constructor(
    private companyService: CompanyService,
    private companyQuery: CompanyQuery,
    private routerQuery: RouterQuery,
    private dialog: MatDialog
  ) {}

  selectActive() {
    return this.companyQuery.selectActive() as Observable<Company>;
  }

  getActive(): Company {
    return this.companyQuery.getActive() as Company;
  }

  syncActive() {
    return this.companyService.syncActive(
      { id: this.routerQuery.getParams().companyId },
      {
        reset: true,
      }
    );
  }

  getEditFields(
    entry: Partial<Company> = {},
    keys: string[] = []
  ): FormlyFieldConfig[] {
    return ([formlyInput({ key: 'name' })] as FormlyFieldConfig[]).filter(
      (thing) =>
        keys && keys.length ? keys.includes(thing.key as string) : true
    );
  }

  edit(company: Company) {
    const options: DialogFormOptions = {
      fields: this.getEditFields(company),
      model: company,
      title: 'Edit Company',
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    return dialog.afterClosed().pipe(
      switchMap((val) => {
        if (!val) {
          return of();
        }
        return from(this.saveRecord(val));
      })
    );
  }

  async saveRecord(company: Company): Promise<string> {
    if (company.id) {
      return this.companyService.update(company).then(() => company.id);
    }
    return this.companyService.add(company);
  }
}
