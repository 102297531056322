import {
  Component,
  OnInit,
} from '@angular/core';

import { TemplateUxService } from 'src/app/services';
import { Template } from 'src/app/store';

import {
  UntilDestroy,
  untilDestroyed,
} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements OnInit {
  templates: Template[] = [];
  columns = ['name', 'category', 'edit'];

  constructor(private templateUxService: TemplateUxService) {}

  ngOnInit(): void {
    this.templateUxService
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe((val) => (this.templates = val));
  }

  edit(model?: Template) {
    this.templateUxService.edit(model).subscribe();
  }
}
