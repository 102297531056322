import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { where } from 'firebase/firestore';
import { round } from 'lodash';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  AuthService,
  BillingUxService,
  ClientInsuranceProviderUxService,
  ClientUxService,
  InsuranceProviderUxService,
  ServiceLogUxService,
} from 'src/app/services';
import {
  Billing,
  calcUnits,
  Client,
  ClientInsuranceProvider,
  InsuranceProvider,
  Payment,
  ServiceLog,
  UxStore,
} from 'src/app/store';
import { filterNullOrUndefined } from 'src/app/util/custom-rx-pipes';

import { RouterQuery } from '@datorama/akita-ng-router-store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-billing-detail',
  templateUrl: './billing-detail.component.html',
  styles: [
    `
      :host {
        display: block;
        position: relative;
      }
      .client-name {
        position: sticky;
        top: 60px;
        z-index: 987;
        background-color: #fafafa;
      }
      .cards {
        mat-card {
          flex: 1;
        }
        mat-card:not(:last-child) {
          margin-right: 10px;
        }
        .mat-card-content {
          flex-grow: 1;
          overflow: auto;
        }
        .submit-billing {
          display: flex;
          flex-direction: column;
        }
      }

      app-payment-table {
        display: block;
        max-height: 40vh;
        overflow: auto;
      }

      #billing-service-container.is-collapsed {
        app-service-table {
          display: block;
          max-height: 40vh;
          overflow: auto;
        }
      }

      .description-item > div:first-child {
        width: 100px;
        max-width: 35%;
        text-align: right;
        margin-right: 5px;
        font-weight: 300;
      }
      .expand-button-container {
        position: absolute;
        left: 50%;
        bottom: -10px;
      }
    `,
  ],
})
export class BillingDetailComponent implements OnInit {
  client: Client;
  clientProvider: ClientInsuranceProvider;
  insuranceProvider: InsuranceProvider;
  logs: ServiceLog[] = [];
  billing: Billing;
  isCreate = true;
  serviceColumns = ['dateString', 'staff', 'minutes', 'location', 'category'];
  showExpand = false;
  expanded = false;
  get isView() {
    return !this.isCreate;
  }
  unBilledYear: number;

  selectedRows: { [key: string]: boolean } = {};

  get selectedLogs(): ServiceLog[] {
    return this.logs.filter(l => this.selectedRows[l.id]);
  }

  get selectedMinDate() {
    if (this.isCreate) {
      return this.selectedLogs[0]?.dateString;
    }

    return this.logs[0]?.dateString;
  }

  get selectedMaxDate() {
    if (this.isCreate) {
      return this.selectedLogs[this.selectedLogs.length - 1]?.dateString;
    }
    return this.logs[this.logs.length - 1]?.dateString;
  }

  get preTotalRows(): number {
    return this.selectedLogs.length;
  }

  get submitBillingIsDisabled(): boolean {
    return this.selectedLogs.length === 0 || !this.insuranceProvider;
  }

  get preTotalMinutes(): number {
    return this.selectedLogs.reduce((a, c) => (a += c.minutes), 0);
  }

  get preUnits(): number {
    // const ip = this.billing.insuranceProvider || this.insuranceProvider
    if (this.insuranceProvider && this.preTotalMinutes) {
      if (this.insuranceProvider.billServiceBy === 'GROUP') {
        return calcUnits(this.insuranceProvider, this.preTotalMinutes);
      }
      return this.selectedLogs.reduce(
        (a, c) => (a += calcUnits(this.insuranceProvider, c.minutes)),
        0
      );
    }
    return 0;
  }

  get preBillAmount(): number {
    if (this.insuranceProvider) {
      return round(this.insuranceProvider.rate * this.preUnits, 2);
    }
    return 0;
  }

  get selectedServiceIds(): string[] {
    return Object.keys(this.selectedRows).filter(k => this.selectedRows[k]);
  }

  get closedStatus(): string {
    if (this.billing.totalPaymentAmount === 0) {
      return 'UNPAID';
    }

    if (this.billing.totalPaymentAmount < this.billing.totalAmount) {
      return 'PARTIAL';
    }

    return 'PAID';
  }

  get isOpen(): boolean {
    return this.billing?.status === 'OPEN';
  }

  constructor(
    private routerQuery: RouterQuery,
    private billingUx: BillingUxService,
    private clientUx: ClientUxService,
    private serviceUx: ServiceLogUxService,
    private clientProviderUx: ClientInsuranceProviderUxService,
    private providerUx: InsuranceProviderUxService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private uxStore: UxStore
  ) {}

  checkExpand(): void {
    const lastRow = document.querySelector(
      'app-service-table tbody tr:last-of-type'
    );
    const container = document.getElementById('billing-service-container');

    if (lastRow && container) {
      if (
        container.getBoundingClientRect().bottom <
        lastRow.getBoundingClientRect().bottom
      ) {
        this.showExpand = true;
      }
    }
  }

  ngOnInit(): void {
    const { billingId, clientId, clientIpId } = this.routerQuery.getParams();

    this.unBilledYear = this.uxStore.getValue().unProcessedBilling.year;

    if (billingId) {
      this.isCreate = false;
      this.billingUx
        .valueChanges(billingId as string)
        .pipe(
          filterNullOrUndefined(),
          switchMap(val => {
            this.billing = val;
            this.clientProvider = val.clientInsuranceProvider;
            this.insuranceProvider = val.insuranceProvider;
            return combineLatest([
              this.clientUx.selectEntity(val.clientId),
              this.serviceUx.valueChanges([where('billingId', '==', val.id)]),
            ]);
          }),
          untilDestroyed(this)
        )
        .subscribe(([client, service]) => {
          this.client = client;
          this.logs = service.sort((a, b) =>
            a.dateString.localeCompare(b.dateString)
          );
          setTimeout(() => this.checkExpand(), 500);
        });
    } else {
      this.serviceColumns.unshift('selection');
      this.clientUx
        .selectEntity(clientId)
        .pipe(untilDestroyed(this))
        .subscribe(val => {
          this.client = val;
        });
      this.clientProviderUx
        .selectEntity(clientIpId)
        .pipe(
          filterNullOrUndefined(),
          switchMap(val => {
            this.clientProvider = val;
            return this.providerUx.selectEntity(val.insuranceProviderId);
          }),
          untilDestroyed(this)
        )
        .subscribe(val => (this.insuranceProvider = val));
      this.serviceUx
        .selectAll({
          sortBy: 'dateString',
          filterBy: log =>
            log.clientId === clientId &&
            log.clientInsuranceProviderId === clientIpId,
        })
        .pipe(untilDestroyed(this))
        .subscribe(val => {
          this.logs = val;
          this.selectedRows = val.reduce((a, c) => {
            a[c.id] = true;
            return a;
          }, {} as { [key: string]: boolean });
          setTimeout(() => this.checkExpand(), 500);
        });
    }
  }

  editBilling() {
    // const rate = this.insuranceProvider.rate;
    // const minutesToUnits = this.insuranceProvider.minutesToUnits;
    // const alwaysRoundUp = this.insuranceProvider.alwaysRoundUp;
    const totalMinutes = this.logs.reduce((a, c) => (a += c.minutes), 0);
    const totalBillableMinutes = this.preTotalMinutes;
    const totalAmount = this.preBillAmount;
    const totalUnits = this.preUnits;
    const clientId = this.client.id;
    const clientName = this.client.displayName;
    const insuranceProviderId = this.insuranceProvider.id;
    const insuranceProvider = this.insuranceProvider;
    const clientInsuranceProviderId = this.clientProvider.id;
    const clientInsuranceProvider = this.clientProvider;
    const billServiceBy = this.insuranceProvider.billServiceBy;
    const updatedById = this.authService.currentUserId;
    this.billingUx
      .edit(
        {
          // rate,
          // minutesToUnits,
          // alwaysRoundUp,
          totalMinutes,
          totalBillableMinutes,
          totalAmount,
          totalUnits,
          clientId,
          clientName,
          insuranceProviderId,
          insuranceProvider,
          clientInsuranceProviderId,
          clientInsuranceProvider,
          billServiceBy,
          updatedById,
        },
        this.selectedServiceIds
      )
      .subscribe(() => {
        this.goBack();
      });
  }

  resetBilling() {
    this.billingUx.remove(this.billing).subscribe(val => {
      if (val) this.goBack();
    });
  }

  editPayment(payment: Payment = {}) {
    this.billingUx.addPayment(payment, this.billing);
  }

  // updateStatus(status: Billing['status']) {
  //   this.billingUx.updateStatus(this.billing, status);
  // }

  markClosed() {
    this.billingUx.markClosed(this.billing);
  }

  markOpen() {
    this.billingUx.markOpen(this.billing);
  }

  goBack() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
