import { Component, OnInit } from '@angular/core';

import { groupBy } from 'lodash';
import print from 'print-js';
import { combineLatest, from } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  ClientUxService,
  InsuranceProviderUxService,
  PromptService,
  ServiceLogUxService,
  UserUxService,
} from 'src/app/services';
import { ServiceLog, UxQuery } from 'src/app/store';
import { monthNames } from 'src/app/util/client-util';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface DisplayData {
  groupedByLabel: string;
  groupedByTotalUnits: number;
  groupByTotalMinutes: number;
  months: {
    year: number;
    month: number;
    sortBy: string;
    monthTotalUnits: number;
    monthTotalMinutes: number;
    categories: {
      category: string;
      categoryTotalUnits: number;
      categoryTotalMinutes: number;
    }[];
  }[];
}

@UntilDestroy()
@Component({
  selector: 'app-unit-report',
  templateUrl: './unit-report.component.html',
  styles: [
    `
      th.mat-cell.subtotal {
        font-weight: 500;
        padding: 0 10px;
      }
      tr.subtotal {
        height: 35px;
      }
    `,
  ],
})
export class UnitReportComponent implements OnInit {
  results: DisplayData[] = null;

  loading: boolean;

  monthNames = monthNames;

  mainLabel: 'Client' | 'Insurance Provider' = 'Client';

  get resultsAvailable() {
    return !this.loading && this.results?.length;
  }

  constructor(
    private serviceUx: ServiceLogUxService,
    private uxQuery: UxQuery,
    private userUxService: UserUxService,
    private clientUxService: ClientUxService,
    private insuranceProviderUx: InsuranceProviderUxService,
    private prompt: PromptService
  ) {}

  ngOnInit(): void {
    const logs$ = this.uxQuery.selectReportFilters.pipe(
      skip(1),
      distinctUntilChanged((a, b) => {
        return (
          a.dateStart.toDateString() === b.dateStart.toDateString() &&
          a.dateEnd.toDateString() === b.dateEnd.toDateString() &&
          a.dateType === b.dateType &&
          a.dateFilter === b.dateFilter &&
          a.clientId === b.clientId &&
          a.userId === b.userId
        );
      }),
      tap(() => (this.loading = true)),
      switchMap(f =>
        from(
          this.serviceUx.getForDates(
            f.dateFilter,
            f.dateStart,
            f.dateEnd,
            f.userId,
            f.clientId
          )
        )
      )
    );

    combineLatest([this.uxQuery.selectReportFilters, logs$])
      .pipe(
        map(([filters, logs]) => {
          const filtered = logs
            .filter(l =>
              filters.locations.length
                ? filters.locations.includes(l.location)
                : true
            )
            .filter(l =>
              filters.billingType.length
                ? filters.billingType.includes(l.billingStatus)
                : true
            )
            .filter(l =>
              filters.clientId ? filters.clientId === l.clientId : true
            )
            .filter(l => (filters.userId ? filters.userId === l.userId : true));

          const grouped = this.groupByThing(filtered, filters.groupBy);
          grouped.forEach(i => {
            i.groupedByLabel =
              filters.groupBy === 'clientId'
                ? this.clientUxService.getEntity(i.groupedByLabel).displayName
                : this.insuranceProviderUx.getEntity(i.groupedByLabel).name;
          });
          grouped.sort((a, b) =>
            a.groupedByLabel.localeCompare(b.groupedByLabel)
          );
          this.mainLabel =
            filters.groupBy === 'clientId' ? 'Client' : 'Insurance Provider';
          return grouped;
        }),
        untilDestroyed(this)
      )
      .subscribe(val => {
        this.loading = false;
        this.results = val;
      });
  }

  groupByThing(
    logs: ServiceLog[],
    groupBy: 'clientId' | 'insuranceProviderId'
  ): DisplayData[] {
    return this.getGroupByStats(logs, groupBy).map(cl => {
      return {
        groupedByLabel: cl.label,
        groupedByTotalUnits: cl.units,
        groupByTotalMinutes: cl.minutes,
        months: this.getGroupByStats(cl.subLogs, 'month')
          .map(ml => {
            return {
              year: Number(ml.label.slice(0, 4)),
              month: Number(ml.label.slice(-2)),
              sortBy: ml.label,
              monthTotalUnits: ml.units,
              monthTotalMinutes: ml.minutes,
              categories: this.getGroupByStats(ml.subLogs, 'category')
                .map(cl => {
                  return {
                    category: cl.label,
                    categoryTotalMinutes: cl.minutes,
                    categoryTotalUnits: cl.units,
                  };
                })
                .sort((a, b) => a.category.localeCompare(b.category)),
            };
          })
          .sort((a, b) => a.sortBy.localeCompare(b.sortBy)),
      };
    });
  }

  getGroupByStats(
    logs: ServiceLog[],
    field: 'clientId' | 'insuranceProviderId' | 'month' | 'category'
  ): {
    label: string;
    units: number;
    minutes: number;
    subLogs: ServiceLog[];
  }[] {
    return Object.values(groupBy(logs, field)).map(subLogs => {
      const { units, minutes } = this.getSums(subLogs);
      return {
        label: subLogs[0][field].toString(),
        units: units,
        minutes: minutes,
        subLogs,
      };
    });
  }

  getSums(logs: ServiceLog[]): {
    minutes: number;
    units: number;
  } {
    return logs.reduce<{
      minutes: number;
      units: number;
    }>(
      (a, c) => {
        a.minutes += c.minutes || 0;
        a.units += c.units || 0;
        return a;
      },
      { minutes: 0, units: 0 }
    );
  }

  print() {
    this.prompt
      .alert('Note this can take a moment to compile a printed page')
      .subscribe(() => {
        print({
          header: 'Unit Report',
          printable: 'print-table',
          type: 'html',
          targetStyles: '*',
        });
      });
  }
}
