import { Injectable } from '@angular/core';
import { Timestamp, where } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { ApiFile, FilesQuery, FilesService } from '../store';
import { PromptService } from './';
import { AuthService } from './auth-service.service';
import { FileStackService } from './filestack.service';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class FileUxService {
  constructor(
    private service: FilesService,
    private query: FilesQuery,
    private dialog: MatDialog,
    private prompt: PromptService,
    private toast: ToastService,
    private routerQuery: RouterQuery,
    private fileStackService: FileStackService,
    private auth: AuthService
  ) {}

  sync(model: string, id: string) {
    return this.service.syncCollection([where(model, '==', id)], {
      reset: true,
    });
  }

  getRecord(id: string) {
    return this.query.getEntity(id);
  }

  getAll() {
    return this.query.getAll();
  }

  selectEntity(id: string) {
    return this.query.selectEntity(id);
  }

  selectAll() {
    return this.query.selectAll();
  }

  selectAllForActiveClient() {
    const clientId = this.routerQuery.getParams().clientId;
    return this.service.valueChanges([where('clientId', '==', clientId)]);
  }

  // getEditFields(
  //   entry: Partial<ApiFile> = {},
  //   keys: string[] = []
  // ): FormlyFieldConfig[] {
  //   return ([formlyInput({ key: 'file' })] as FormlyFieldConfig[]).filter(
  //     thing => (keys && keys.length ? keys.includes(thing.key as string) : true)
  //   );
  // }

  // edit(file: ApiFile = {}): Observable<string | null> {
  //   const options: DialogFormOptions = {
  //     fields: this.getEditFields(file),
  //     model: file,
  //     title: file.id ? 'Edit File' : 'Add File',
  //     remove: file.id ? () => this.remove(file) : undefined,
  //   };

  //   const dialog = this.dialog.open(DialogFormComponent, {
  //     data: options,
  //   });

  //   return dialog.afterClosed().pipe(
  //     switchMap(val => {
  //       if (!val) {
  //         this.toast.open(`${file.id ? 'Edit file' : 'Add file'} cancelled`);
  //         return of(null);
  //       }
  //       return from(this.saveRecord(val));
  //     })
  //   );
  // }

  uploadFiles(clientId: string) {
    this.fileStackService.pickMany({ path: 'files' }, resp => {
      if (!resp || resp.length === 0) {
        this.toast.open('Add file cancelled');
        return;
      }
      const files: ApiFile[] = resp.map(r => {
        return {
          location: r.key,
          name: r.filename,
          mimetype: r.mimetype,
          clientId,
          size: r.size,
          createdById: this.auth.currentUserId,
          createdAt: Timestamp.now(),
          key: r.handle,
        };
      });
      this.service
        .add(files)
        .then(() => this.toast.success(`${files.length} files added`));
    });
  }

  // saveRecord(model: ApiFile): Promise<string> {
  //   if (model.id) {
  //     return this.service.update(model).then(() => {
  //       this.toast.success(
  //         `"${model.name.substring(0, 15)}${
  //           model.name!.length > 15 ? '...' : ''
  //         }" Updated`
  //       );
  //       return model.id as string;
  //     });
  //   }
  //   return this.service.add(model).then(val => {
  //     this.toast.success(
  //       `"${model.name!.substring(0, 15)}${
  //         model.name!.length > 15 ? '...' : ''
  //       }" Added`
  //     );
  //     return val;
  //   });
  // }

  remove(model: ApiFile) {
    this.prompt
      .confirm(
        `Delete file: "${model.name!.substring(0, 15)}${
          model.name!.length > 15 ? '...' : ''
        }"`
      )
      .subscribe(val => {
        if (!val) {
          return;
        }
        this.dialog.closeAll();
        this.service
          .remove(model.id as string)
          .then(() =>
            this.toast.success(
              `"${model.name!.substring(0, 15)}${
                model.name!.length > 15 ? '...' : ''
              }" removed`
            )
          );
      });
  }

  preview(file: ApiFile) {
    const key = file.location.split('/').reverse()[0].split('_')[0];
    console.log(key);
    this.fileStackService.previewPublicFile(key);
  }
}
