import { Injectable } from '@angular/core';

import { CollectionConfig, CollectionService } from 'akita-ng-fire';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { InsuranceProvider } from './insurance-provider.model';
import {
  InsuranceProviderState,
  InsuranceProviderStore,
} from './insurance-provider.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'insurance_providers' })
export class InsuranceProviderService extends CollectionService<InsuranceProviderState> {
  constructor(store: InsuranceProviderStore, private routerQuery: RouterQuery) {
    super(store);
  }

  get path() {
    const companyId = this.routerQuery.getParams()?.companyId;
    return `companies/${companyId}/insurance_providers`;
  }

  formatToFirestore(model: InsuranceProvider) {
    return {
      ...model,
      companyId: this.routerQuery.getParams()?.companyId,
    };
  }
}
