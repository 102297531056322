<app-title-bar header="Payroll Report" [showBack]="true" [backRoute]="['../']">
  <button mat-button (click)="download()" [disabled]="!results?.length">
    <mat-icon>download</mat-icon>
    <span>Download</span>
  </button>
  <button mat-button (click)="print()" [disabled]="!results?.length">
    <mat-icon>print</mat-icon>
    <span>Print</span>
  </button>
</app-title-bar>
<app-report-filters type="payroll"></app-report-filters>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<table
  id="print-table"
  class="table-alpha mat-table not-stripped"
  role="table"
  *ngIf="!loading && results?.length"
>
  <thead>
    <tr class="mat-header-row">
      <th class="mat-cell">User</th>
      <th class="mat-cell" *ngIf="showDetail">Client</th>
      <th class="mat-cell" *ngIf="showDetail">Date</th>
      <th class="mat-cell right">Rate</th>
      <th class="mat-cell right">Hours / Minutes</th>
      <th class="mat-cell right">Amount</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let result of results">
      <ng-container *ngIf="showDetail">
        <tr
          class="mat-row"
          *ngFor="let row of result.rows; let odd = odd"
          [class.odd-stripe]="odd"
        >
        <td class="mat-cell">{{ result.user }}</td>
        <td class="mat-cell">{{ row.client }}</td>
        <td class="mat-cell">{{ row.date | date }}</td>
        <td class="mat-cell right">{{ result.rate | currency }}</td>
        <td class="mat-cell right">{{ row.hours | number: '0.0-2' }} / {{ row.minutes | number: '0.0-2' }}</td>
        <td class="mat-cell right">{{ row.amount | currency }}</td>
        </tr>
      </ng-container>
      <tr class="mat-header-row" [class.summary-row]="showDetail">
        <td [colSpan]="showDetail ? 3 : 1" class="mat-cell">{{ result.user }} total</td>
        <td class="mat-cell right">{{ result.rate | currency }}</td>
        <td class="mat-cell right">
          {{ result.hours | number: '0.0-2' }}
        </td>
        <td class="mat-cell right">
          {{ result.amount | currency }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<mat-table style="display: none">
  <mat-header-row *matHeaderRowDef="[]"></mat-header-row>
</mat-table>

<app-no-results *ngIf="!loading && results && !results.length"></app-no-results>
