import { Injectable } from '@angular/core';

import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { ServiceLog } from './service-log.model';

export interface ServiceLogState
  extends EntityState<ServiceLog, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'service-log', resettable: true })
export class ServiceLogStore extends EntityStore<ServiceLogState> {
  constructor() {
    super();
  }
}
