import { Injectable } from '@angular/core';

import { QueryConfig, QueryEntity } from '@datorama/akita';

import { TemplateState, TemplateStore } from './template.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'name' })
export class TemplateQuery extends QueryEntity<TemplateState> {
  constructor(protected store: TemplateStore) {
    super(store);
  }
}
