import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'app-report-list',
  template: `
    <ul>
      <li *ngFor="let report of list" [routerLink]="[report.key]">
        <h3>{{ report.label }}</h3>
        <p>{{ report.description }}</p>
      </li>
    </ul>
  `,
  styles: [
    `
      h3 {
        font-weight: 500;
      }
      ul {
        list-style: none;
        padding: 0;
        max-width: 500px;
        margin: 20px auto;
      }
      li {
        padding: 5px 10px;
        background-color: #eeeeee;
        border: 1px solid #dddddd;
        margin: 0 0 15px 0;
        cursor: pointer;
      }
      li:hover {
        background-color: #b0b0b0;
      }
    `,
  ],
})
export class ReportListComponent implements OnInit {
  list: {
    label: string;
    description: string;
    key: string;
  }[] = [];

  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    const payroll = {
      label: 'Payroll report',
      description:
        'If payroll is tied to case notes, this allows you to generate numbers for staff to perform payroll duties',
      key: 'payroll',
    };

    const unit = {
      label: 'Unit report',
      description: 'Total unit report is based on billed case notes only.',
      key: 'unit',
    };

    const logs = {
      label: 'Case note report',
      description: 'Extended searching and downloading of case notes',
      key: 'service',
    };

    if (this.auth.isProjectAdmin) {
      this.list = [payroll, unit, logs];
    } else {
      this.list = [unit, logs];
    }
  }
}
