import { Injectable } from '@angular/core';

import { CollectionConfig, CollectionService } from 'akita-ng-fire';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { ClientInsuranceProvider } from '../';
import {
  ClientInsuranceProviderState,
  ClientInsuranceProviderStore,
} from './client-insurance-provider.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'client_insurance_providers' })
export class ClientInsuranceProviderService extends CollectionService<ClientInsuranceProviderState> {
  constructor(
    store: ClientInsuranceProviderStore,
    private routerQuery: RouterQuery
  ) {
    super(store);
  }

  get path() {
    const companyId = this.routerQuery.getParams()?.companyId;
    return `companies/${companyId}/client_insurance_providers`;
  }

  formatToFirestore(model: ClientInsuranceProvider) {
    const { companyId } = this.routerQuery.getParams();
    return {
      ...model,
      companyId,
    };
  }
}
