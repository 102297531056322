import { Injectable } from '@angular/core';

import { QueryConfig, QueryEntity } from '@datorama/akita';

import { CategoryState, CategoryStore } from './category.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({ sortBy: 'name' })
export class CategoryQuery extends QueryEntity<CategoryState> {
  constructor(protected store: CategoryStore) {
    super(store);
  }
}
