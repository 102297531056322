import { TypeOption } from '@ngx-formly/core/lib/services/formly.config';

import { ArrayMatCardTypeComponent } from './array-type';
import { FileValueAccessor, FormlyFieldFile } from './file-upload.type';
import { FormlyProviderPriceCheck } from './provider-price-check.type';
import { FormlyFieldSearchSelect } from './search-select-type';
import { TextAreaTemplatesInput } from './service-notes.type';
import { FormlyFieldCustomTemplate } from './template.type';

export const formly_components = [
  ArrayMatCardTypeComponent,
  FormlyFieldSearchSelect,
  FormlyFieldCustomTemplate,
  FormlyFieldFile,
  FileValueAccessor,
  FormlyProviderPriceCheck,
  TextAreaTemplatesInput,
];

export const formly_types: TypeOption[] = [
  {
    name: 'textarea-templates',
    component: TextAreaTemplatesInput,
    wrappers: ['form-field'],
  },
  {
    name: 'array-card',
    component: ArrayMatCardTypeComponent,
  },
  {
    name: 'select-search',
    component: FormlyFieldSearchSelect,
    wrappers: ['form-field'],
  },
  {
    name: 'template-ref',
    component: FormlyFieldCustomTemplate,
  },
  {
    name: 'file',
    component: FormlyFieldFile,
  },
  {
    name: 'provider-price-check',
    component: FormlyProviderPriceCheck,
  },
];
