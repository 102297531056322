import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { isEqual } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {
  FormlyFieldConfig,
  FormlyFormBuilder,
  FormlyFormOptions,
} from '@ngx-formly/core';

export interface AdminFormAction<T = any> {
  type: 'SUBMIT' | 'ISDIRTY';
  payload: boolean | T;
}

@Component({
  selector: 'app-form',
  template: `
    <form
      [formGroup]="form"
      novalidate
      (ngSubmit)="submit()"
      autocomplete="off"
    >
      <formly-form
        [model]="model"
        [fields]="fields"
        [form]="form"
        [options]="options"
      >
      </formly-form>
      <button
        type="submit"
        style="display: none;"
        [disabled]="disableSubmit || (form.touched && !form.valid)"
      >
        submit
      </button>
    </form>
    <pre *ngIf="debug">{{ model | json }}</pre>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      formly-form {
        display: flex;
        flex-direction: column;
      }
      formly-group.column {
        display: block;
        column-width: 285px;
      }
      formly-field {
        break-inside: avoid;
        display: block;
      }
      formly-field.field-group + formly-field.field-group {
        margin-top: 15px;
      }
      formly-field section {
        /* border: 1px solid transparent; */
      }
      formly-field section.form-group-invalid {
        background: rgb(220, 53, 69, 0.15);
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnInit, OnDestroy {
  @Input() public autoSubmit = false;
  @Input() public fields: FormlyFieldConfig[] = [];
  @Input() public form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() public model = {};
  @Input() public options: FormlyFormOptions = {};
  @Input() public disableSubmit = false;
  @Output() public action = new EventEmitter();
  @Input() debug = false;

  private autoSubmitSub: Subscription | undefined;
  private isDirtySub: Subscription | undefined;

  constructor(private builder: FormlyFormBuilder) {}

  ngOnInit(): void {
    this.builder.buildForm(this.form, this.fields, this.model, this.options);
    if (this.autoSubmit) {
      this.autoSubmitSub = this.formChanges().subscribe(() => this.submit());
    }

    this.isDirtySub = this.formChanges().subscribe(() =>
      this.action.emit({ type: 'ISDIRTY', payload: this.form.dirty })
    );
  }

  ngOnDestroy(): void {
    if (this.autoSubmitSub) {
      this.autoSubmitSub.unsubscribe();
    }

    if (this.isDirtySub) {
      this.isDirtySub.unsubscribe();
    }
  }

  submit(): void {
    if (this.disableSubmit) {
      return;
    }
    this.action.emit({ type: 'SUBMIT', payload: this.model });
  }

  formChanges(): Observable<any> {
    return this.form.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged((oldValue, newValue) => isEqual(oldValue, newValue))
    );
  }
}
