import {
  Component,
  OnInit,
} from '@angular/core';

import {
  UntilDestroy,
  untilDestroyed,
} from '@ngneat/until-destroy';

import { ContactUxService } from '../services/contact-ux.service';
import { Contact } from '../store';

@UntilDestroy()
@Component({
  selector: 'app-contacts-table',
  template: `
    <table
      class="table-alpha"
      mat-table
      #table
      [dataSource]="contacts"
      *ngIf="contacts.length"
    >
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let row">{{ row.description }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let row">{{ row.type }}</td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>Value</th>
        <td mat-cell *matCellDef="let row">{{ row.value }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="actions">
          <button mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        class="pointer"
        (click)="edit(row)"
        *matRowDef="let row; columns: columns"
      ></tr>
    </table>
    <app-no-results *ngIf="!contacts.length"></app-no-results>
  `,
  styles: [],
})
export class ContactsTableComponent implements OnInit {
  contacts: Contact[] = [];

  columns = ['description', 'type', 'value', 'edit'];

  constructor(private contactUx: ContactUxService) {}

  ngOnInit(): void {
    this.contactUx
      .selectAllForActiveClient()
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.contacts = val;
      });
  }

  edit(contact: Contact) {
    this.contactUx.edit(contact);
  }
}
