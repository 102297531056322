import {
  Component,
  OnInit,
} from '@angular/core';

import { Observable } from 'rxjs';
import { CompanyUxService } from 'src/app/services';
import { Company } from 'src/app/store';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit {
  company$: Observable<Company> | undefined;

  constructor(private companyUx: CompanyUxService) {}

  ngOnInit(): void {
    this.company$ = this.companyUx.selectActive();
  }

  edit() {
    this.companyUx.edit(this.companyUx.getActive()).subscribe();
  }
}
