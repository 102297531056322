import { Injectable } from '@angular/core';

import {
  CollectionConfig,
  CollectionService,
} from 'akita-ng-fire';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { Template } from '../';
import {
  TemplateState,
  TemplateStore,
} from './template.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'templates' })
export class TemplateService extends CollectionService<TemplateState> {
  constructor(store: TemplateStore, private routerQuery: RouterQuery) {
    super(store);
  }

  get path() {
    const companyId = this.routerQuery.getParams()?.companyId;
    return `companies/${companyId}/templates`;
  }

  formatToFirestore(model: Template) {
    return {
      ...model,
      ...this.routerQuery.getParams(),
    };
  }
}
