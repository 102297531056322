<app-title-bar
  header="Case Note Report"
  [showBack]="true"
  [backRoute]="['../']"
>
<button [disabled]="!resultsAvailable" mat-button (click)="print()">
  <mat-icon>print</mat-icon>
  <span>Quick Print</span>
</button>
<button [disabled]="!resultsAvailable" mat-button (click)="pdf()">
  <mat-icon>picture_as_pdf</mat-icon>
  <span>PDF Audit Report</span>
</button>
  <button mat-button [disabled]="!resultsAvailable" (click)="download()">
    <mat-icon>download</mat-icon>
    <span>Download Spreadsheet</span>
  </button>
</app-title-bar>
<app-report-filters type="service"></app-report-filters>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<ng-container *ngIf="resultsAvailable">
  <app-service-table
    id="log-report-services-table"
    [columns]="columns"
    [sortable]="false"
    [loading]="loading"
    [services]="data.results"
    [includeNotes]="true"
  ></app-service-table>

  <mat-paginator
    class="page-container"
    [length]="data.length"
    [pageSize]="filterModel.pageSize"
    [pageIndex]="filterModel.page"
    (page)="pageChange($event)"
    [pageSizeOptions]="[25, 50, 100, 500]"
  >
  </mat-paginator>
</ng-container>
<app-no-results *ngIf="data && !loading && !resultsAvailable"></app-no-results>

