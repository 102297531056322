import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { debounceTime } from 'rxjs/operators';
import { booleanSelectInput, formlyInput } from 'src/app/formly';
import {
  ClientUxService,
  PromptService,
  UserUxService,
} from 'src/app/services';
import { Client, UxQuery, UxState, UxStore } from 'src/app/store';
import { convertToCSVAndDownload, monthNames } from 'src/app/util/client-util';
import { getDateTime, toJsDate } from 'src/app/util/date-util';
import { PaginatedResults } from 'src/app/util/pageinated-results.interface';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';

@UntilDestroy()
@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})
export class ClientListComponent implements OnInit {
  data: PaginatedResults<Client>;

  filterForm: UntypedFormGroup = new UntypedFormGroup({});

  filterModel: Partial<UxState['client']> = {};

  currentYear: number = toJsDate().getFullYear();
  currentMonth: string = monthNames[toJsDate().getMonth()];

  filterFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'form-grid',
      fieldGroup: [
        formlyInput({ key: 'q', label: 'Search', required: false }),
        this.userUx.userSelectInput(false, false, true),
        booleanSelectInput('hideInactive', 'Hide Inactive'),
      ],
    },
  ];

  columns = [
    'firstName',
    'lastName',
    'minutesMonth',
    'minutesYear',
    'unitsMonth',
    'unitsYear',
  ];

  constructor(
    private clientUxService: ClientUxService,
    private router: Router,
    private route: ActivatedRoute,
    private uxStore: UxStore,
    private uxQuery: UxQuery,
    private userUx: UserUxService,
    private prompt: PromptService
  ) {}

  ngOnInit(): void {
    this.filterModel = { ...this.uxQuery.getValue().client };
    this.filterForm.valueChanges
      .pipe(debounceTime(250), untilDestroyed(this))
      .subscribe((val: Partial<UxState['client']>) => {
        val.q = val.q?.toLocaleLowerCase();
        this.uxStore.updateClient({ ...val, page: 0 });
      });
    this.clientUxService
      .selectForList()
      .pipe(untilDestroyed(this))
      .subscribe(val => (this.data = val));
  }

  add() {
    this.clientUxService.edit().subscribe(val => {
      if (val) {
        this.router.navigate([val], { relativeTo: this.route });
      }
    });
  }

  pageChange(val: PageEvent) {
    this.uxStore.updateClient({
      page: val.pageIndex,
      pageSize: val.pageSize,
    });
    document.getElementsByTagName('app-client-list')[0].scrollIntoView({
      behavior: 'smooth',
    });
  }

  sortChange(val: Sort) {
    this.uxStore.updateClient({
      sortBy: val.active as any,
      sortByOrder: val.direction as any,
    });
  }

  download() {
    this.prompt
      .confirm('Download client (using current filters) to a CSV file?')
      .subscribe(val => {
        if (!val) {
          return;
        }
        const clients = this.clientUxService.getForDownload();
        const dateString = getDateTime().toFormat('yyyy-MM-dd');
        convertToCSVAndDownload(clients, `clients-${dateString}.csv`);
      });
  }
}
