import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'formly-field-input',
  template: `
    <textarea
      matInput
      type="input"
      [formControl]="formControl"
      [formlyAttributes]="field"
    ></textarea>
    <button
      type="button"
      mat-icon-button
      class="template-button"
      [matMenuTriggerFor]="templateMenu"
      matTooltip="Insert from template"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <mat-menu #templateMenu="matMenu" xPosition="before">
      <ng-template matMenuContent>
        <button
          (click)="addTemplate(template)"
          mat-menu-item
          *ngFor="let template of to.templates"
        >
          {{ template.label }}
        </button>
      </ng-template>
    </mat-menu>
  `,
  styles: [
    `
      .template-button {
        position: absolute;
        top: 0;
        right: 0;
      }
    `,
  ],
})
export class TextAreaTemplatesInput extends FieldType {
  formControl: UntypedFormControl;

  get templates(): { label: string; value: string }[] {
    if (this.to.templates && this.to.templates.length) {
      return this.to.templates;
    }
    return [
      {
        value: 'none',
        label: 'No templates',
      },
    ];
  }

  addTemplate(template: { label: string; value: string }) {
    if (template.value === 'none') {
      return;
    }
    this.formControl.markAllAsTouched();
    this.formControl.markAsDirty();
    this.formControl.setValue(template.value + (this.value || ''));
  }
}
