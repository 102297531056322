import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sort, SortDirection } from '@angular/material/sort';

import { ServiceLogUxService } from 'src/app/services/service-log-ux.service';

import { ServiceLog } from '../../../store';

@Component({
  selector: 'app-service-table',
  templateUrl: './service-table.component.html',
  styleUrls: ['./service-table.component.scss'],
})
export class ServiceTableComponent implements OnInit {
  @Input() services: ServiceLog[] = [];

  @Input() sortable: boolean = false;

  @Input() columns: string[] = [
    'client',
    'dateString',
    'staff',
    'minutes',
    'location',
    'category',
    'billing',
    'edit',
  ];

  @Input() loading = false;
  @Input() sortActive: string;
  @Input() sortDirection: SortDirection;
  @Input() includeNotes: boolean = false;

  @Output() sortChange: EventEmitter<Sort> = new EventEmitter();

  @Input() selectedRows: { [key: string]: boolean } = {};
  @Output() selectedRowsChange = new EventEmitter<{ [key: string]: boolean }>();

  get isAllSelected() {
    return this.services.every(l => this.selectedRows[l.id]);
  }

  constructor(private serviceUx: ServiceLogUxService) {}

  ngOnInit(): void {}

  edit(log: ServiceLog) {
    console.log(log);
    this.serviceUx.edit(log).subscribe();
  }

  selectRow(log: ServiceLog, ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    this.selectedRows[log.id] = !this.selectedRows[log.id];
    this.selectedRowsChange.emit(this.selectedRows);
  }

  toggleAll(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    if (!this.isAllSelected) {
      this.services.forEach(log => (this.selectedRows[log.id] = true));
    } else {
      this.selectedRows = {};
    }
    this.selectedRowsChange.emit(this.selectedRows);
  }
}
