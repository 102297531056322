import { Component } from '@angular/core';

@Component({
  selector: 'app-unit-info',
  template: `
    <mat-icon
      matTooltip="This is an estimate. Actual units are calculated during billing."
      >info</mat-icon
    >
  `,
  styles: [
    `
      mat-icon {
        font-size: 18px;
        height: 18px;
        width: 18px;
      }
    `,
  ],
})
export class UnitInfoComponent {}
