export enum ContactType {
  PHONE = 'PHONE',
  FAX = 'FAX',
  SMS = 'SMS',
  CELL = 'CELL',
  HOME = 'HOME',
  EMAIL = 'EMAIL',
  WEBSITE = 'WEBSITE',
  ADDRESS = 'ADDRESS'
}

export interface Contact {
  id?: string;
  clientId?: string;
  description?: string;
  type?: ContactType;
  value?: string;
}

export const CONTACT_OPTIONS: string[] = [
  'PHONE',
  'FAX',
  'SMS',
  'CELL',
  'HOME',
  'EMAIL',
  'WEBSITE',
  'ADDRESS',
].sort();

export function createContact(params: Partial<Contact>) {
  return {} as Contact;
}