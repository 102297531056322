import { Injectable } from '@angular/core';

import {
  CollectionConfig,
  CollectionService,
} from 'akita-ng-fire';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { Location } from './location.model';
import {
  LocationState,
  LocationStore,
} from './location.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'locations' })
export class LocationService extends CollectionService<LocationState> {
  constructor(store: LocationStore, private routerQuery: RouterQuery) {
    super(store);
  }

  get path() {
    const companyId = this.routerQuery.getParams()?.companyId;
    return `companies/${companyId}/locations`;
  }

  formatToFirestore(model: Location) {
    return {
      ...model,
      ...this.routerQuery.getParams(),
    };
  }
}
