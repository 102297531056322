<app-title-bar [header]="header">
  <button mat-button (click)="print()">
    <mat-icon>print</mat-icon>
    <span>Print</span>
  </button>
  <button mat-button (click)="download()">
    <mat-icon>download</mat-icon>
    <span>Download</span>
  </button>
</app-title-bar>
<mat-card class="page-container search-filters">
  <app-form
    #formEl
    [fields]="filterFields"
    [model]="filterModel"
    [form]="filterForm"
  >
  </app-form>
</mat-card>
<table
  id="submitted-billing-table"
  class="table-alpha"
  mat-table
  #table
  [dataSource]="billing.results"
  matSort
  (matSortChange)="sortChange($event)"
  [matSortActive]="filterModel.sortBy" 
  [matSortDirection]="filterModel.sortByOrder"
  matSortDisableClear
  *ngIf="billing.length"
>
<ng-container matColumnDef="client">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
  <td mat-cell *matCellDef="let row">{{ row.client }}</td>
</ng-container>

<ng-container matColumnDef="provider">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Provider</th>
  <td mat-cell *matCellDef="let row">{{ row.provider }}</td>
</ng-container>

<ng-container matColumnDef="status">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
  <td mat-cell *matCellDef="let row">
    <div class="status-with-icon">
      <mat-icon class="material-icons-outlined">{{icons[row.status]}}</mat-icon>
      <div>{{ row.status }}</div>
    </div>
  </td>
</ng-container>

<ng-container matColumnDef="date">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</th>
  <td mat-cell *matCellDef="let row">{{ row.date | date}}</td>
</ng-container>

<ng-container matColumnDef="units">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Units</th>
  <td mat-cell *matCellDef="let row" class="center">{{ row.units }}</td>
</ng-container>

<ng-container matColumnDef="amount">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
  <td mat-cell *matCellDef="let row" class="right">{{ row.amount | currency }}</td>
</ng-container>

<ng-container matColumnDef="paid">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid</th>
  <td mat-cell *matCellDef="let row" class="right">{{ row.paid | currency }}</td>
</ng-container>



<tr mat-header-row *matHeaderRowDef="columns"></tr>
<tr
  mat-row
  [routerLink]="[
    row.id
  ]"
  class="pointer"
  *matRowDef="let row; columns: columns"
></tr>
</table>
<app-no-results *ngIf="!billing?.results?.length"></app-no-results>
<mat-paginator
  class="page-container"
  [length]="billing.length"
  [pageSize]="filterModel.pageSize"
  [pageIndex]="filterModel.page"
  (page)="pageChange($event)"
  [pageSizeOptions]="[25, 50, 100, 500]"
>
</mat-paginator>