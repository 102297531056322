import { Component, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ClientInsuranceProviderUxService } from '../../services';
import { ClientInsuranceProvider, Contact } from '../../store';

@UntilDestroy()
@Component({
  selector: 'app-client-provider-table',
  template: `
    <table
      class="table-alpha"
      mat-table
      #table
      [dataSource]="providers"
      *ngIf="providers.length"
    >
      <ng-container matColumnDef="provider">
        <th mat-header-cell *matHeaderCellDef>Provider</th>
        <td mat-cell *matCellDef="let row">
          {{ row.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>Notes</th>
        <td mat-cell *matCellDef="let row">{{ row.notes }}</td>
      </ng-container>

      <ng-container matColumnDef="default">
        <th mat-header-cell *matHeaderCellDef>Default</th>
        <td mat-cell *matCellDef="let row">
          {{ row.isDefault | boolean }}
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="actions">
          <button mat-icon-button (click)="edit(row)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>
    <app-no-results *ngIf="!providers.length"></app-no-results>
  `,
  styles: [],
})
export class ClientProviderTableComponent implements OnInit {
  providers: ClientInsuranceProvider[] = [];

  columns = ['provider', 'notes', 'default', 'edit'];

  constructor(private uxService: ClientInsuranceProviderUxService) {}

  ngOnInit(): void {
    this.uxService
      .selectAllForActiveClient()
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        this.providers = val;
      });
  }

  edit(contact: Contact) {
    this.uxService.edit(contact).subscribe();
  }
}
