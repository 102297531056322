<app-title-bar header="Service Templates">
  <button mat-button (click)="edit()" color="primary">
    <mat-icon>add_box</mat-icon>
    <span>Add Template</span>
  </button>
</app-title-bar>

<table class="table-alpha" mat-table #table [dataSource]="templates" *ngIf="templates.length">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let row">{{ row.name }}</td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let row">{{ row.category }}</td>
  </ng-container>


  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row" class="actions">
      <button mat-icon-button >
        <mat-icon>edit</mat-icon>
      </button>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row class="pointer" (click)="edit(row)" *matRowDef="let row; columns: columns"></tr>
</table>
<app-no-results *ngIf="!templates.length"></app-no-results>
