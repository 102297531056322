import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Note } from './note.model';

export interface NotesState extends EntityState<Note, string>, ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notes' })
export class NotesStore extends EntityStore<NotesState> {

  constructor() {
    super();
  }

}
