<app-title-bar header="Staff List">
  <button mat-button (click)="edit()" color="primary">
    <mat-icon>add_box</mat-icon>
    <span>Add Staff</span>
  </button>
</app-title-bar>

<table class="table-alpha" mat-table #table [dataSource]="users" *ngIf="users.length">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let row">{{ row.displayName }}</td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let row">{{ row.emailAddress }}</td>
  </ng-container>

  <ng-container matColumnDef="access">
    <th mat-header-cell *matHeaderCellDef>Access</th>
    <td mat-cell *matCellDef="let row">{{ row.access }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row (click)="edit(row)" class="pointer" *matRowDef="let row; columns: columns"></tr>
</table>
<app-no-results *ngIf="!users.length"></app-no-results>
