import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private snackBar: MatSnackBar) {}

  open(
    message: string,
    action?: string,
    config?: MatSnackBarConfig
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, {
      duration: 5000,
      politeness: 'polite',
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      ...config,
    });
  }

  error(
    message: string,
    action?: string,
    config?: MatSnackBarConfig
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.open(message, action, {
      ...config,
      panelClass: 'toast-error',
    });
  }

  success(
    message: string,
    action?: string,
    config?: MatSnackBarConfig
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.open(message, action, {
      ...config,
      panelClass: 'toast-success',
    });
  }

  info(
    message: string,
    action?: string,
    config?: MatSnackBarConfig
  ): MatSnackBarRef<TextOnlySnackBar> {
    return this.open(message, action, {
      ...config,
      panelClass: 'info',
    });
  }

  dismiss() {
    this.snackBar.dismiss();
  }
}
