<app-title-bar header="Company Details">
  <button mat-button (click)="edit()" color="primary">
    <mat-icon>edit</mat-icon>
    <span>Edit Info</span>
  </button>
</app-title-bar>
<mat-card *ngIf="company$ | async as company" class="page-container">
  <div class="description-item">
    <div>Name</div>
    <div>{{ company.name }}</div>
  </div>
</mat-card>