import {
  Component,
  OnInit,
} from '@angular/core';

import { InsuranceProviderUxService } from 'src/app/services';
import { InsuranceProvider } from 'src/app/store';

import {
  UntilDestroy,
  untilDestroyed,
} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-insurance-providers',
  templateUrl: './insurance-providers.component.html',
  styleUrls: ['./insurance-providers.component.scss'],
})
export class InsuranceProvidersComponent implements OnInit {
  insuranceProviders: InsuranceProvider[] = [];
  columns = ['name', 'delete'];

  constructor(private insuranceProviderUxService: InsuranceProviderUxService) {}

  ngOnInit(): void {
    this.insuranceProviderUxService
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe((val) => (this.insuranceProviders = val));
  }

  edit(model: InsuranceProvider = {}) {
    this.insuranceProviderUxService.edit(model).subscribe();
  }

  remove(model: InsuranceProvider) {
    this.insuranceProviderUxService.remove(model);
  }
}
