import { Injectable } from '@angular/core';
import {
  limit,
  orderBy,
  QueryConstraint,
  startAfter,
} from '@angular/fire/firestore';

import { CollectionConfig, CollectionService } from 'akita-ng-fire';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { User } from './user.model';
import { UserState, UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'companies/:companyId/users' })
export class UserService extends CollectionService<UserState> {
  constructor(store: UserStore, private routerQuery: RouterQuery) {
    super(store);
  }

  get path() {
    const companyId = this.routerQuery.getParams()?.companyId;
    return `companies/${companyId}/users`;
  }

  formatFromFirestore(entity: User): User {
    return {
      ...entity,
      isAdmin: entity.access === 'admin',
      displayName: entity.lastName
        ? `${entity.lastName}, ${entity.firstName}`.toLocaleUpperCase()
        : entity.emailAddress?.split('@')[0],
      sortProp: (
        (entity.lastName || '') +
        (entity.firstName || '') +
        (entity.emailAddress || '')
      ).toLocaleLowerCase(),
    };
  }

  async fetchAll(): Promise<User[]> {
    const orderByField = 'id';
    const records: User[] = [];
    const qLimit = 500;
    const query: QueryConstraint[] = [orderBy(orderByField), limit(qLimit)];
    let lastId: any = null;
    let hasNext = true;
    while (hasNext) {
      let q: QueryConstraint[];
      if (lastId) {
        q = [...query, startAfter(lastId)];
      } else {
        q = [...query];
      }
      const results = await this.getValue(q);
      records.push(...results);
      lastId = results[results.length - 1][orderByField];
      if (results.length < qLimit) {
        hasNext = false;
      }
    }
    return records;
  }

  formatToFirestore(model: User) {
    return {
      ...model,
      ...this.routerQuery.getParams(),
    };
  }
}
