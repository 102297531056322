import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <ng-container *ngFor="let field of field.fieldGroup; let i = index">
      <mat-card style="margin-bottom: 15px">
        <mat-card-title *ngIf="to.title"> {{ to.title }} </mat-card-title>
        <mat-card-subtitle> {{ subTitle }} # {{ i + 1 }} </mat-card-subtitle>
        <mat-card-content>
          <formly-field class="col" [field]="field"></formly-field>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button type="button" (click)="remove(i)">
            {{ removeText }}
          </button>
        </mat-card-actions>
      </mat-card>
    </ng-container>
    <div>
      <button mat-button type="button" (click)="add()">
        {{ addText }}
      </button>
    </div>
  `,
})
export class ArrayMatCardTypeComponent extends FieldArrayType {
  get addText() {
    return this.to.addText || 'Add';
  }

  get removeText() {
    return this.to.removeText || 'Remove';
  }

  get subTitle() {
    return this.to.subTitle || 'Item';
  }
}
