import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  AppShellComponent,
  BillingShellComponent,
  BlankOutletComponent,
  CategoriesComponent,
  ClientDetailComponent,
  ClientListComponent,
  CompanyDetailsComponent,
  CompanySelectionComponent,
  CompanyShellComponent,
  InsuranceProvidersComponent,
  LocationsComponent,
  LoginComponent,
  ServiceListComponent,
  SubmittedBillingComponent,
  TemplatesComponent,
  UnprocessedBillingComponent,
  UserListComponent,
} from './components';
import { BillingDetailComponent } from './components/billing-detail/billing-detail.component';
import { FirebaseLinksComponent } from './components/firebase-links.component';
import { FoobarComponent } from './components/foobar.component';
import { LogoutComponent } from './components/logout.component';
import { ReportsShellComponent } from './components/reports-shell.component';
import { PayrollReportComponent } from './components/reports/payroll-report/payroll-report.component';
import { ReportListComponent } from './components/reports/report-list.component';
import { ServiceLogReportComponent } from './components/reports/service-log/service-log-report.component';
import { UnitReportComponent } from './components/reports/unit-report/unit-report.component';
import { AuthGuard, ProjectGuard } from './services/auth.guard';

const appChildren: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'service',
  },
  {
    path: 'home',
    pathMatch: 'full',
    redirectTo: 'service',
    // component: HomeComponent,
  },
  {
    path: 'service',
    component: ServiceListComponent,
  },
  {
    path: 'clients',
    component: ClientListComponent,
  },
  {
    path: 'clients/:clientId',
    component: ClientDetailComponent,
  },
  {
    path: 'staff',
    component: UserListComponent,
  },
  {
    path: 'foobar',
    component: FoobarComponent,
  },
  {
    path: 'company',
    component: CompanyShellComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'company-settings',
      },
      {
        path: 'company-settings',
        component: CompanyDetailsComponent,
      },
      {
        path: 'categories',
        component: CategoriesComponent,
      },
      {
        path: 'templates',
        component: TemplatesComponent,
      },
      {
        path: 'locations',
        component: LocationsComponent,
      },
      {
        path: 'insurance',
        component: InsuranceProvidersComponent,
      },
    ],
  },
  {
    path: 'billing',
    component: BillingShellComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'unprocessed',
      },
      {
        path: 'unprocessed',
        children: [
          {
            path: '',
            component: UnprocessedBillingComponent,
          },
          {
            path: 'create',
            component: BillingDetailComponent,
          },
        ],
      },
      {
        path: 'submitted',
        children: [
          {
            path: '',
            component: SubmittedBillingComponent,
          },
          {
            path: ':billingId',
            component: BillingDetailComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'reports',
    component: ReportsShellComponent,
    children: [
      {
        path: '',
        component: ReportListComponent,
      },
      {
        path: 'payroll',
        component: PayrollReportComponent,
      },
      {
        path: 'unit',
        component: UnitReportComponent,
      },
      {
        path: 'service',
        component: ServiceLogReportComponent,
      },
    ],
  },
];

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    path: 'actions',
    component: FirebaseLinksComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'app',
    component: BlankOutletComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CompanySelectionComponent,
      },
      {
        path: 'comp/:companyId',
        canActivate: [ProjectGuard],
        component: AppShellComponent,
        children: appChildren,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
