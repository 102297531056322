import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Timestamp, where } from 'firebase/firestore';
import { round } from 'lodash';
import { from, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { FormlyFieldConfig } from '@ngx-formly/core';

import {
  DialogFormComponent,
  DialogFormOptions,
} from '../formly/form-dialog.component';
import { booleanSelectInput, formlyInput } from '../formly/formly-helpers';
import {
  Billing,
  BillingQuery,
  BillingService,
  ClientInsuranceProviderService,
  ClientQuery,
  InsuranceProviderQuery,
  NotesService,
  Payment,
  ServiceLogService,
  UxQuery,
} from '../store';
import { getDateTime, toJsDate } from '../util/date-util';
import { PaginatedResults } from '../util/pageinated-results.interface';
import { PromptService } from './';
import { AuthService } from './auth-service.service';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class BillingUxService {
  constructor(
    private service: BillingService,
    private query: BillingQuery,
    private dialog: MatDialog,
    private prompt: PromptService,
    private toast: ToastService,
    private logService: ServiceLogService,
    private uxQuery: UxQuery,
    private ipQuery: InsuranceProviderQuery,
    private clientIpService: ClientInsuranceProviderService,
    private clientQuery: ClientQuery,
    private auth: AuthService,
    private noteService: NotesService
  ) {}

  sync() {
    return this.uxQuery.selectSubmittedBillingFilters.pipe(
      distinctUntilChanged(
        (a, b) => `${a.year}${a.month}` === `${b.year}${b.month}`
      ),
      switchMap(filters => {
        const month = String(filters.month).padStart(2, '0');
        const monthStart = `${filters.year}-${month}-00`;
        const monthEnd = `${filters.year}-${month}-32`;

        const q = [
          where('billedAt', '>', monthStart),
          where('billedAt', '<', monthEnd),
        ];

        // if (filters.status !== 'ALL') {
        //   q.push(where('status', '==', filters.status));
        // }

        return this.service.syncCollection(q, { reset: true, loading: true });
      })
    );
  }

  valueChanges = this.service.valueChanges.bind(this.service);

  getEntity = this.query.getEntity.bind(this.query);

  getAll = this.query.getAll.bind(this.query);

  selectEntity = this.query.selectEntity.bind(this.query);

  selectAll = this.query.selectAll.bind(this.query);

  selectAllForList() {
    return this.uxQuery.selectSubmittedBillingFilters.pipe(
      switchMap(filters => {
        return this.selectAll().pipe(
          map(items => {
            const results = items
              .filter(i => {
                if (!filters.status.length) {
                  return true;
                }
                return filters.status.includes(i.status);
              })
              .filter(i =>
                filters.insuranceProviderId
                  ? i.insuranceProviderId === filters.insuranceProviderId
                  : true
              )
              .filter(i =>
                filters.clientId ? i.clientId === filters.clientId : true
              )
              .map(item => ({
                id: item.id,
                status: item.status,
                provider: this.ipQuery.getEntity(item.insuranceProviderId).name,
                client: this.clientQuery.getEntity(item.clientId).displayName,
                date: item.billedAt,
                units: item.totalUnits,
                amount: item.totalAmount,
                paid: item.totalPaymentAmount || 0,
              }))
              .sort((a, b) => {
                if (
                  filters.sortBy === 'units' ||
                  filters.sortBy === 'amount' ||
                  filters.sortBy === 'paid'
                ) {
                  return a[filters.sortBy] > b[filters.sortBy]
                    ? 1
                    : a[filters.sortBy] === b[filters.sortBy]
                    ? 0
                    : -1;
                }
                return a[filters.sortBy].localeCompare(b[filters.sortBy]);
              });
            if (filters.sortByOrder === 'desc') {
              results.reverse();
            }
            return <PaginatedResults>{
              results: results.slice(
                filters.pageSize * filters.page,
                filters.pageSize * filters.page + filters.pageSize
              ),
              pageIndex: filters.page,
              length: results.length,
              pageSize: filters.pageSize,
              allResults: results,
            };
          })
        );
      })
    );
  }

  getEditFields(
    entry: Partial<Billing> = {},
    keys: string[] = []
  ): FormlyFieldConfig[] {
    return (
      [
        booleanSelectInput('isBillable', 'Is Billable'),
        {
          type: 'datepicker',
          key: 'billedAt',
          defaultValue: toJsDate(),
          templateOptions: {
            label: 'Submitted on',
            required: true,
          },
        },
        formlyInput({ key: 'note', type: 'textarea', required: false }),
      ] as FormlyFieldConfig[]
    ).filter(thing =>
      keys && keys.length ? keys.includes(thing.key as string) : true
    );
  }

  edit(billing: Billing, selectedServiceIds: string[]) {
    const options: DialogFormOptions = {
      fields: this.getEditFields(billing),
      model: {},
      title: billing.id ? 'Edit Billing' : 'Submit Billing',
      remove: billing.id ? () => this.remove(billing) : undefined,
      labels: { primary: 'Submit' },
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    return dialog.afterClosed().pipe(
      switchMap((val: Billing) => {
        if (!val) {
          this.toast.open(
            `${billing.id ? 'Edit billing' : 'Submit billing'} cancelled`
          );
          return of();
        }

        const updatedBilling: Billing = {
          ...billing,
          status: val.isBillable ? 'OPEN' : 'NON-BILLABLE',
          note: val.note || null,
          billedAt: getDateTime(val.billedAt).toFormat('yyyy-MM-dd'),
          updatedAtDate: Timestamp.now(),
          updatedById: this.auth.currentUserId,
          totalPaymentAmount: 0,
          payments: [],
        };
        return from(this.saveBilling(updatedBilling, selectedServiceIds));
      })
    );
  }

  async saveBilling(
    model: Billing,
    selectedServiceIds: string[]
  ): Promise<string> {
    if (model.id) {
      console.warn('Edit not turned on');
      return model.id;
      // return this.service.update(model).then(() => {
      //   this.toast.success(`Billing Updated`);
      //   return model.id as string;
      // });
    }
    model.payments = [];
    const addedId = await this.service.add(model);
    if (model.note) {
      await this.noteService.add({
        note: model.note,
        billingId: addedId,
      });
    }
    await this.logService.update(selectedServiceIds, {
      billingId: addedId,
      billingStatus: model.status,
      billedAt: model.billedAt,
    });
    await this.updateClientInsuranceProvider(
      model.clientInsuranceProviderId,
      model.totalMinutes
    );
    this.toast.success(`Billing Added`);
    return addedId;
  }

  remove(model: Billing) {
    return this.prompt
      .confirm({
        title: `Reset billing?`,
        text: 'This will remove this billing entry and move the service back into unprocessed.',
      })
      .pipe(
        tap(async val => {
          if (!val) {
            return;
          }
          this.dialog.closeAll();
          await this.service.remove(model.id as string);
          await this.updateRelatedService(model.id, null);
          await this.updateClientInsuranceProvider(
            model.clientInsuranceProviderId,
            model.totalMinutes * -1
          );
          this.toast.open('Billing reset');
        })
      );
  }

  addPayment(payment: Payment = {}, billing: Billing) {
    const hadId: boolean = !!payment.id;

    const fields: FormlyFieldConfig[] = [
      {
        key: 'paidAt',
        type: 'datepicker',
        defaultValue: toJsDate(),
        templateOptions: {
          label: 'Paid On',
          required: true,
          readonly: billing.status !== 'OPEN',
        },
      },
      {
        key: 'amount',
        type: 'input',
        defaultValue: billing.totalAmount - billing.totalPaymentAmount,
        templateOptions: {
          label: 'Amount',
          type: 'number',
          required: true,
          readonly: billing.status !== 'OPEN',
        },
      },
      {
        key: 'notes',
        type: 'textarea',
        templateOptions: {
          label: 'Notes',
          readonly: billing.status !== 'OPEN',
        },
      },
    ];

    const options: DialogFormOptions = {
      fields: fields,
      model: {
        ...payment,
      },
      title: hadId ? 'Edit Payment' : 'Add Payment',
      remove: hadId ? () => this.removePayment(payment, billing) : undefined,
      readonly: billing.status !== 'OPEN',
      readonlyMessage: 'Form is readonly since billing is marked Closed ',
      labels: { close: billing.status !== 'OPEN' ? 'Close' : 'Cancel' },
      saveOnPristine: true,
    };

    this.dialog
      .open(DialogFormComponent, {
        data: options,
      })
      .afterClosed()
      .subscribe(val => {
        if (!val) {
          if (!options.readonly) {
            this.toast.open(
              `${hadId ? 'Edit payment' : 'Submit payment'} cancelled`
            );
          }
          return;
        }

        const updatedPayment: Payment = {
          id: Math.random().toString(36).slice(-5),
          billingId: billing.id,
          ...payment,
          amount: val.amount,
          notes: val.notes || null,
          paidAt: getDateTime(val.paidAt).toFormat('yyyy-MM-dd'),
          updatedById: this.auth.currentUserId,
          updatedAtDate: Timestamp.now(),
        };

        const payments: Payment[] = [
          updatedPayment,
          ...billing.payments.filter(p => p.id !== updatedPayment.id),
        ].sort((a, b) => a.paidAt.localeCompare(b.paidAt));

        const totalAmount = round(
          payments.reduce((a, c) => (a += c.amount), 0),
          2
        );

        if (totalAmount > billing.totalAmount) {
          this.prompt
            .confirm(
              'Total amount of payments ' +
                `($${totalAmount.toFixed(2)}) ` +
                "is more than this billing's amount of " +
                `($${billing.totalAmount.toFixed(2)}). ` +
                'Are you sure you want to make this change?'
            )
            .subscribe(val => {
              if (!val) {
                return;
              }
              this.updatePayments(payments, billing);
            });
        } else {
          this.updatePayments(payments, billing);
        }
      });
  }

  updatePayments(payments: Payment[], billing: Billing) {
    const totalPaymentAmount = round(
      payments.reduce((a, c) => (a += c.amount), 0),
      2
    );

    this.service
      .update(billing.id, { payments, totalPaymentAmount })
      .then(() => {
        this.toast.success('Payment updated');

        if (totalPaymentAmount >= billing.totalAmount) {
          this.prompt
            .confirm('Would you like to mark this billing as PAID?')
            .subscribe(val => {
              if (!val) return;
              this.updateStatus(billing, 'PAID');
            });
        }
      });
  }

  async updateStatus(billing: Billing, status: Billing['status']) {
    await this.service.update(billing.id, { status });
    await this.updateRelatedService(billing.id, status);
    this.toast.success('Billing status updated');
  }

  async updateRelatedService(billingId: string, status: string) {
    const logs = await this.logService.getValue([
      where('billingId', '==', billingId),
    ]);
    if (logs.length) {
      await this.logService.update(
        logs.map(l => l.id),
        {
          billingId: !status ? null : billingId,
          billingStatus: status,
          billedAt: null,
        }
      );
    }
  }

  removePayment(payment: Payment, billing: Billing) {
    this.prompt.confirm('Remove this payment?').subscribe(val => {
      if (!val) {
        return;
      }
      this.dialog.closeAll();
      const payments = billing.payments.filter(p => p.id !== payment.id);
      this.updatePayments(payments, billing);
    });
  }

  markClosed(billing: Billing) {
    const paidInFull = billing.totalPaymentAmount >= billing.totalAmount;
    if (billing.totalPaymentAmount === 0) {
      this.updateStatus(billing, 'UNPAID');
    }
    this.updateStatus(billing, paidInFull ? 'PAID' : 'PARTIAL');
  }

  markOpen(billing: Billing) {
    this.updateStatus(billing, 'OPEN');
  }

  async updateClientInsuranceProvider(
    clientInsuranceProviderId: string,
    amount: number
  ) {
    // CURRENTLY NOT DOING THIS RIGHT NOW. WILL RE-INTRODUCE IN THE FUTURE.
    // THIS WAS A WAY TO MARK THIS WITH UNPROCESSED BILLING TO MAKE IT EASIER
    // TO SEE ALL UNPROCESSED BILLING.
    return;
    // await this.clientIpService.update(clientInsuranceProviderId, {
    //   unprocessedMinutes: increment(amount) as any,
    // });
  }
}
