import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UAParser } from 'ua-parser-js';

import { FormlyFieldConfig } from '@ngx-formly/core';

import {
  DialogFormComponent,
  DialogFormOptions,
} from '../formly/form-dialog.component';
import {
  convertListToOptions,
  convertModelsToOptions,
  formlyInput,
} from '../formly/formly-helpers';
import { User, UserQuery, UserService } from '../store';
import { rxConvertModelsToOptions } from '../util/custom-rx-pipes';
import { getDateTime } from '../util/date-util';
import { PromptService } from './';
import { AuthService } from './auth-service.service';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class UserUxService {
  constructor(
    private service: UserService,
    private query: UserQuery,
    private dialog: MatDialog,
    private prompt: PromptService,
    private toast: ToastService,
    private auth: AuthService
  ) {}

  userSelectInput(
    openSelectBox: boolean = false,
    required = true,
    addEmpty = false,
    includeDisabled = false
  ): FormlyFieldConfig {
    return {
      type: 'select-search',
      key: 'userId',
      templateOptions: {
        openSelectBox: openSelectBox,
        required: required,
        label: 'Staff',
        labelProp: 'displayName',
        valueProp: 'id',
        compareWith: (a: User, b: User) => a && b && a === b,
        searchObs: (term: string, value: string) => {
          const _term = term.toLowerCase();
          return this.query
            .selectAll({
              filterBy: u =>
                (includeDisabled || u.access !== 'disabled') &&
                u.sortProp!.includes(_term),
            })
            .pipe(
              map(users => {
                if (value && this.getEntity(value)) {
                  users.unshift(this.getEntity(value));
                }
                if (addEmpty) {
                  users.unshift({ displayName: 'All', id: null });
                }
                return users;
              })
            );
        },
      },
    };
  }

  sync() {
    return this.service.syncCollection({ reset: true });
  }

  getEntity(id: string) {
    return this.query.getEntity(id);
  }

  getAll = this.query.getAll.bind(this.query);

  selectEntity = this.query.selectEntity.bind(this.query);

  selectAll() {
    return this.query.selectAll();
  }

  selectFilteredOptions(term: string) {
    return of(this.getAll().filter(u => u.sortProp?.includes(term)));
  }

  getListOptions() {
    return convertListToOptions(
      this.getAll().map(v => v.displayName as string)
    );
  }

  getModelOptions() {
    return convertModelsToOptions(this.getAll(), 'displayName');
  }

  selectModelOptions() {
    return this.selectAll().pipe(rxConvertModelsToOptions('displayName', 'id'));
  }

  getEditFields(
    entry: Partial<User> = {},
    keys: string[] = []
  ): FormlyFieldConfig[] {
    return (
      [
        formlyInput({ key: 'firstName' }),
        formlyInput({ key: 'lastName' }),
        {
          key: 'emailAddress',
          type: 'input',
          required: true,
          templateOptions: {
            readonly: entry.id,
            label: 'Email Address',
            type: 'email',
            description: entry.id ? 'Email address can not be edited' : null,
          },
        },
        {
          key: 'access',
          type: 'select',
          templateOptions: {
            label: 'Access',
            required: true,
            options: convertListToOptions(['user', 'admin', 'disabled']),
          },
        },
        {
          key: 'payRate',
          type: 'input',
          defaultValue: 0,
          templateOptions: {
            label: 'Pay rate',
            type: 'number',
            required: true,
          },
        },
        formlyInput({ key: 'serviceSignature' }),
      ] as FormlyFieldConfig[]
    ).filter(thing =>
      keys && keys.length ? keys.includes(thing.key as string) : true
    );
  }

  edit(user: User = {}) {
    const options: DialogFormOptions = {
      fields: this.getEditFields(user),
      model: user,
      title: user.id ? 'Edit User' : 'Add User',
      remove: user.id ? () => this.remove(user) : undefined,
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    return dialog.afterClosed().pipe(
      switchMap(val => {
        if (!val) {
          this.toast.open(`${user.id ? 'Edit user' : 'Add user'} cancelled`);
          return of();
        }
        return from(this.saveRecord(val));
      })
    );
  }

  async saveRecord(model: User): Promise<string> {
    model.emailAddress = model.emailAddress.trim().toLocaleLowerCase();
    if (model.id === this.auth.currentUserId && model.access === 'disabled') {
      this.prompt.alert('You are not allowed to disable yourself');
      return null;
    }
    if (model.id) {
      return this.service.update(model).then(() => {
        this.toast.success(`"${model.displayName}" Updated`);
        return model.id as string;
      });
    }
    model.id = model.emailAddress;
    return this.service.add(model).then(val => {
      this.toast.success(`User Added`);
      return val;
    });
  }

  remove(model: User) {
    this.prompt
      .confirm(`Delete user: "${model.displayName}"`)
      .subscribe(val => {
        if (!val) {
          return;
        }
        if (model.id === this.auth.currentUserId) {
          this.prompt.alert('You are not allowed to remove yourself');
          return;
        }
        this.dialog.closeAll();
        this.service
          .remove(model.id as string)
          .then(() => this.toast.open(`"${model.displayName}" removed`));
      });
  }

  updateCurrentUserLastLoginAt() {
    const parser = new UAParser();
    this.service.update(this.auth.currentUserId, {
      lastLoginAt: getDateTime().toJSDate().toISOString(),
      meta: {
        browser: parser.getResult().browser,
        os: parser.getResult().os,
        lang: navigator.language,
      },
    });
  }
}
