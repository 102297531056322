import { Component, Inject, Injectable } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { AskOptionOption, AskOptions } from './prompt-options.interface';
import { askTypes } from './prompt-types.enum';

@Injectable({ providedIn: 'root' })
export class PromptService {
  // dialogRef: MatDialogRef<AskDialogComponent>;

  constructor(private dialog: MatDialog) {}

  alert(config: string | AskOptions): Observable<boolean> {
    const defaultOptions: AskOptions = {
      type: askTypes.confirm,
      title: 'Alert',
      okText: 'Ok',
    };

    const options: Partial<AskOptions> = {};

    if (typeof config === 'string') {
      options.text = config;
    } else {
      Object.assign(options, config);
    }

    const dialogRef = this.dialog.open(PromptDialogComponent, {
      data: Object.assign(defaultOptions, options),
    });

    return dialogRef.afterClosed();
  }

  confirm(config: string | AskOptions): Observable<boolean> {
    const defaultOptions: AskOptions = {
      type: askTypes.confirm,
      title: 'Please Confirm',
      okText: 'Ok',
      cancelText: 'Cancel',
    };

    const options: Partial<AskOptions> = {};

    if (typeof config === 'string') {
      options.text = config;
    } else {
      Object.assign(options, config);
    }

    const dialogRef = this.dialog.open(PromptDialogComponent, {
      data: Object.assign(defaultOptions, options),
    });

    return dialogRef.afterClosed();
  }

  ask<T = any>(config: string | AskOptions): Observable<T> {
    const defaultOptions: AskOptions = {
      type: askTypes.askForText,
      title: 'Enter your response',
      okText: 'Save',
      cancelText: 'Cancel',
      textInputType: 'text',
    };

    const options: Partial<AskOptions> = {};

    if (typeof config === 'string') {
      options.text = config;
    } else {
      Object.assign(options, config);
    }

    return this.dialog
      .open(PromptDialogComponent, {
        data: Object.assign(defaultOptions, options),
      })
      .afterClosed();
  }

  /****************************
   * SELECT OPTIONS *
   ****************************/
  select<T = any>(
    config: string | AskOptions,
    selections?: AskOptions['options'],
    format?: string
  ): Observable<AskOptionOption<T>> {
    const defaultOptions: AskOptions<T> = {
      type: askTypes.askForSelection,
      title: 'Choose from the following',
      okText: 'Save',
      cancelText: 'Cancel',
    };

    const options: Partial<AskOptions> = {};

    if (typeof config === 'string') {
      options.text = config;
      options.options = selections;
      options.format = format;
    } else {
      Object.assign(options, config);
    }

    return this.dialog
      .open(PromptDialogComponent, {
        data: Object.assign(defaultOptions, options),
      })
      .afterClosed();
  }
}

@Component({
  selector: 'app-ask-dialog',
  template: `
    <h1 mat-dialog-title *ngIf="options.title">{{ options.title }}</h1>
    <mat-dialog-content>
      <form>
        <div *ngIf="options.html" [innerHtml]="options.html"></div>
        <p *ngIf="options.text">{{ options.text }}</p>
        <pre *ngIf="options.preText">{{ options.preText }}</pre>
        <mat-form-field
          *ngIf="isAskForText && options.textInputType === 'text'"
          style="width: 100%"
        >
          <input
            autocomplete="off"
            matInput
            type="text"
            [value]="initTextValue"
            required
            #textInput
            (keyup)="textValue = textInput.value"
            (change)="textValue = textInput.value"
            (keyUp.enter)="continue()"
          />
        </mat-form-field>
        <mat-form-field
          *ngIf="isAskForText && options.textInputType === 'textarea'"
        >
          <textarea
            matInput
            style="width: 100%; height: 100px"
            type="text"
            [value]="initTextValue"
            #textInput
            (change)="textValue = textInput.value"
          ></textarea>
        </mat-form-field>
        <mat-form-field
          *ngIf="isAskForText && options.textInputType === 'money'"
        >
          <input
            autocomplete="off"
            matInput
            type="number"
            [value]="initTextValue"
            required
            #textInput
            (keyup)="textValue = textInput.value"
            (change)="textValue = textInput.value"
            (keyUp.enter)="continue()"
          />
          <mat-hint>{{ textInput.value | currency: '$' }}</mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="isAskForSelection">
          <mat-select
            #selectInput
            (selectionChange)="selectValue = selectInput.value"
          >
            <mat-option [value]="option" *ngFor="let option of options.options">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div style="flex: 1"></div>
      <button
        *ngIf="options.cancelText"
        type="button"
        mat-button
        mat-dialog-close
      >
        {{ options.cancelText }}
      </button>
      <button
        (click)="continue()"
        mat-raised-button
        [disabled]="!isValid"
        [color]="options.okColor || 'primary'"
      >
        {{ options.okText }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      mat-form-field {
        width: 100%;
      }
      pre {
        border: 1px dotted lightgray;
        padding: 5px;
        border-radius: 5px;
      }
    `,
  ],
})
export class PromptDialogComponent {
  textValue: string = '';
  confirmValue: boolean = false;
  selectValue: any;

  constructor(
    public dialogRef: MatDialogRef<PromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public options: AskOptions
  ) {}

  get initTextValue(): string | number | null {
    return this.options.promptValue || null;
  }

  get isConfirm(): boolean {
    return this.options.type === askTypes.confirm;
  }

  get isAskForText(): boolean {
    return this.options.type === askTypes.askForText;
  }

  get isAskForSelection(): boolean {
    return this.options.type === askTypes.askForSelection;
  }

  get askValue(): any {
    if (this.isConfirm) {
      return this.confirmValue;
    } else if (this.isAskForText) {
      return this.textValue;
    } else if (this.isAskForSelection) {
      return this.selectValue;
    }
  }

  get isValid() {
    if (this.isAskForText) {
      return !!this.textValue;
    }
    return true;
  }

  continue(): void {
    this.confirmValue = true;
    this.closeWithValue();
  }

  closeWithValue(): void {
    this.dialogRef.close(this.askValue);
  }
}
