import { Pipe, PipeTransform } from '@angular/core';

import {
  ClientInsuranceProviderUxService,
  ClientUxService,
  InsuranceProviderUxService,
  UserUxService,
} from 'src/app/services';

@Pipe({
  name: 'client',
})
export class ClientPipe implements PipeTransform {
  constructor(private service: ClientUxService) {}

  transform(value: string): string | undefined {
    return this.service.getEntity(value)?.displayName;
  }
}

@Pipe({
  name: 'insuranceProvider',
})
export class InsuranceProviderPipe implements PipeTransform {
  constructor(private service: InsuranceProviderUxService) {}

  transform(value: string): string | undefined {
    return this.service.getEntity(value)?.name;
  }
}

@Pipe({
  name: 'user',
})
export class UserPipe implements PipeTransform {
  constructor(private service: UserUxService) {}

  transform(value: string): string | undefined {
    return this.service.getEntity(value)?.displayName;
  }
}

@Pipe({
  name: 'clientInsuranceProvider',
})
export class ClientInsuranceProviderPipe implements PipeTransform {
  constructor(private service: ClientInsuranceProviderUxService) {}

  transform(value: string): string | undefined {
    return this.service.getEntity(value)?.name;
  }
}
