import { Injectable } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';

import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { RouterQuery } from '@datorama/akita-ng-router-store';
import { FormlyFieldConfig } from '@ngx-formly/core';

import {
  DialogFormComponent,
  DialogFormOptions,
} from '../formly/form-dialog.component';
import { formlyInput } from '../formly/formly-helpers';
import { Note, NotesQuery, NotesService } from '../store';
import { PromptService } from './';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class NoteUxService {
  constructor(
    private service: NotesService,
    private query: NotesQuery,
    private dialog: MatDialog,
    private prompt: PromptService,
    private toast: ToastService,
    private routerQuery: RouterQuery
  ) {}

  sync() {
    return this.service.syncCollection({ reset: true });
  }

  getRecord(id: string) {
    return this.query.getEntity(id);
  }

  getAll() {
    return this.query.getAll();
  }

  selectEntity(id: string) {
    return this.query.selectEntity(id);
  }

  selectAll() {
    return this.query.selectAll();
  }

  selectAllForActiveClient() {
    const clientId = this.routerQuery.getParams().clientId;
    return this.service.valueChanges([
      where('clientId', '==', clientId),
      orderBy('createdAt', 'asc'),
    ]);
  }

  getEditFields(
    entry: Partial<Note> = {},
    keys: string[] = []
  ): FormlyFieldConfig[] {
    return ([formlyInput({ key: 'note' })] as FormlyFieldConfig[]).filter(
      (thing) =>
        keys && keys.length ? keys.includes(thing.key as string) : true
    );
  }

  edit(note: Note = {}): Observable<string | null> {
    const options: DialogFormOptions = {
      fields: this.getEditFields(note),
      model: note,
      title: note.id ? 'Edit Note' : 'Add Note',
      remove: note.id ? () => this.remove(note) : undefined,
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    return dialog.afterClosed().pipe(
      switchMap((val) => {
        if (!val) {
          this.toast.open(`${note.id ? 'Edit note' : 'Add note'} cancelled`);
          return of(null);
        }
        return from(this.saveRecord(val));
      })
    );
  }

  async saveRecord(model: Note): Promise<string> {
    if (model.id) {
      return this.service.update(model).then(() => {
        this.toast.success(
          `"${model.note!.substring(0, 15)}${
            model.note!.length > 15 ? '...' : ''
          }" Updated`
        );
        return model.id as string;
      });
    }
    return this.service.add(model).then((val) => {
      this.toast.success(
        `"${model.note!.substring(0, 15)}${
          model.note!.length > 15 ? '...' : ''
        }" Added`
      );
      return val;
    });
  }

  remove(model: Note) {
    this.prompt
      .confirm(
        `Delete note: "${model.note!.substring(0, 15)}${
          model.note!.length > 15 ? '...' : ''
        }"`
      )
      .subscribe((val) => {
        if (!val) {
          return;
        }
        this.dialog.closeAll();
        this.service
          .remove(model.id as string)
          .then(() =>
            this.toast.success(
              `"${model.note!.substring(0, 15)}${
                model.note!.length > 15 ? '...' : ''
              }" removed`
            )
          );
      });
  }
}
