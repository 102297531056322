import { Component, OnInit } from '@angular/core';
import {
  Auth,
  authState,
  GoogleAuthProvider,
  OAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
} from '@angular/fire/auth';
import { traceUntilFirst } from '@angular/fire/performance';
import { Router } from '@angular/router';

import { map } from 'rxjs/operators';
import { PromptService } from 'src/app/services';
import { FunctionService } from 'src/app/services/function.service';
import { addToDate } from 'src/app/util/date-util';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  submitting = false;
  showEmailLinkForm = false;
  emailLinkRequested: string = null;

  constructor(
    private router: Router,
    public auth: Auth,
    private fnc: FunctionService,
    private prompt: PromptService
  ) {}

  ngOnInit(): void {
    if (this.auth) {
      authState(this.auth)
        .pipe(
          traceUntilFirst('auth'),
          map(u => !!u),
          untilDestroyed(this)
        )
        .subscribe(isLoggedIn => {
          console.log('Is Logged in: ', isLoggedIn);
          if (isLoggedIn) {
            this.submitting = true;

            this.auth.currentUser.getIdTokenResult().then(val => {
              const email =
                this.auth.currentUser.email ||
                this.auth.currentUser.providerData[0].email;
              if (val.claims.customEmail && val.claims.customEmail === email) {
                this.next();
              }
              this.assignUserCustomClaims();
            });
          } else {
            // this.confirmLink();
          }
        });
    }
  }

  assignUserCustomClaims() {
    this.fnc.assignUserCustomClaims().subscribe(fncResult => {
      console.log(this.auth.currentUser);
      this.auth.currentUser.getIdToken(true).then(() => {
        this.next();
      });
    });
  }

  next() {
    this.router.navigate(['/app']);
  }

  async logout() {
    await this.auth.signOut().then(() => this.doneSubmitting());
  }

  isSubmitting() {
    this.submitting = true;
  }

  doneSubmitting() {
    this.submitting = false;
  }

  isEmailValid(emailAddress: string) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress);
  }

  loginWithGoogle() {
    if (this.submitting) {
      return;
    }
    this.isSubmitting();

    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    provider.addScope('email');
    provider.addScope('profile');
    signInWithPopup(this.auth, provider)
      .then(result => {
        console.log('Success');
      })
      .catch(result => this.handlePopupError(result));
  }

  loginWithMicrosoft() {
    if (this.submitting) {
      return;
    }
    this.isSubmitting();

    const provider = new OAuthProvider('microsoft.com');
    provider.addScope('email');
    provider.addScope('OpenID');
    provider.setCustomParameters({
      prompt: 'select_account',
    });

    signInWithPopup(this.auth, provider)
      .then(result => {
        console.log('Success');
      })
      .catch(result => this.handlePopupError(result));
  }

  handlePopupError(result: any) {
    alert('There was an issue logging you in with the provider selected');
    console.log('Error with popup provider');
    console.log(result);
    this.logout();
    window.location.reload();
  }

  loginWithMagicLink(emailAddress: string) {
    if (this.submitting) {
      return;
    }
    this.isSubmitting();
    const email = emailAddress.trim();
    const url = window.location.origin + '/';
    sendSignInLinkToEmail(this.auth, email, {
      url,
      handleCodeInApp: true,
    })
      .then(result => {
        this.setCookie(email);
        console.log('Success');
        this.emailLinkRequested = email;
        this.submitting = false;
      })
      .catch(result => this.handleMagicLinkError(result));
  }

  handleMagicLinkError(result: { code: string; message: string }) {
    alert('There was an issue logging you in with the email link');
    this.submitting = false;
    console.log('Error with email link');
    console.log(result);
    this.logout();
    window.location.reload();
  }

  closeWindow() {
    window.close();
  }

  setCookie(emailForSignIn: string) {
    const futureDate = addToDate({
      minutes: 15,
    }).toHTTP();

    document.cookie = `efsi=${btoa(emailForSignIn)}; expires=${futureDate};`;
  }

  help() {
    this.prompt.alert({
      title: 'Contact Us!',
      html: `
        <p>
          Please contact us at 
          <a href="mailto:support@casenotesonline.com">support@casenotesonline.com</a>
          with any issues you have.
        </p>
      `,
    });
  }
}
