import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
  from,
  Observable,
  of,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FormlyFieldConfig } from '@ngx-formly/core';

import {
  DialogFormComponent,
  DialogFormOptions,
} from '../formly/form-dialog.component';
import {
  convertListToOptions,
  convertModelsToOptions,
  formlyInput,
} from '../formly/formly-helpers';
import {
  Location,
  LocationQuery,
  LocationService,
} from '../store';
import { PromptService } from './';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class LocationUxService {
  constructor(
    private service: LocationService,
    private query: LocationQuery,
    private dialog: MatDialog,
    private prompt: PromptService,
    private toast: ToastService
  ) {}

  sync() {
    return this.service.syncCollection({ reset: true });
  }

  getRecord(id: string) {
    return this.query.getEntity(id);
  }

  getAll() {
    return this.query.getAll();
  }

  selectEntity(id: string) {
    return this.query.selectEntity(id);
  }

  selectAll() {
    return this.query.selectAll();
  }

  getListOptions() {
    return convertListToOptions(this.getAll().map((v) => v.name as string));
  }

  getModelOptions() {
    return convertModelsToOptions(this.getAll(), 'name');
  }

  getEditFields(
    entry: Partial<Location> = {},
    keys: string[] = []
  ): FormlyFieldConfig[] {
    return ([formlyInput({ key: 'name' })] as FormlyFieldConfig[]).filter(
      (thing) =>
        keys && keys.length ? keys.includes(thing.key as string) : true
    );
  }

  edit(location: Location = {}): Observable<string | null> {
    const options: DialogFormOptions = {
      fields: this.getEditFields(location),
      model: location,
      title: location.id ? 'Edit Location' : 'Add Location',
      remove: location.id ? () => this.remove(location) : undefined,
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    return dialog.afterClosed().pipe(
      switchMap((val) => {
        if (!val) {
          this.toast.open(
            `${location.id ? 'Edit location' : 'Add location'} cancelled`
          );
          return of(null);
        }
        return from(this.saveRecord(val));
      })
    );
  }

  async saveRecord(model: Location): Promise<string> {
    if (model.id) {
      return this.service.update(model).then(() => {
        this.toast.open(`"${model.name}" Updated`);
        return model.id as string;
      });
    }
    return this.service.add(model).then((val) => {
      this.toast.open(`"${model.name}" Added`);
      return val;
    });
  }

  remove(model: Location) {
    this.prompt.confirm(`Delete location: "${model.name}"`).subscribe((val) => {
      if (!val) {
        return;
      }
      this.dialog.closeAll();
      this.service
        .remove(model.id as string)
        .then(() => this.toast.open(`"${model.name}" removed`));
    });
  }
}
