import { Timestamp } from '@angular/fire/firestore';

import { round } from 'lodash';

export interface InsuranceProvider {
  id?: string;
  name?: string;
  minutesToUnits?: number;
  alwaysRoundUp?: boolean;
  rate?: number;
  address?: string; // DO NOT IMPORT
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
  website?: string;
  notes?: string;
  companyId?: string;
  updatedById?: string;
  updatedAt?: Timestamp;
  billServiceBy?: string;
  isActive?: boolean;
}

export function createInsuranceProvider(params: Partial<InsuranceProvider>) {
  return {} as InsuranceProvider;
}

export function calcUnits(provider: InsuranceProvider, minutes: number) {
  if (!provider.minutesToUnits) return 0;
  if (!minutes) return 0;
  const val = minutes / (provider.minutesToUnits || 0);
  if (provider.alwaysRoundUp) {
    return Math.ceil(val);
  }
  return round(val);
}
