import { Component, OnInit } from '@angular/core';
import { limit, where } from '@angular/fire/firestore';

import { orderBy } from 'firebase/firestore';

import { FunctionService } from '../services/function.service';
import {
  BillingService,
  ClientInsuranceProviderQuery,
  ClientInsuranceProviderService,
  ClientQuery,
  ClientService,
  ServiceLogService,
} from '../store';

@Component({
  selector: 'app-foobar',
  template: `
    <hr />
    <button (click)="otherStuff()">Other Stuff</button>
  `,
  styles: [],
})
export class FoobarComponent implements OnInit {
  constructor(
    private clientService: ClientService,
    private clientQuery: ClientQuery,
    private serviceLogService: ServiceLogService,
    private clientIPS: ClientInsuranceProviderService,
    private clientIPQ: ClientInsuranceProviderQuery,
    private fnc: FunctionService,
    private billingsService: BillingService
  ) {}

  ngOnInit(): void {}
  updateClientProviderId() {
    // key by clientInsuranceProviderId by legacyId
    /**
     * loop through clients, select clientInsuranceProvider
     * by client.defaultClientInsuranceProviderId
     *
     * update client set defaultClientInsuranceProviderId = clientInsuranceProvider.id
     */
  }

  async doStuff() {
    console.log('started');

    const clients = this.clientQuery.getAll({
      filterBy: m => !!m.deletedAt,
    });

    for (let client of clients) {
      const logs = await this.serviceLogService.getValue([
        where('stats.${year}.${month}', '==', client.id),
        limit(1),
      ]);
      if (logs.length === 0) {
        console.log('Deleting', client.displayName, logs.length);
        await this.clientService.remove(client.id);
      } else {
        console.log('NOT Deleting', client.displayName, logs.length);
      }
    }

    // console.log(clients);
    console.log('done');
  }

  async otherStuff() {
    console.log('started');
    const billings = await this.billingsService.getValue([
      orderBy('updatedAtDate', 'desc'),
      limit(100),
    ]);
    for (let billing of billings) {
      const client = await this.clientService.getValue(billing.clientId);
      if (!client) {
        console.log(billing);
      }
    }
    console.log('done');
  }
}

// clientStats

/**
 * clientId+YYYYMM = {
 *  clientId
 *  year
 *  month
 *  minutes
 *  billedUnites
 * }
 */
