<app-title-bar header="Unprocessed Billing">
  <button mat-button (click)="print()">
    <mat-icon>print</mat-icon>
    <span>Print</span>
  </button>
  <button mat-button (click)="download()">
    <mat-icon>download</mat-icon>
    <span>Download</span>
  </button>
</app-title-bar>
<mat-card class="page-container search-filters">
  <app-form
    #formEl
    [fields]="filterFields"
    [model]="filterModel"
    [form]="filterForm"
  >
  </app-form>
</mat-card>
<table
  id="unprocessed-billing-table"
  class="table-alpha"
  mat-table
  #table
  [dataSource]="items.results"
  matSort
  (matSortChange)="sortChange($event)"
  [matSortActive]="filterModel.sortBy"
  [matSortDirection]="filterModel.sortByOrder"
  matSortDisableClear
  *ngIf="items.length"
>
  <ng-container matColumnDef="clientInsuranceProvider">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Provider</th>
    <td mat-cell *matCellDef="let row">{{ row.clientInsuranceProvider }}</td>
  </ng-container>

  <ng-container matColumnDef="client">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
    <td mat-cell *matCellDef="let row">{{ row.client }}</td>
  </ng-container>

  <ng-container matColumnDef="minutes">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="right">Minutes</th>
    <td mat-cell *matCellDef="let row" class="right">{{ row.minutes | number:'0.0-0'}}</td>
  </ng-container>

  <ng-container matColumnDef="units">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="right">Units <app-unit-info></app-unit-info></th>
    <td mat-cell *matCellDef="let row" class="right">{{ row.units | number:'0.0-0'}}</td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row">
      <button mat-button>View / Bill</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr
    mat-row
    [routerLink]="[
      'create',
      { clientId: row.clientId, clientIpId: row.clientInsuranceProviderId }
    ]"
    class="pointer"
    *matRowDef="let row; columns: columns"
  ></tr>
</table>
<app-no-results *ngIf="!items.results.length"></app-no-results>
<mat-paginator
  class="page-container"
  [length]="items.length"
  [pageSize]="filterModel.pageSize"
  [pageIndex]="filterModel.page"
  (page)="pageChange($event)"
  [pageSizeOptions]="[25, 50, 100, 500]"
>
</mat-paginator>
