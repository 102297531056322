import { Injectable } from '@angular/core';

import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { Category } from './category.model';

export interface CategoryState
  extends EntityState<Category, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'category', resettable: true })
export class CategoryStore extends EntityStore<CategoryState> {
  constructor() {
    super();
  }
}
