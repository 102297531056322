import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DateTime } from 'luxon';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { getDateTime } from 'src/app/util/date-util';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { billingOptions, booleanSelect, SelectOptions } from '../../formly';
import {
  AuthService,
  ClientUxService,
  LocationUxService,
  PromptService,
  UserUxService,
} from '../../services';
import {
  dateTypeOptions,
  DateTypes,
  UxQuery,
  UxState,
  UxStore,
} from '../../store';
import { rxConvertModelsToOptions } from '../../util/custom-rx-pipes';

@UntilDestroy()
@Component({
  selector: 'app-report-filters',
  template: `
    <app-form
      class="page-container"
      #formEl
      [fields]="filterFields"
      [model]="filterModel"
      [form]="filterForm"
    >
    </app-form>
    <ng-template #submitButton>
      <div class="button">
        <button
          [disabled]="isDisabled"
          (click)="runReport()"
          mat-raised-button
          color="primary"
        >
          Run Report
        </button>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .button {
        height: 55px;
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class ReportFiltersComponent implements OnInit {
  @Input() type: 'payroll' | 'unit' | 'service';

  get isPayroll() {
    return this.type === 'payroll';
  }

  get isUnit() {
    return this.type === 'unit';
  }

  filterModel: UxState['reports'];
  filterForm: UntypedFormGroup = new UntypedFormGroup({});

  filterFields: FormlyFieldConfig[] = [];

  get isDisabled() {
    return this.filterForm.pristine || this.filterForm.invalid;
  }

  isAdmin = false;

  @ViewChild('submitButton')
  submitButton: TemplateRef<any>;

  constructor(
    private uxStore: UxStore,
    private uxQuery: UxQuery,
    private userUx: UserUxService,
    private clientUxService: ClientUxService,
    private cd: ChangeDetectorRef,
    private prompt: PromptService,
    private locationUx: LocationUxService,
    private auth: AuthService
  ) {}

  runReport() {
    const filters: UxState['reports'] = this.filterForm.value;

    if (!this.isAdmin) {
      filters.userId = this.auth.currentUserId;
    }

    const months = getDateTime(filters.dateEnd).diff(
      getDateTime(filters.dateStart),
      'month'
    ).months;

    if (months > 12) {
      this.prompt.alert('Dates can not be more than a year apart.');
      return;
    }
    this.uxStore.updateReport({
      ...filters,
      ...this.getDatesForType(filters.dateType),
      page: 0,
    });
    this.filterForm.markAsPristine();
  }

  ngOnInit(): void {
    this.filterModel = { ...this.uxQuery.getValue().reports };

    this.isAdmin = this.auth.isProjectAdmin;

    this.filterForm.valueChanges
      .pipe(pluck('dateType'), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((type: DateTypes) => {
        this.filterModel = {
          ...this.filterModel,
          ...this.getDatesForType(type),
        };
      });
  }

  getDatesForType(type: DateTypes): { dateStart: Date; dateEnd: Date } | void {
    if (!type || type === 'custom') {
      return;
    }

    let start: DateTime, end: DateTime;
    if (type === 'thisMonth') {
      start = getDateTime().startOf('month');
      end = getDateTime().endOf('month');
    } else if (type === 'lastMonth') {
      start = getDateTime().plus({ month: -1 }).startOf('month');
      end = getDateTime().plus({ month: -1 }).endOf('month');
    } else if (type === 'thisYear') {
      start = getDateTime().startOf('year');
      end = getDateTime().endOf('year');
    } else if (type === 'lastYear') {
      start = getDateTime().plus({ year: -1 }).startOf('year');
      end = getDateTime().plus({ year: -1 }).endOf('year');
    }

    return {
      dateStart: start.toJSDate(),
      dateEnd: end.toJSDate(),
    };
  }

  ngAfterViewInit(): void {
    let keys: Array<keyof UxState['reports']>;

    if (this.isPayroll) {
      keys = [
        'dateType',
        'dateStart',
        'dateEnd',
        'dateFilter',
        'billingType',
        'showDetail',
        null,
      ];
    } else if (this.isUnit) {
      keys = [
        'dateType',
        'dateStart',
        'dateEnd',
        'dateFilter',
        'billingType',
        'locations',
        'clientId',
        'groupBy',
        null,
      ];
    } else {
      keys = [
        'dateType',
        'dateStart',
        'dateEnd',
        'dateFilter',
        'billingType',
        'locations',
        'clientId',
        null,
      ];
    }

    if (this.isAdmin) {
      keys.push('userId');
    }

    this.filterFields = [
      {
        fieldGroupClassName: 'form-grid',
        fieldGroup: [
          {
            type: 'select',
            key: 'dateType',
            templateOptions: {
              options: dateTypeOptions,
              required: true,
              label: 'Date',
            },
          },
          {
            type: 'datepicker',
            key: 'dateStart',
            hideExpression: (m: UxState['reports']) => m.dateType !== 'custom',
            templateOptions: {
              required: true,
              label: 'Start Date',
            },
          },
          {
            type: 'datepicker',
            key: 'dateEnd',
            hideExpression: (m: UxState['reports']) => m.dateType !== 'custom',
            templateOptions: {
              required: true,
              label: 'End Date',
            },
          },
          {
            type: 'select',
            key: 'dateFilter',
            templateOptions: {
              label: 'Date filter',
              required: true,
              options: <SelectOptions>[
                {
                  value: null,
                  label: '',
                },
                {
                  value: 'dateString',
                  label: 'Service Date',
                },
                {
                  value: 'billedAt',
                  label: 'Billing Date',
                },
              ],
            },
          },
          {
            type: 'select',
            key: 'billingType',
            templateOptions: {
              label: 'Billing type',
              multiple: true,
              options: billingOptions({ null: true }),
            },
          },
          this.userUx.userSelectInput(false, false, true, true),
          this.clientUxService.clientSelectInput({ required: false }),
          {
            type: 'select',
            key: 'locations',
            templateOptions: {
              label: 'Location',
              multiple: true,
              options: this.locationUx
                .selectAll()
                .pipe(rxConvertModelsToOptions('name', 'name')),
            },
          },
          {
            type: 'select',
            key: 'groupBy',
            templateOptions: {
              label: 'Group By',
              required: true,
              options: <SelectOptions>[
                {
                  value: 'clientId',
                  label: 'Client',
                },
                {
                  value: 'insuranceProviderId',
                  label: 'Insurance Provider',
                },
              ],
            },
          },
          {
            type: 'select',
            key: 'showDetail',
            templateOptions: {
              label: 'Show Detail',
              options: booleanSelect,
            },
          },
          {
            type: 'template-ref',
            key: null,
            templateOptions: {
              template: this.submitButton,
            },
          },
        ].filter(i => keys.includes(i.key as any)),
      },
    ];
    if (this.filterForm.valid) {
      this.filterForm.markAsDirty();
    }
    this.cd.detectChanges();
  }

  clearFilters() {
    this.uxStore.resetValues('reports');
  }
}
