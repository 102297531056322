import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';

import { DateTime, DurationLike } from 'luxon';

@Pipe({
  name: 'timestampDate',
})
export class timestampDatePipe implements PipeTransform {
  transform(
    value: number,
    formatAsString: string = 'f',
    timezone?: string
  ): any {
    if (typeof value !== 'number') {
      console.warn('number not passed into zoned date pipe');
    }
    return dateNumberFormat(value * 1000, formatAsString, timezone);
  }
}

@Pipe({
  name: 'myDate',
})
export class myDatePipe implements PipeTransform {
  transform(
    value: string | Date | DateTime | undefined | Timestamp,
    formatAsString: string = 'f',
    timezone?: string
  ): string {
    if (!value) {
      return '';
    }
    return getDateTime(value, timezone).toFormat(formatAsString);
  }
}

@Pipe({
  name: 'yearsAgo',
})
export class yearsAgoPipe implements PipeTransform {
  transform(value: string | Date | DateTime | undefined | Timestamp): number {
    if (!value) {
      return 0;
    }
    return getDateTime().diff(getDateTime(value), 'years').as('years');
  }
}

@Pipe({
  name: 'milTime',
})
export class MilTimePipe implements PipeTransform {
  transform(value: string): string {
    return milTimeNormalize(value);
  }
}

export function milTimeNormalize(value: string): string {
  const hours = +value.split(':')[0];
  const minutes = value.split(':')[1];
  if (hours < 12) {
    return value + ' AM';
  }
  if (hours === 12) {
    return value + ' PM';
  }
  return `${hours - 12}:${minutes} PM`;
}

export function dateNumberFormat(
  value: number,
  formatAsString: string = 'f',
  timezone?: string
): string {
  return DateTime.fromMillis(value, {
    zone: timezone,
  }).toFormat(formatAsString);
}

export function setZone(value: Date, timezone: string) {
  return DateTime.fromJSDate(value, {
    zone: timezone,
  }).toJSDate();
}

export function parseISO(val: string, zone?: string) {
  return DateTime.fromISO(val, {
    zone: zone,
  }).toJSDate();
}

export function formatDate(val: Date | string, formatAsString: string) {
  if (typeof val === 'object') {
    return DateTime.fromJSDate(val).toFormat(formatAsString);
  }
  return DateTime.fromISO(val).toFormat(formatAsString);
}

export function simpleString(thing?: string | Date | DateTime | Timestamp) {
  return getDateTime(thing).toFormat('yyyy-MM-dd');
}

export function diffInDays(dateOne: Date, dateTwo: Date) {
  // var end = DateTime.fromISO('2017-03-13');
  // var start = DateTime.fromISO('2017-02-13');

  // var diffInMonths = end.diff(start, 'months');
  // diffInMonths.toObject(); //=> { months: 1 }

  return DateTime.fromJSDate(dateOne)
    .diff(DateTime.fromJSDate(dateTwo), 'days')
    .as('days');
}

export function dateToStamp(date: Date) {
  return Timestamp.fromDate(date);
}

export function getDateTime(
  thing?: string | Date | DateTime | Timestamp,
  zone?: string
): DateTime {
  if (DateTime.isDateTime(thing)) {
    return thing.setZone(zone);
  }
  let date: DateTime;
  if (thing) {
    if (thing instanceof Date) {
      return DateTime.fromJSDate(thing, { zone });
    } else if (thing instanceof Timestamp) {
      return DateTime.fromJSDate(thing.toDate(), { zone });
    } else {
      return DateTime.fromISO(thing, { zone });
    }
  }
  return DateTime.now().setZone(zone);
}

export function getTimeAddedInSeconds(
  thing: DurationLike,
  startDate?: string | Date | DateTime
) {
  return getDateTime(startDate).plus(thing).toSeconds();
}

export function addToDate(
  thing: DurationLike,
  startDate?: string | Date | DateTime
): DateTime {
  return getDateTime(startDate).plus(thing);
}

export function toJsDate(thing?: string | Date | DateTime) {
  return getDateTime(thing).toJSDate();
}

export function zeroDate(thing?: string | Date | DateTime) {
  return getDateTime(thing).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
}
