import { Injectable } from '@angular/core';

import { CollectionConfig, CollectionService } from 'akita-ng-fire';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { Billing } from './billing.model';
import { BillingState, BillingStore } from './billing.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'billings' })
export class BillingService extends CollectionService<BillingState> {
  constructor(store: BillingStore, private routerQuery: RouterQuery) {
    super(store);
  }
  get path() {
    const companyId = this.routerQuery.getParams()?.companyId;
    return `companies/${companyId}/billings`;
  }

  formatToFirestore(model: Billing) {
    return {
      companyId: this.routerQuery.getParams().companyId,
      ...model,
    };
  }
}
