<app-title-bar header="Billing Detail / Create" [showBack]="true">
</app-title-bar>

<div class="client-name">
  <div class="page-container">
    <h2>
      <span class="pointer" [routerLink]="['../../../', 'clients', client?.id]" matTooltip="Navigate to client details">{{ client?.displayName }}</span></h2>
  </div>
</div>

<mat-card class="page-container" id="billing-service-container" [class.is-collapsed]="!expanded">
  <mat-card-subtitle>
    <div *ngIf="isCreate">Un-billed Service for {{unBilledYear}}</div>
    <div *ngIf="!isCreate">Billed Service</div>
    <div *ngIf="logs">
      Dates selected: {{ selectedMinDate | myDate:'MMMM dd' }} -
      {{ selectedMaxDate | myDate:'MMMM dd' }}
    </div>
  </mat-card-subtitle>
    <app-service-table
      [services]="logs"
      [columns]="serviceColumns"
      [(selectedRows)]="selectedRows"
    ></app-service-table>
    <div class="expand-button-container" matTooltip="Toggle expand to show all service" *ngIf="showExpand" (click)="expanded = !expanded"><button mat-mini-fab role="button"><mat-icon>expand</mat-icon></button></div>
</mat-card>

<div class="page-container">
  <div class="cards flex-rows">
    <mat-card >
      <mat-card-subtitle> Insurance Provider </mat-card-subtitle>
      <div *ngIf="!clientProvider || !insuranceProvider" class="inline-error">
        There is an issue with the insurance provider for the service listed.
      </div>
      <div class="details">
        <div class="description-item" *ngIf="clientProvider?.name !== insuranceProvider?.name">
          <div>Nickname</div>
          <div>{{ clientProvider?.name }}</div>
        </div>
        <div class="description-item">
          <div>Name</div>
          <div>{{ insuranceProvider?.name }}</div>
        </div>
        <div class="description-item">
          <div>Policy</div>
          <div>{{ clientProvider?.accountNumber }}</div>
        </div>
        <div class="description-item">
          <div>GroupId</div>
          <div>{{ clientProvider?.groupId }}</div>
        </div>
        <div class="description-item">
          <div>Holder</div>
          <div>{{ clientProvider?.policyHolder }}</div>
        </div>
        <div class="description-item">
          <div>Company</div>
          <div>{{ clientProvider?.policyHolderCompany }}</div>
        </div>
        <div class="description-item">
          <div>Notes</div>
          <div>{{ clientProvider?.notes }}</div>
        </div>
      </div>
    </mat-card>
    <mat-card >
      <mat-card-subtitle> Totals </mat-card-subtitle>
      <div class="details">
        <div class="description-item">
          <div>Service Count</div>
          <div>
            <span *ngIf="isCreate">{{ preTotalRows }} of {{ logs.length }}</span>
            <span *ngIf="!isCreate">{{ logs.length }}</span>
          </div>
        </div>
        <div class="description-item">
          <div>Bill By</div>
          <div>{{ insuranceProvider?.billServiceBy }}</div>
        </div>
        <div class="description-item">
          <div>Minutes</div>
          <div>
            <span *ngIf="isCreate">{{ preTotalMinutes }}</span>
            <span *ngIf="!isCreate">{{ billing?.totalBillableMinutes }}</span>
          </div>
        </div>
        <div class="description-item">
          <div>Units</div>
          <div>
            <span *ngIf="isCreate">{{ preUnits }}</span>
            <span *ngIf="!isCreate">{{ billing?.totalUnits }}</span>
          </div>
        </div>
        <div class="description-item">
          <div>Bill Amount</div>
          <div>
            <span *ngIf="isCreate">{{ preBillAmount | currency }}</span>
            <span *ngIf="!isCreate">{{ billing?.totalAmount | currency }}</span>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="submit-billing">
      <mat-card-subtitle> Billing </mat-card-subtitle>
      <mat-card-content>
        <div class="description-item">
          <div>Status</div>
          <div>{{ billing?.status }}</div>
        </div>
        <div class="description-item">
          <div>Updated By</div>
          <div>{{ billing?.updatedById | none }}</div>
        </div>
        <div class="description-item">
          <div>Submitted On</div>
          <div>{{ billing?.billedAt | date }}</div>
        </div>
        <div class="description-item">
          <div>Notes</div>
          <div>{{ billing?.note | none }}</div>
        </div>
      </mat-card-content>
      <mat-card-actions *ngIf="isCreate" align="end">
        <button mat-raised-button [disabled]="submitBillingIsDisabled" (click)="editBilling()" color="primary">Submit Billing</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
<div class="page-container" *ngIf="!isCreate">
  <mat-card>
    <mat-card-subtitle> Payments </mat-card-subtitle>
    <app-payment-table (selected)="editPayment($event)" [payments]="billing?.payments ?? []"></app-payment-table>
    <mat-card-actions align="end" *ngIf="isOpen">
      <button mat-button (click)="editPayment()">Add Payment</button>
    </mat-card-actions>
  </mat-card>
</div>


<div class="page-container" *ngIf="!isCreate">
  <mat-card>
    <mat-card-subtitle> Actions </mat-card-subtitle>
    <mat-card-actions class="flex-row">
      <button color="warn" *ngIf="!isCreate" mat-button (click)="resetBilling()">
        Reset Billing
      </button>
      <div class="flex"></div>
      <button mat-raised-button color="primary" *ngIf="isOpen" (click)="markClosed()">
        <span>Mark Closed</span>
        <span> ({{ closedStatus }})</span>
      </button>
      <button mat-raised-button color="primary" *ngIf="!isOpen" (click)="markOpen()">Re-Open Billing</button>
    </mat-card-actions>
  </mat-card>
</div>