<div class="app-shell-container">
  <mat-toolbar color="primary">
    <button mat-icon-button aria-label="Toggle Menu" (click)="sNav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="header-text">
      <span class="hide-sm">Case Notes Online</span>
      <span class="hide-sm">|</span>
      <span>{{ company?.name }}</span>
    </span>
    <span class="flex"></span>
    <span class="display-name">{{ currentUser?.displayName }}</span>
  </mat-toolbar>
  <mat-sidenav-container>
    <mat-sidenav class="side-nav" #sNav mode="side" [opened]="true">
      <mat-nav-list>
        <a
          mat-list-item
          *ngFor="let item of routes"
          [routerLink]="item.route"
          routerLinkActive="active"
        >
          <span>{{ item.label }}</span></a
        >
        <div class="flex"></div>
        <button mat-list-item (click)="help()">
          <mat-icon matListIcon>help_center</mat-icon>
          <span> Help</span>
        </button>
        <button mat-list-item *ngIf="showSwitchCompany" [routerLink]="['/']">
          <mat-icon matListIcon>cloud_sync</mat-icon>
          <span> Switch Company</span>
        </button>
        <a mat-list-item routerLink="/logout">
          <mat-icon matListIcon>logout</mat-icon>
          <span> Logout</span>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
