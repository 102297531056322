import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

import print from 'print-js';
import { billingOptions, monthOptions, yearOptions } from 'src/app/formly';
import {
  BillingUxService,
  ClientUxService,
  InsuranceProviderUxService,
  PromptService,
} from 'src/app/services';
import { UxQuery, UxState, UxStore } from 'src/app/store';
import {
  convertToCSVAndDownload,
  printableCss,
} from 'src/app/util/client-util';
import { PaginatedResults } from 'src/app/util/pageinated-results.interface';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';

@UntilDestroy()
@Component({
  selector: 'app-submitted-billing',
  templateUrl: './submitted-billing.component.html',
  styles: [
    `
      .status-with-icon {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class SubmittedBillingComponent implements OnInit {
  filterForm: UntypedFormGroup = new UntypedFormGroup({});

  filterModel: Partial<UxState['submittedBilling']> = {};

  icons: any = {
    OPEN: 'hourglass_empty',
    PAID: 'paid',
    PARTIAL: 'hourglass_bottom',
    UNPAID: 'money_off',
    'NON-BILLABLE': 'block',
  };

  filterFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'form-grid',
      fieldGroup: [
        {
          key: 'year',
          type: 'select',
          templateOptions: {
            label: 'Year',
            options: yearOptions,
          },
        },
        {
          key: 'month',
          type: 'select',
          templateOptions: {
            label: 'Month',
            options: monthOptions,
          },
        },
        this.providerUx.providerSelectInput(false, false, true),
        {
          key: 'status',
          type: 'select',
          templateOptions: {
            label: 'Status',
            multiple: true,
            options: billingOptions(),
          },
        },
        this.clientUx.clientSelectInput(),
      ],
    },
  ];
  billing: PaginatedResults<{
    id: string;
    status: string;
    provider: string;
    client: string;
    date: string;
    units: number;
    amount: number;
    paid: number;
  }> = {
    length: 0,
    results: [],
    pageIndex: 0,
    pageSize: 0,
  };

  get header() {
    return `Submitted (${this.billing.length})`;
  }

  columns = ['provider', 'client', 'date', 'units', 'status', 'amount', 'paid'];

  constructor(
    private billingUx: BillingUxService,
    private uxQuery: UxQuery,
    private uxStore: UxStore,
    private providerUx: InsuranceProviderUxService,
    private clientUx: ClientUxService,
    private prompt: PromptService
  ) {}

  ngOnInit(): void {
    this.filterModel = { ...this.uxQuery.getValue().submittedBilling };
    this.billingUx
      .selectAllForList()
      .pipe(untilDestroyed(this))
      .subscribe(val => {
        this.billing = val;
      });
    this.filterForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((val: Partial<UxState['submittedBilling']>) => {
        this.uxStore.updateSubmitted(val);
      });
  }

  sortChange(val: Sort) {
    this.uxStore.updateSubmitted({
      sortBy: val.active as any,
      sortByOrder: val.direction as any,
    });
  }

  pageChange(val: PageEvent) {
    this.uxStore.updateSubmitted({
      page: val.pageIndex,
      pageSize: val.pageSize,
    });
    document.getElementsByTagName('app-submitted-billing')[0].scrollIntoView({
      behavior: 'smooth',
    });
  }

  print() {
    this.prompt
      .alert(
        'Note this will only print the current page of results. ' +
          'Note: You cause use the download feature to ' +
          'download all the records into a spreadsheet.'
      )
      .subscribe(() => {
        print({
          header: 'Submitted Billing',
          printable: 'submitted-billing-table',
          type: 'html',
          targetStyles: '*',
          style: printableCss,
        });
      });
  }

  download() {
    this.prompt
      .confirm('This will download the results as a CSV file')
      .subscribe(val => {
        if (!val) {
          return;
        }
        convertToCSVAndDownload(
          this.billing.allResults.map(d => ({
            provider: d.provider,
            client: d.client,
            submittedOn: d.date,
            units: d.units,
            status: d.status,
            amount: d.amount,
            paid: d.paid || 0,
          })),
          'submitted-payments'
        );
      });
  }

  log(stuff: any) {
    console.log(stuff);
  }
}
