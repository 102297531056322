<app-title-bar header="Client List">
  <button mat-button (click)="add()" color="primary">
    <mat-icon>add_box</mat-icon>
    <span>Add Client</span>
  </button>
  <button mat-button (click)="download()">
    <mat-icon>download</mat-icon>
    <span>Download</span>
  </button>
</app-title-bar>
<mat-card class="page-container search-filters">
  <app-form
    #formEl
    [fields]="filterFields"
    [model]="filterModel"
    [form]="filterForm"
  >
  </app-form>
</mat-card>
<table
  class="table-alpha"
  mat-table
  #table
  [dataSource]="data.results"
  matSort
  (matSortChange)="sortChange($event)"
  [matSortActive]="filterModel.sortBy"
  [matSortDirection]="filterModel.sortByOrder"
  matSortDisableClear
>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row">{{ row.displayName }}</td>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>First name</th>
    <td mat-cell *matCellDef="let row">{{ row.firstName }}</td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last name</th>
    <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
  </ng-container>

  <ng-container matColumnDef="minutesMonth">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Minutes ({{ currentMonth }})
    </th>
    <td mat-cell *matCellDef="let row" class="center">
      {{ row.currentStats.month.minutes | number: '0.0-0' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="minutesYear">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Minutes ({{ currentYear }})
    </th>
    <td mat-cell *matCellDef="let row" class="center">
      {{ row.currentStats.year.minutes | number: '0.0-0' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="unitsMonth">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Units ({{ currentMonth }}) <app-unit-info></app-unit-info>
    </th>
    <td mat-cell *matCellDef="let row" class="center">
      {{ row.currentStats.month.units | number: '0.0-0' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="unitsYear">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Units ({{ currentYear }}) <app-unit-info></app-unit-info>
    </th>
    <td mat-cell *matCellDef="let row" class="center">
      {{ row.currentStats.year.units | number: '0.0-0' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row" class="actions">
      <button mat-icon-button [routerLink]="[row.id]">
        <mat-icon>wysiwyg</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr
    mat-row
    class="pointer"
    *matRowDef="let row; columns: columns"
    [routerLink]="[row.id]"
  ></tr>
</table>
<app-no-results *ngIf="!data.results"></app-no-results>
<mat-paginator
  class="page-container"
  [length]="data.length"
  [pageSize]="filterModel.pageSize"
  [pageIndex]="filterModel.page"
  (page)="pageChange($event)"
  [pageSizeOptions]="[25, 50, 100, 500]"
>
</mat-paginator>

<!-- <button (click)="fixClients()">Fix Clients</button> -->
