import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import {
  limit,
  orderBy,
  QueryConstraint,
  Timestamp,
  where,
} from 'firebase/firestore';
import { combineLatest, from, Observable, of } from 'rxjs';
import {
  distinctUntilKeyChanged,
  map,
  startWith,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { FormlyFieldConfig } from '@ngx-formly/core';

import { convertModelsToOptions } from '../formly';
import {
  DialogFormComponent,
  DialogFormOptions,
} from '../formly/form-dialog.component';
import {
  calcUnits,
  CategoryQuery,
  ClientInsuranceProviderQuery,
  ClientInsuranceProviderService,
  ClientQuery,
  InsuranceProviderQuery,
  LocationQuery,
  ServiceLog,
  ServiceLogQuery,
  ServiceLogService,
  TemplateQuery,
  UserQuery,
  UxQuery,
} from '../store';
import { rxConvertModelsToOptions } from '../util/custom-rx-pipes';
import { getDateTime, parseISO, simpleString } from '../util/date-util';
import { PaginatedResults } from '../util/pageinated-results.interface';
import { PromptService, UserUxService } from './';
import { AuthService } from './auth-service.service';
import { ClientUxService } from './client-ux.service';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class ServiceLogUxService {
  constructor(
    private service: ServiceLogService,
    private query: ServiceLogQuery,
    private dialog: MatDialog,
    private prompt: PromptService,
    private toast: ToastService,
    private clientQuery: ClientQuery,
    private clientProviderQuery: ClientInsuranceProviderQuery,
    private auth: AuthService,
    private locationQuery: LocationQuery,
    private categoryQuery: CategoryQuery,
    private userQuery: UserQuery,
    private templateQuery: TemplateQuery,
    private providerQuery: InsuranceProviderQuery,
    private clientUx: ClientUxService,
    private uxQuery: UxQuery,
    private userUx: UserUxService,
    private clientIpService: ClientInsuranceProviderService
  ) {}

  // getEntity(id: string) {
  //   return this.query.getEntity(id);
  // }

  selectEntity(id: string) {
    return this.query.selectEntity(id);
  }

  valueChanges = this.service.valueChanges.bind(this.service);

  selectAll = this.query.selectAll.bind(this.query);

  loading = this.query.selectLoading.bind(this.query);

  getForDates(
    field: 'dateString' | 'billedAt',
    startDate: Date,
    endDate: Date,
    userId?: string,
    clientId?: string
  ) {
    const start = simpleString(startDate);
    const end = simpleString(endDate);
    const q: QueryConstraint[] = [];
    q.push(where(field, '>=', start), where(field, '<=', end));

    if (userId) {
      q.push(where('userId', '==', userId));
    }

    if (clientId) {
      q.push(where('clientId', '==', clientId));
    }
    return this.service.getAll(field, q);
  }

  syncForBilling() {
    return this.uxQuery.selectUnprocessedBillingFilters.pipe(
      distinctUntilKeyChanged('year'),
      switchMap(filters => {
        const q: QueryConstraint[] = [];

        const monthStart = `${filters.year}-00-00`;
        const monthEnd = `${filters.year}-13-32`;

        q.push(
          where('dateString', '>', monthStart),
          where('dateString', '<', monthEnd),
          where('billingId', '==', null)
        );

        return this.service.syncCollection(q, { reset: true, loading: true });
      })
    );
  }

  syncForList() {
    return this.uxQuery.selectServiceFilters.pipe(
      distinctUntilKeyChanged('syncChange'),
      switchMap(filters => {
        const q: QueryConstraint[] = [];

        const month = String(filters.month).padStart(2, '0');
        const monthStart = `${filters.year}-${month}-00`;
        const monthEnd = `${filters.year}-${month}-32`;

        q.push(
          where('dateString', '>', monthStart),
          where('dateString', '<', monthEnd)
        );

        if (this.auth.isProjectAdmin) {
          if (filters.userId) {
            q.push(where('userId', '==', filters.userId));
          }
        } else {
          q.push(where('userId', '==', this.auth.currentUserId));
        }

        q.push(orderBy('dateString', 'desc'));

        return this.service.syncCollection(q, { reset: true, loading: true });
      })
    );
  }

  selectForList(): Observable<PaginatedResults<ServiceLog>> {
    return combineLatest([
      this.uxQuery.selectServiceFilters,
      this.query.selectAll(),
    ]).pipe(
      map(([filters, service]) => {
        let results: ServiceLog[];

        if (['category', 'location'].includes(filters.sortBy)) {
          results = service.sort((a, b) => {
            return a[filters.sortBy as 'category'].localeCompare(
              b[filters.sortBy as 'category']
            );
          });
        } else if (filters.sortBy === 'dateString') {
          results = service.sort((a, b) => {
            return (a.dateString + a.startTimeString).localeCompare(
              b.dateString + b.startTimeString
            );
          });
        } else if (filters.sortBy === 'minutes') {
          results = service.sort((a, b) => {
            return a.minutes > b.minutes ? 1 : a.minutes === b.minutes ? 0 : -1;
          });
        } else if (filters.sortBy === 'billing') {
          results = service.sort((a, b) => {
            return this.providerQuery
              .getEntity(a.insuranceProviderId)
              .name.localeCompare(
                this.providerQuery.getEntity(b.insuranceProviderId).name
              );
          });
        } else if (filters.sortBy === 'client') {
          results = service.sort((a, b) => {
            return this.clientQuery
              .getEntity(a.clientId)
              .sortProp.localeCompare(
                this.clientQuery.getEntity(b.clientId).sortProp
              );
          });
        } else if (filters.sortBy === 'staff') {
          results = service.sort((a, b) => {
            return this.userQuery
              .getEntity(a.userId)
              .sortProp.localeCompare(
                this.userQuery.getEntity(b.userId).sortProp
              );
          });
        } else {
          results = service;
        }

        if (filters.sortByOrder === 'desc') {
          results.reverse();
        }

        return {
          results: results.slice(
            filters.pageSize * filters.page,
            filters.pageSize * filters.page + filters.pageSize
          ),
          allResults: results,
          pageIndex: filters.page,
          length: results.length,
          pageSize: filters.pageSize,
        };
      })
    );
  }

  selectRecentForClient(clientId: string, limitTo: number, year?: string) {
    const q: QueryConstraint[] = [
      where('clientId', '==', clientId),
      limit(limitTo),
      orderBy('dateString', 'desc'),
    ];

    if (!this.auth.isProjectAdmin) {
      q.push(where('userId', '==', this.auth.currentUserId));
    }

    return this.service.valueChanges(q);
  }

  mapLogsForTable(logs: ServiceLog[]) {
    return logs.map(log => {
      return {
        ...log,
      };
    });
  }

  getEditFields(
    entry: Partial<ServiceLog> = {},
    keys: string[] = []
  ): FormlyFieldConfig[] {
    return (
      [
        this.clientUx.clientSelectInput({
          required: true,
          openSelectBox: !entry.clientId,
        }),
        {
          type: 'select',
          key: 'clientInsuranceProviderId',
          templateOptions: {
            required: true,
            label: 'Insurance Provider',
          },
          // defaultValue: this.clientQuery.getEntity(entry.clientId)
          //   ?.defaultClientInsuranceProviderId,
          hooks: {
            onInit: f => {
              f.templateOptions.options = f.form
                .get('clientId')
                .valueChanges.pipe(
                  startWith(entry.clientId),
                  tap(clientId => {
                    if (!f.form.get('clientInsuranceProviderId').value) {
                      f.form
                        .get('clientInsuranceProviderId')
                        .setValue(
                          this.clientQuery.getEntity(clientId)
                            ?.defaultClientInsuranceProviderId
                        );
                    }
                  }),
                  switchMap(v =>
                    this.clientProviderQuery
                      .selectAll({
                        filterBy: m => m.clientId === v,
                      })
                      .pipe(
                        rxConvertModelsToOptions('name', 'id'),
                        takeWhile(v => v.length === 0, true)
                      )
                  )
                );
            },
          },
        },
        {
          type: 'datepicker',
          key: 'dateString',
          defaultValue: getDateTime().toJSDate(),
          templateOptions: {
            label: 'Date',
            required: true,
          },
        },
        {
          fieldGroupClassName: 'flex-row fit-content',
          fieldGroup: [
            {
              key: 'startTimeString',
              type: 'input',
              templateOptions: {
                label: 'Start Time',
                required: true,
                type: 'time',
                change: f =>
                  f.form.get('endTimeString').updateValueAndValidity(),
              },
              defaultValue: getDateTime().toFormat('HH:mm'),
              validators: {
                minTime: {
                  expression: (c: UntypedFormControl, f: FormlyFieldConfig) => {
                    const start = f.form.get('startTimeString').value;
                    const end = f.form.get('endTimeString').value;

                    return end > start;
                  },
                  message: (e: any, f: FormlyFieldConfig) => '',
                },
              },
            },
            {
              key: 'endTimeString',
              type: 'input',
              templateOptions: {
                label: 'End Time',
                required: true,
                type: 'time',
                change: f =>
                  f.form.get('startTimeString').updateValueAndValidity(),
              },
              defaultValue: getDateTime()
                .plus({ minutes: 15 })
                .toFormat('HH:mm'),
              validators: {
                minTime: {
                  expression: (c: UntypedFormControl, f: FormlyFieldConfig) => {
                    const start = f.form.get('startTimeString').value;
                    const end = f.form.get('endTimeString').value;
                    return end > start;
                  },
                  message: (e: any, f: FormlyFieldConfig) => '',
                },
              },
            },
          ],
        },
        {
          template: '',
          expressionProperties: {
            template: (m: ServiceLog) => {
              let msg = '';
              let msgClass: string = 'good';

              if (m.endTimeString < m.startTimeString) {
                msgClass = 'error';
                msg = 'Start time can not be greater than end time';
              } else {
                const bob = getDateTime(m.endTimeString).diff(
                  getDateTime(m.startTimeString),
                  ['minutes', 'hours']
                );
                msg = `Duration: ${bob.toHuman()}`;
                m.minutes = bob.as('minutes');
                // hour / minutes
                if (m.minutes > 12 * 60) {
                  msgClass = 'warning';
                }
              }
              return `<p class="time-description-block ${msgClass}"><span>${msg}</span></p>`;
            },
          },
        },
        this.userUx.userSelectInput(false, true),
        {
          key: 'location',
          type: 'select',
          templateOptions: {
            label: 'Location',
            options: convertModelsToOptions(
              this.locationQuery.getAll(),
              'name',
              'name'
            ),
            required: true,
          },
        },
        {
          key: 'category',
          type: 'select',
          templateOptions: {
            label: 'Category',
            options: convertModelsToOptions(
              this.categoryQuery.getAll(),
              'name',
              'name'
            ),
            required: true,
          },
        },
        {
          key: 'notes',
          type: 'textarea-templates',
          templateOptions: {
            label: 'Notes',
            required: true,
            templates: [],
          },
          expressionProperties: {
            'templateOptions.templates': (log: ServiceLog) => {
              const templates = convertModelsToOptions(
                this.templateQuery.getAll({
                  filterBy: t => t.category === log.category,
                }),
                'name',
                'template'
              );
              return templates?.length
                ? templates
                : [
                    {
                      value: 'none',
                      label: 'No templates',
                    },
                  ];
            },
          },
        },
      ] as FormlyFieldConfig[]
    )
      .filter(thing =>
        keys && keys.length ? keys.includes(thing.key as string) : true
      )
      .filter(thing =>
        thing.key === 'userId' && !this.auth.isProjectAdmin ? false : true
      );
  }

  edit(existing: ServiceLog = {}): Observable<string | null> {
    if (!existing.userId) {
      existing.userId = this.auth.currentUserId;
    }

    const options: DialogFormOptions<ServiceLog> = {
      fields: this.getEditFields(existing),
      model: {
        ...existing,
        ...(existing.dateString
          ? { dateString: parseISO(existing.dateString) as any }
          : {}),
      },
      title: existing.id ? 'Edit Case Note' : 'Add Case Note',
      remove: existing.id ? () => this.remove(existing) : undefined,
      readonly: !!existing.billingId,
      readonlyMessage: 'Service is has been billed and can not be edited.',
      labels: { close: existing.billingId ? 'Close' : 'Cancel' },
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    return dialog.afterClosed().pipe(
      switchMap((val: ServiceLog) => {
        if (!val) {
          if (!existing.billingId) {
            this.toast.open(
              `${existing.id ? 'Edit Case Note' : 'Add Case Note'} cancelled`
            );
          }
          return of(null);
        }

        if (val.billingId) {
          this.prompt.alert('Can not edit a service after it has been billed');
          return of(null);
        }

        const updatedDate = getDateTime(val.dateString);

        const insuranceProviderId = this.clientProviderQuery.getEntity(
          val.clientInsuranceProviderId
        )?.insuranceProviderId;

        const provider = this.providerQuery.getEntity(insuranceProviderId);

        if (!provider) {
          this.prompt.alert(
            'Provider is not setup correctly for this client. Can not save this case note.'
          );
          this.toast.error('Note not saved');
          return of(null);
        }

        const updated: ServiceLog = {
          billingId: null,
          billingStatus: null,
          ...existing,
          ...val,
          dateString: updatedDate.toFormat('yyyy-MM-dd'),
          insuranceProviderId,
          month: Number(updatedDate.toFormat('yyyyMM')),
          week: updatedDate.weekNumber,
          updatedAt: Timestamp.now(),
          updatedById: this.auth.currentUserId!,
          units: calcUnits(provider, val.minutes),
        };

        if (!val.id) {
          const signature = this.userQuery.getEntity(
            updated.userId
          ).serviceSignature;
          updated.notes = updated.notes + '\n\n -' + signature || '';
        }
        return from(this.saveRecord(updated)).pipe(
          tap(() => this.postUpdateTriggers(existing, updated))
        );
      })
    );
  }

  async saveRecord(model: ServiceLog): Promise<string> {
    if (model.id) {
      return this.service.update(model).then(() => {
        this.toast.success(`${this.getDisplayValue(model)} Updated`);
        return model.id as string;
      });
    }
    return this.service.add(model).then(val => {
      this.toast.success(`${this.getDisplayValue(model)} Added`);
      return val as string;
    });
  }

  postUpdateTriggers(pre: ServiceLog, post: ServiceLog) {
    const minutes = post.minutes - (pre.minutes || 0);
    this.updateClientInsuranceProvider(pre.clientInsuranceProviderId, minutes);
  }

  remove(model: ServiceLog) {
    this.prompt
      .confirm(`Delete ${this.getDisplayValue(model)}`)
      .subscribe(val => {
        if (!val) {
          return;
        }
        this.dialog.closeAll();
        this.service.remove(model.id as string).then(() => {
          this.updateClientInsuranceProvider(
            model.clientInsuranceProviderId,
            model.minutes * -1
          );
          this.toast.success(`${this.getDisplayValue(model)} removed`);
        });
      });
  }

  getDisplayValue(model: ServiceLog) {
    const client = this.clientQuery.getEntity(model.clientId!);
    const date: string = getDateTime(model.dateString).toFormat('LLL dd');
    return `Service for ${client?.displayName} on ${date}`;
  }

  async updateClientInsuranceProvider(
    clientInsuranceProviderId: string,
    amount: number
  ) {
    // CURRENTLY NOT DOING THIS RIGHT NOW. WILL RE-INTRODUCE IN THE FUTURE.
    // THIS WAS A WAY TO MARK THIS WITH UNPROCESSED BILLING TO MAKE IT EASIER
    // TO SEE ALL UNPROCESSED BILLING.
    return;
    // await this.clientIpService.update(clientInsuranceProviderId, {
    //   unprocessedMinutes: increment(amount) as any,
    // });
  }
}
