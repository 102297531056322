import { Component, OnInit } from '@angular/core';

import { UserUxService } from 'src/app/services';
import { User } from 'src/app/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-users',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  users: User[] = [];
  columns = ['name', 'email', 'access'];

  constructor(private userUxService: UserUxService) {}

  ngOnInit(): void {
    this.userUxService
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe(val => (this.users = val));
  }

  edit(user?: User) {
    this.userUxService.edit(user).subscribe();
  }

  remove(model: User) {
    this.userUxService.remove(model);
  }
}
