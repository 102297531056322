import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';
import {
  ClientInsuranceProviderUxService,
  ClientUxService,
  ContactUxService,
} from 'src/app/services';
import { FileUxService } from 'src/app/services/file-ux.service';
import { NoteUxService } from 'src/app/services/note-ux.service';
import { ServiceLogUxService } from 'src/app/services/service-log-ux.service';
import { Client, ServiceLog } from 'src/app/store';
import { monthNames } from 'src/app/util/client-util';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss'],
})
export class ClientDetailComponent implements OnInit {
  client: Client | undefined;
  header = '';

  service: Observable<ServiceLog[]>;

  monthNames = monthNames;

  stats: {
    year: string;
    month: number;
    minutes: number;
    units: number;
  }[] = [];

  constructor(
    private clientUx: ClientUxService,
    private contactUx: ContactUxService,
    private cIpUx: ClientInsuranceProviderUxService,
    private noteUX: NoteUxService,
    private router: Router,
    private route: ActivatedRoute,
    private serviceUx: ServiceLogUxService,
    private fileUx: FileUxService
  ) {}

  ngOnInit(): void {
    const clientId = this.route.snapshot.params.clientId;
    this.clientUx
      .selectEntity(clientId)
      .pipe(untilDestroyed(this))
      .subscribe(val => {
        if (val) {
          this.client = val;
          this.header = val!.displayName!;
          if (val.stats) {
            Object.keys(val.stats).forEach(year => {
              Object.keys(val.stats[year]).forEach(month => {
                this.stats.push({
                  year,
                  month: Number(month),
                  ...val.stats[year][month],
                });
              });
            });
            this.stats
              .sort((a, b) => a.month - b.month)
              .sort((a, b) => a.year.localeCompare(b.year))
              .reverse();
          }
        }
      });
    this.service = this.serviceUx.selectRecentForClient(clientId, 25);
  }

  edit() {
    this.clientUx.edit(this.client).subscribe();
  }

  removePhoto() {
    this.clientUx.removePhoto(this.client.id);
  }

  addNewPhoto() {
    this.clientUx.pickNewPhoto(this.client.id);
  }

  addContact() {
    this.contactUx.edit().subscribe();
  }

  addInsuranceProvider() {
    this.cIpUx
      .edit({
        clientId: this.client.id,
      })
      .subscribe();
  }

  addFile() {
    this.fileUx.uploadFiles(this.client.id);
  }

  addService() {
    this.serviceUx.edit({ clientId: this.client!.id }).subscribe();
  }

  addNote() {
    this.noteUX.edit().subscribe();
  }

  remove() {
    this.clientUx.remove(this.client as Client).subscribe(val => {
      if (val) {
        this.router.navigate(['../'], { relativeTo: this.route });
      }
    });
  }
}
