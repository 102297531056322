import { Injectable } from '@angular/core';

import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import {
  limit,
  orderBy,
  QueryConstraint,
  startAfter,
} from 'firebase/firestore';
import { toJsDate } from 'src/app/util/date-util';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { Client } from './client.model';
import { ClientState, ClientStore } from './client.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'clients' })
export class ClientService extends CollectionService<ClientState> {
  cYear = toJsDate().getFullYear();
  cMonth = String(toJsDate().getMonth() + 1).padStart(2, '0');

  constructor(store: ClientStore, private routerQuery: RouterQuery) {
    super(store);
  }

  get path() {
    const companyId = this.routerQuery.getParams()?.companyId;
    return `companies/${companyId}/clients`;
  }

  formatFromFirestore(entity: Client): Client {
    const currentStats = {
      month: {
        minutes: 0,
        units: 0,
      },
      year: {
        minutes: 0,
        units: 0,
      },
    };

    if (entity.stats && entity.stats[this.cYear]) {
      currentStats.year = Object.values(entity.stats[this.cYear]).reduce(
        (a, c) => {
          a.minutes += c.minutes || 0;
          a.units += c.units || 0;
          return a;
        },
        { minutes: 0, units: 0 }
      );

      if (entity.stats[this.cYear][this.cMonth]) {
        currentStats.month = entity.stats[this.cYear][this.cMonth];
      }
    }

    return {
      ...entity,
      displayName:
        `${entity.lastName}, ${entity.firstName}`.toLocaleUpperCase(),
      sortProp: (
        (entity.lastName || '') + (entity.firstName || '')
      ).toLocaleLowerCase(),
      currentStats,
    };
  }

  /**
   * Current not needed as all clients are loaded into client
   * @returns
   */
  async fetchAll(): Promise<Client[]> {
    const orderByField = 'id';
    const records: Client[] = [];
    const qLimit = 500;
    const query: QueryConstraint[] = [orderBy(orderByField), limit(qLimit)];
    let lastId: any = null;
    let hasNext = true;
    while (hasNext) {
      let q: QueryConstraint[];
      if (lastId) {
        q = [...query, startAfter(lastId)];
      } else {
        q = [...query];
      }
      const results = await this.getValue(q);
      records.push(...results);
      lastId = results[results.length - 1][orderByField];
      if (results.length < qLimit) {
        hasNext = false;
      }
    }
    return records;
  }

  formatToFirestore(model: Client) {
    const companyId = this.routerQuery.getParams()?.companyId;

    const updated = {
      ...model,
      companyId,
    };
    delete updated.currentStats;

    return updated;
  }
}
