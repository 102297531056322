import { Injectable } from '@angular/core';

import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { InsuranceProvider } from './insurance-provider.model';

export interface InsuranceProviderState extends EntityState<InsuranceProvider, string>, ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'insuranceProvider', resettable: true })
export class InsuranceProviderStore extends EntityStore<InsuranceProviderState> {
  constructor() {
    super();
  }
}
