import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { TrackJS } from 'trackjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import versionInfo from './version.json';

if (location.host.indexOf('localhost') !== 0) {
  TrackJS.install({
    token: '59320ea2db8a441caef7f122a3fc9bd6',
    application: 'casenotes',
  });
}

if (environment.production) {
  enableProdMode();
}

console.log('[Version: LOCAL]: ', versionInfo.date);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any) => console.error(err));
