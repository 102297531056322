import { Injectable } from '@angular/core';

import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { Location } from './location.model';

export interface LocationState
  extends EntityState<Location, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'location', resettable: true })
export class LocationStore extends EntityStore<LocationState> {
  constructor() {
    super();
  }
}
