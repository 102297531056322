import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { where } from 'firebase/firestore';
import {
  from,
  Observable,
  of,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { RouterQuery } from '@datorama/akita-ng-router-store';
import { FormlyFieldConfig } from '@ngx-formly/core';

import {
  DialogFormComponent,
  DialogFormOptions,
} from '../formly/form-dialog.component';
import {
  formlyInput,
  formlySelect,
} from '../formly/formly-helpers';
import {
  Contact,
  CONTACT_OPTIONS,
  ContactsQuery,
  ContactsService,
} from '../store';
import { PromptService } from './';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class ContactUxService {
  constructor(
    private service: ContactsService,
    private query: ContactsQuery,
    private dialog: MatDialog,
    private prompt: PromptService,
    private toast: ToastService,
    private routerQuery: RouterQuery
  ) {}

  // sync() {
  //   return this.service.syncCollection({ reset: true });
  // }

  // getRecord(id: string) {
  //   return this.query.getEntity(id);
  // }

  // getAll() {
  //   return this.query.getAll();
  // }

  selectEntity(id: string) {
    return this.query.selectEntity(id);
  }

  selectAllForActiveClient() {
    const clientId = this.routerQuery.getParams().clientId;
    return this.service.valueChanges([where('clientId', '==', clientId)]);
  }

  getEditFields(
    entry: Partial<Contact> = {},
    keys: string[] = []
  ): FormlyFieldConfig[] {
    return (
      [
        formlyInput({ key: 'description' }),
        formlySelect({ key: 'type', options: CONTACT_OPTIONS }),
        formlyInput({ key: 'value' }),
      ] as FormlyFieldConfig[]
    ).filter((thing) =>
      keys && keys.length ? keys.includes(thing.key as string) : true
    );
  }

  edit(contact: Contact = {}): Observable<string | null> {
    const options: DialogFormOptions = {
      fields: this.getEditFields(contact),
      model: contact,
      title: contact.id ? 'Edit Contact' : 'Add Contact',
      remove: contact.id ? () => this.remove(contact) : undefined,
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    return dialog.afterClosed().pipe(
      switchMap((val) => {
        if (!val) {
          this.toast.open(
            `${contact.id ? 'Edit contact' : 'Add contact'} cancelled`
          );
          return of(null);
        }
        return from(this.saveRecord(val));
      })
    );
  }

  async saveRecord(model: Contact): Promise<string> {
    if (!model.clientId) {
      model.clientId = this.routerQuery.getParams().clientId;
    }

    if (model.id) {
      return this.service.update(model).then(() => {
        this.toast.open(`"${model.description}" Updated`);
        return model.id as string;
      });
    }
    return this.service.add(model).then((val) => {
      this.toast.open(`"${model.description}" Added`);
      return val;
    });
  }

  remove(model: Contact) {
    this.prompt
      .confirm(`Delete contact: "${model.description}"`)
      .subscribe((val) => {
        if (!val) {
          return;
        }
        this.dialog.closeAll();
        this.service
          .remove(model.id as string)
          .then(() => this.toast.open(`"${model.description}" removed`));
      });
  }
}
