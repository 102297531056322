import { Injectable } from '@angular/core';

import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import { Timestamp } from 'firebase/firestore';
import { AuthService } from '../../services';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { Note } from './note.model';
import { NotesState, NotesStore } from './notes.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'notes' })
export class NotesService extends CollectionService<NotesState> {
  constructor(
    store: NotesStore,
    private routerQuery: RouterQuery,
    private auth: AuthService
  ) {
    super(store);
  }

  get path() {
    const companyId = this.routerQuery.getParams()?.companyId;
    return `companies/${companyId}/notes`;
  }

  formatToFirestore(model: Note) {
    return {
      createdAt: Timestamp.now(),
      createdBy: this.auth.currentUserId,
      ...this.routerQuery.getParams(),
      ...model,
      updatedAt: Timestamp.now(),
    };
  }
}
