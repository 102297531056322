import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

import print from 'print-js';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { yearOptions } from 'src/app/formly';
import {
  ClientInsuranceProviderUxService,
  ClientUxService,
  InsuranceProviderUxService,
  PromptService,
  UserUxService,
} from 'src/app/services';
import { ServiceLogUxService } from 'src/app/services/service-log-ux.service';
import { UxQuery, UxState, UxStore } from 'src/app/store';
import {
  convertToCSVAndDownload,
  printableCss,
} from 'src/app/util/client-util';
import { PaginatedResults } from 'src/app/util/pageinated-results.interface';

import { Order } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';

interface Item {
  clientInsuranceProviderId: string;
  insuranceProviderId: string;
  clientId: string;
  clientInsuranceProvider: string;
  client: string;
  minutes: number;
  units: number;
}

@UntilDestroy()
@Component({
  selector: 'app-unprocessed-billing',
  templateUrl: './unprocessed-billing.component.html',
  styles: [],
})
export class UnprocessedBillingComponent implements OnInit {
  filterForm: UntypedFormGroup = new UntypedFormGroup({});

  filterModel: Partial<UxState['unProcessedBilling']> = {};

  filterFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'form-grid',
      fieldGroup: [
        {
          key: 'year',
          type: 'select',
          templateOptions: {
            label: 'Year',
            options: yearOptions,
          },
        },
        this.providerUx.providerSelectInput(false, false, true),
        this.userUx.userSelectInput(false, false, true),
        this.cUx.clientSelectInput(),
      ],
    },
  ];

  items: PaginatedResults<Item>;

  columns = [`client`, 'clientInsuranceProvider', 'minutes', 'units'];

  constructor(
    private uxStore: UxStore,
    private uxQuery: UxQuery,
    private sUx: ServiceLogUxService,
    private ciPUx: ClientInsuranceProviderUxService,
    private cUx: ClientUxService,
    private providerUx: InsuranceProviderUxService,
    private userUx: UserUxService,
    private prompt: PromptService
  ) {
    this.filterModel = { ...this.uxQuery.getValue().unProcessedBilling };
  }

  ngOnInit(): void {
    this.filterForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((val: Partial<UxState['unProcessedBilling']>) => {
        this.uxStore.updateBilling(val);
      });

    combineLatest([
      this.sUx.selectAll(),
      this.uxQuery.selectUnprocessedBillingFilters,
    ])
      .pipe(
        map(([logs, filters]) => {
          const rowMap: {
            [key: string]: Item;
          } = {};
          logs
            .filter(log =>
              filters.userId ? log.userId === filters.userId : true
            )
            .filter(log =>
              filters.insuranceProviderId
                ? log.insuranceProviderId === filters.insuranceProviderId
                : true
            )
            .filter(log =>
              filters.clientId ? log.clientId === filters.clientId : true
            )
            .forEach(log => {
              const key = log.clientInsuranceProviderId;

              if (rowMap[key]) {
                rowMap[key].minutes += log.minutes;
                rowMap[key].units += log.units;
              } else {
                rowMap[key] = {
                  clientId: log.clientId,
                  clientInsuranceProviderId: log.clientInsuranceProviderId,
                  insuranceProviderId: log.insuranceProviderId,
                  minutes: log.minutes,
                  units: log.units,
                  client: '',
                  clientInsuranceProvider: '',
                };
              }
            });

          const sorted: Item[] = Object.values(rowMap)
            .map(v => {
              return {
                ...v,
                clientInsuranceProvider:
                  this.ciPUx.getEntity(v.clientInsuranceProviderId)?.name || '',
                client: this.cUx.getEntity(v.clientId)?.displayName || '',
              };
            })
            .sort((a, b) => {
              if (filters.sortBy === 'minutes') {
                return a.minutes > b.minutes
                  ? 1
                  : a.minutes === b.minutes
                  ? 0
                  : -1;
              }
              return a[filters.sortBy].localeCompare(b[filters.sortBy]);
            });

          if (filters.sortByOrder === Order.DESC) {
            sorted.reverse();
          }

          return <PaginatedResults>{
            results: sorted.slice(
              filters.pageSize * filters.page,
              filters.pageSize * filters.page + filters.pageSize
            ),
            pageIndex: filters.page,
            length: sorted.length,
            pageSize: filters.pageSize,
            allResults: sorted,
          };
        }),
        untilDestroyed(this)
      )
      .subscribe(val => (this.items = val));
  }

  sortChange(ev: Sort) {
    this.uxStore.updateBilling({
      sortBy: ev.active as any,
      sortByOrder: ev.direction,
    });
  }

  pageChange(val: PageEvent) {
    this.uxStore.updateBilling({
      page: val.pageIndex,
      pageSize: val.pageSize,
    });
    document.getElementsByTagName('app-unprocessed-billing')[0].scrollIntoView({
      behavior: 'smooth',
    });
  }

  print() {
    this.prompt
      .alert(
        'Note this will only print the current page of results. ' +
          'Note: You cause use the download feature to ' +
          'download all the records into a spreadsheet.'
      )
      .subscribe(() => {
        print({
          header: 'Unprocessed Billing',
          printable: 'unprocessed-billing-table',
          type: 'html',
          targetStyles: '*',
          style: printableCss,
        });
      });
  }

  download() {
    this.prompt
      .confirm('This will download the results as a CSV file')
      .subscribe(val => {
        if (!val) {
          return;
        }
        convertToCSVAndDownload(
          this.items.allResults.map(d => ({
            client: d.client,
            provider: d.clientInsuranceProvider,
            minutes: d.minutes,
            units: d.units,
          })),
          'unprocessed-billing'
        );
      });
  }
}
