import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Timestamp } from 'firebase/firestore';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FormlyFieldConfig } from '@ngx-formly/core';

import {
  DialogFormComponent,
  DialogFormOptions,
} from '../formly/form-dialog.component';
import {
  booleanSelectInput,
  convertListToOptions,
  convertModelsToOptions,
  formlyInput,
} from '../formly/formly-helpers';
import { Client, ClientQuery, ClientService, UxQuery, UxState } from '../store';
import { getDateTime, parseISO } from '../util/date-util';
import { PaginatedResults } from '../util/pageinated-results.interface';
import { AuthService, PromptService } from './';
import { FileStackService } from './filestack.service';
import { ToastService } from './toast.service';
import { UserUxService } from './user-ux.service';

@Injectable({ providedIn: 'root' })
export class ClientUxService {
  constructor(
    private service: ClientService,
    private query: ClientQuery,
    private dialog: MatDialog,
    private prompt: PromptService,
    private toast: ToastService,
    private userUxService: UserUxService,
    private uxQuery: UxQuery,
    private fileStack: FileStackService,
    private auth: AuthService
  ) {}

  /**
   * @param props
   * @returns
   */
  clientSelectInput(
    props: {
      required?: boolean;
      openSelectBox?: boolean;
    } = {}
  ): FormlyFieldConfig {
    return {
      type: 'select-search',
      key: 'clientId',
      templateOptions: {
        openSelectBox: props?.openSelectBox,
        required: props?.required,
        label: 'Client',
        labelProp: 'displayName',
        valueProp: 'id',
        compareWith: (a: Client, b: Client) => a && b && a === b,
        searchObs: (term: string, value: string) => {
          const _term = term.toLowerCase();
          return this.query
            .selectAll({
              limitTo: 25,
              filterBy: c =>
                c.isActive &&
                (this.auth.isProjectAdmin ||
                  c.userId === this.auth.currentUserId) &&
                c.sortProp!.includes(_term),
            })
            .pipe(
              map(clients => {
                if (value) {
                  clients.unshift(this.getEntity(value));
                }
                if (!props.required) {
                  clients.unshift({ displayName: 'All', id: null });
                }

                return clients;
              })
            );
        },
      },
    };
  }

  sync() {
    return this.service.syncCollection({
      reset: true,
    });
  }

  getEntity = this.query.getEntity.bind(this.query);

  getAll = this.query.getAll.bind(this.query);

  selectEntity = this.query.selectEntity.bind(this.query);

  selectAll = this.query.selectAll.bind(this.query);

  filterAndSortClients(
    clients: Client[],
    filters: UxState['client']
  ): Client[] {
    const qs = filters.q ? filters.q.replace(/,/, '').split(/\s/) : [];

    const results = clients
      .filter(c => (filters.hideInactive ? c.isActive : true))
      .filter(c => (qs.length ? qs.every(i => c.sortProp.includes(i)) : true))
      .filter(c => (filters.userId ? c.userId === filters.userId : true))
      .sort((a, b) => {
        switch (filters.sortBy) {
          case 'firstName':
          case 'lastName':
            return a[filters.sortBy].localeCompare(b[filters.sortBy]);
          case 'minutesMonth':
            return a.currentStats.month.minutes - b.currentStats.month.minutes;
          case 'minutesYear':
            return a.currentStats.year.minutes - b.currentStats.year.minutes;
          case 'unitsMonth':
            return a.currentStats.month.units - b.currentStats.month.units;
          case 'unitsYear':
            return a.currentStats.year.units - b.currentStats.year.units;
          default:
            return 0;
        }
      });

    if (filters.sortByOrder === 'desc') {
      results.reverse();
    }

    return results;
  }

  getForDownload(): Client[] {
    const clients = this.getAll();
    const filters = this.uxQuery.getValue().client;
    return this.filterAndSortClients(clients, filters).map(c => {
      const user = this.userUxService.getEntity(c.userId);
      return {
        firstName: c.firstName,
        lastName: c.lastName,
        isActive: c.isActive,
        dateOfBirth: c.dateOfBirth,
        notes: c.notes,
        address: c.address,
        user: user?.displayName,
        minutesMonth: c.currentStats.month.minutes,
        minutesYear: c.currentStats.year.minutes,
        unitsMonth: c.currentStats.month.units,
        unitsYear: c.currentStats.year.units,
      };
    });
  }

  selectForList(): Observable<PaginatedResults<Client>> {
    return combineLatest([
      this.selectAll(),
      this.uxQuery.selectClientFilters,
    ]).pipe(
      map(([clients, filters]) => {
        const results = this.filterAndSortClients(clients, filters);
        return {
          results: results.slice(
            filters.pageSize * filters.page,
            filters.pageSize * filters.page + filters.pageSize
          ),
          pageIndex: filters.page,
          length: results.length,
          pageSize: filters.pageSize,
        };
      })
    );
  }

  getListOptions() {
    return convertListToOptions(
      this.getAll().map(v => v.displayName as string)
    );
  }

  getModelOptions() {
    return convertModelsToOptions(this.getAll(), 'displayName');
  }

  getEditFields(
    entry: Partial<Client> = {},
    keys: string[] = []
  ): FormlyFieldConfig[] {
    return (
      [
        formlyInput({ key: 'firstName' }),
        formlyInput({ key: 'lastName' }),
        formlyInput({ key: 'address', type: 'textarea', required: false }),
        {
          key: 'dateOfBirth',
          type: 'datepicker',
          templateOptions: {
            label: 'Date of birth',
            required: true,
            datepickerOptions: {
              startView: 'multi-year',
            },
          },
        },
        {
          key: 'socialSecurityNumber',
          type: 'input',
          templateOptions: {
            type: 'password',
            label: 'Social Security Number',
            required: false,
          },
        },
        formlyInput({ key: 'notes', type: 'textarea', required: false }),
        {
          key: 'userId',
          type: 'select',
          templateOptions: {
            required: true,
            labelProp: 'displayName',
            valueProp: 'id',
            label: 'Assign to Staff',
            options: this.userUxService.selectAll(),
            // compareWith: (a: any, b: any) => a && b && a === b,
            // searchObs: (term: string) =>
            //   this.userUxService.selectFilteredOptions(term),
          },
        },
        booleanSelectInput('isActive', 'Is Active'),
      ] as FormlyFieldConfig[]
    ).filter(thing =>
      keys && keys.length ? keys.includes(thing.key as string) : true
    );
  }

  edit(client: Client = {}): Observable<string | null> {
    /**
     * Using getDateTime because it handles all the different types of dates
     */
    const bob = client.dateOfBirth
      ? getDateTime(client.dateOfBirth).toISO()
      : null;

    const model: Client = {
      ...client,
      dateOfBirth: bob,
      ...(client.dateOfBirth ? { dateString: parseISO(bob) as any } : {}),
    };

    const options: DialogFormOptions = {
      fields: this.getEditFields(client),
      model: model,
      title: client.id ? 'Edit Client' : 'Add Client',
      remove: client.id ? () => this.remove(client) : undefined,
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    return dialog.afterClosed().pipe(
      switchMap(val => {
        if (!val) {
          this.toast.open(
            `${client.id ? 'Edit client' : 'Add client'} cancelled`
          );
          return of(null);
        }
        return from(this.saveRecord(val));
      })
    );
  }

  async saveRecord(model: Client): Promise<string> {
    const recordToSave = {
      ...model,
      dateOfBirth: getDateTime(model.dateOfBirth).toISO(),
    };

    if (recordToSave.id) {
      return this.service.update(recordToSave).then(() => {
        this.toast.open(`"${recordToSave.displayName}" Updated`);
        return recordToSave.id as string;
      });
    }
    recordToSave.deletedAt = null;
    return this.service.add(recordToSave).then(val => {
      this.toast.open(`"${recordToSave.displayName}" Added`);
      return val;
    });
  }

  remove(model: Client) {
    return this.prompt.confirm(`Delete client: "${model.displayName}"`).pipe(
      map(val => {
        if (!val) {
          return false;
        }
        this.dialog.closeAll();
        this.service
          .update({
            ...model,
            deletedAt: Timestamp.now(),
          })
          .then(() => {
            this.toast.open(`"${model.displayName}" removed`);
          });
        return true;
      })
    );
  }

  pickNewPhoto(id: string) {
    this.fileStack.pickPhoto({ path: 'photos' }, resp => {
      this.service.update(id, { hasPhoto: true, photoUrl: resp.key });
      this.toast.success('Photo added');
    });
  }

  removePhoto(id: string) {
    this.prompt.confirm('Remove the current photo?').subscribe(val => {
      if (val) {
        this.service.update(id, { hasPhoto: false });
        this.toast.success('Photo removed');
      }
    });
  }
}
