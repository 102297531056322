import { Injectable } from '@angular/core';
import {
  limit,
  orderBy,
  QueryConstraint,
  startAfter,
  startAt,
  Timestamp,
} from '@angular/fire/firestore';

import { CollectionConfig, CollectionService } from 'akita-ng-fire';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { ServiceLog } from './service-log.model';
import { ServiceLogState, ServiceLogStore } from './service-log.store';
import { getDocs, query } from 'firebase/firestore';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'companies/:companyId/service_logs' })
export class ServiceLogService extends CollectionService<ServiceLogState> {
  constructor(store: ServiceLogStore, private routerQuery: RouterQuery) {
    super(store);
  }

  get path() {
    const companyId = this.routerQuery.getParams()?.companyId;
    return `companies/${companyId}/service_logs`;
  }

  async getAll(
    orderField: keyof ServiceLog,
    q: QueryConstraint[] = []
  ): Promise<ServiceLog[]> {
    const allRecords: ServiceLog[] = [];
    const _q = query(this.collection);
    // defaulting to desc just 'because'
    q.push(orderBy(orderField, 'desc'), limit(500));
    let currentQuery = await getDocs(query(this.collection, ...q));
    // let currentPage: ServiceLog[] = await getDocs(query(this.collection, ...q));
    while (!currentQuery.empty) {
      allRecords.push(
        ...currentQuery.docs.map(i => {
          return { ...i.data(), id: i.id } as ServiceLog;
        })
      );
      currentQuery = await getDocs(
        query(
          this.collection,
          ...q,
          startAfter(currentQuery.docs[currentQuery.docs.length - 1])
        )
      );
    }
    return allRecords;
  }

  formatToFirestore(model: ServiceLog) {
    return {
      ...this.routerQuery.getParams(),
      ...model,
      updatedAt: Timestamp.now(),
    };
  }
}
