import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { SelectOptions } from '../formly/formly-helpers';

export const filterNullOrUndefined =
  () =>
  <T>(source: Observable<T>) =>
    source.pipe(filter(value => value !== undefined && value !== null));

export const addEmptySelect =
  (blankLabel = '') =>
  (source: Observable<SelectOptions>) =>
    source.pipe(
      map(
        (value): SelectOptions => [
          {
            value: null,
            label: blankLabel,
          },
          ...value,
        ]
      )
    );

export const consoleOut = (label?: string) => (source: Observable<any>) =>
  source.pipe(
    tap(value => {
      if (label) {
        console.log(label, value);
      }
      console.log(value);
    })
  );

export const rxConvertModelsToOptions =
  <T = { id: string; [key: string]: any }>(
    displayProp: keyof T,
    valueProp?: keyof T
  ) =>
  (source: Observable<T[]>) =>
    source.pipe(
      map(
        (value): SelectOptions =>
          value.map(v => {
            return {
              value: valueProp ? v[valueProp] : v,
              label: v[displayProp],
            };
          })
      )
    );

// export const filterEmpty = () => <T>(source: Observable<T>) =>
//   source.pipe(filter(value => !isEmpty(value)));
