import {
  Component,
  OnInit,
} from '@angular/core';

import { CategoryUxService } from 'src/app/services';
import { Category } from 'src/app/store';

import {
  UntilDestroy,
  untilDestroyed,
} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  categories: Category[] = [];
  columns = ['name', 'delete'];

  constructor(private categoryUxService: CategoryUxService) {}

  ngOnInit(): void {
    this.categoryUxService
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe((val) => (this.categories = val));
  }

  edit() {
    this.categoryUxService.edit().subscribe();
  }

  remove(model: Category) {
    this.categoryUxService.remove(model);
  }
}
