import {
  Component,
  OnInit,
} from '@angular/core';

import { LocationUxService } from 'src/app/services';
import { Location } from 'src/app/store';

import {
  UntilDestroy,
  untilDestroyed,
} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  locations: Location[] = [];
  columns = ['name', 'delete'];

  constructor(private locationUxService: LocationUxService) {}

  ngOnInit(): void {
    this.locationUxService
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe((val) => (this.locations = val));
  }

  edit() {
    this.locationUxService.edit().subscribe();
  }

  remove(model: Location) {
    this.locationUxService.remove(model);
  }
}
