import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

import { monthOptions, yearOptions } from 'src/app/formly';
import {
  AuthService,
  ClientUxService,
  InsuranceProviderUxService,
  UserUxService,
} from 'src/app/services';
import { ServiceLogUxService } from 'src/app/services/service-log-ux.service';
import { ServiceLog, UxQuery, UxState, UxStore } from 'src/app/store';
import { convertToCSVAndDownload } from 'src/app/util/client-util';
import { PaginatedResults } from 'src/app/util/pageinated-results.interface';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';

@UntilDestroy()
@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss'],
})
export class ServiceListComponent implements OnInit {
  filterForm: UntypedFormGroup = new UntypedFormGroup({});

  filterModel: Partial<UxState['service']> = {};

  data: PaginatedResults<ServiceLog>;

  get header() {
    return `Case Notes (${this.data.length})`;
  }

  loading: boolean = true;

  constructor(
    private uxStore: UxStore,
    private uxQuery: UxQuery,
    private serviceUx: ServiceLogUxService,
    private userUx: UserUxService,
    private auth: AuthService,
    private insuranceProviderUx: InsuranceProviderUxService,
    private clientUx: ClientUxService
  ) {}

  ngOnInit(): void {
    this.serviceUx.loading().subscribe(val => (this.loading = val));
    this.filterModel = { ...this.uxQuery.getValue().service };
    this.serviceUx.syncForList().pipe(untilDestroyed(this)).subscribe();
    this.serviceUx
      .selectForList()
      .pipe(untilDestroyed(this))
      .subscribe(val => {
        this.data = val;
      });
  }

  ngAfterViewInit() {
    this.filterForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((val: Partial<UxState['service']>) => {
        val.syncChange = this.uxQuery.getValue().service.syncChange + 1;
        this.uxStore.updateService({ ...val, page: 0 });
      });
  }

  filterFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'form-grid',
      fieldGroup: [
        {
          key: 'year',
          type: 'select',
          templateOptions: {
            label: 'Year',
            options: yearOptions,
          },
        },
        {
          key: 'month',
          type: 'select',
          templateOptions: {
            label: 'Month',
            options: monthOptions,
          },
        },
        this.auth.isProjectAdmin
          ? this.userUx.userSelectInput(false, false, true)
          : null,
      ].filter(Boolean),
    },
  ];

  add() {
    this.serviceUx.edit().subscribe();
  }

  pageChange(val: PageEvent) {
    this.uxStore.updateService({
      page: val.pageIndex,
      pageSize: val.pageSize,
    });
    document.getElementsByTagName('app-service-list')[0].scrollIntoView({
      behavior: 'smooth',
    });
  }

  sortChange(val: Sort) {
    this.uxStore.updateService({
      sortBy: val.active as any,
      sortByOrder: val.direction as any,
    });
  }

  download() {
    const data = this.data.allResults.map(i => {
      return {
        PerformedBy: this.userUx.getEntity(i.userId).displayName,
        InsuranceProvider: this.insuranceProviderUx.getEntity(
          i.insuranceProviderId
        ).name,
        Client: this.clientUx.getEntity(i.clientId).displayName,
        Date: i.dateString,
        Time: i.startTimeString,
        Minutes: i.minutes,
        'Estimated units': i.units,
        Location: i.location,
        Category: i.category,
        Notes: i.notes,
        'Updated By': this.userUx.getEntity(i.updatedById).displayName,
      };
    });

    convertToCSVAndDownload(
      data,
      `service-list-${this.filterModel.year}-${this.filterModel.month}`
    );
  }
}
