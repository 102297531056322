import {
  AfterViewInit,
  Component,
  Inject,
  Injectable,
  NgZone,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import * as filestack from 'filestack-js';
import { Client } from 'filestack-js/build/main/lib/client';
import { PickerOptions } from 'filestack-js/build/main/lib/picker';

const apiKey = 'AKEwVuHHYQoaOheCpw7mUz';

export interface FileStackClientConfig {
  public: {
    key: string;
  };
  private: {
    key: string;
    policy: string;
    signature: string;
  };
}

// interface FileStackOptions {
//   fromSources?: string[];
//   minFiles?: number;
//   maxFiles?: number;
//   accept?: string[];
//   maxSize?: number;
//   imageMax?: [number, number];
//   storeTo?: {
//     container: 'ds.plakeside';
//     location: 's3';
//     region: 'us-east-1';
//   };
//   dropPane?: any;
// }

interface PickOptions {
  path: 'files' | 'photos';
  isImage?: boolean;
}

export type ApiSingleFileStackCallBack = (
  file: filestack.PickerFileMetadata
) => void;

export type ApiMultiFileStackCallBack = (
  files: filestack.PickerFileMetadata[]
) => void;

@Injectable({
  providedIn: 'root',
})
export class FileStackService {
  fileStackClient: Client;

  constructor(private ngZone: NgZone, private dialog: MatDialog) {
    this.fileStackClient = filestack.init(apiKey);
  }

  // getPrivateLink(handle: string) {
  //   // this.fileStackPrivateClient.FileLin
  //   return new filestack.Filelink(handle).security(this.security).toString();
  // }

  // previewSecureFile(handle: string) {
  //   this.dialog.open(FilePreviewComponent, {
  //     data: { handle, client: this.fileStackPrivateClient },
  //     height: '90vh',
  //     width: '90vw',
  //     id: 'preview',
  //     closeOnNavigation: true,
  //   });
  // }

  previewPublicFile(handle: string) {
    this.fileStackClient.preview(handle);
  }

  pickPhoto(opts: PickOptions, cb?: ApiSingleFileStackCallBack) {
    const stackOptions: Partial<PickerOptions> = {
      cleanupImageExif: {
        keepOrientation: true,
        keepICCandAPP: true,
      },
      transformations: {
        rotate: true,
      },
      storeTo: {
        location: 's3',
        path: `/apps/cno/${opts.path}/`,
      },
      imageDim: [300, 300],
      accept: ['image/*'],
    };

    return this.fileStackClient
      .picker({
        ...this.fromDefaults(stackOptions),
        onUploadDone: resp => {
          this.ngZone.run(() => {
            return cb(resp.filesUploaded[0]);
          });
        },
      })
      .open();
  }

  pickOne(opts: PickOptions, cb?: ApiSingleFileStackCallBack): Promise<void> {
    const stackOptions: Partial<PickerOptions> = {
      cleanupImageExif: {
        keepOrientation: true,
        keepICCandAPP: true,
      },
      transformations: {
        rotate: true,
      },
      storeTo: {
        location: 's3',
        path: `/apps/cno/${opts.path}/`,
      },
    };

    if (opts.isImage) {
      stackOptions.accept = ['image/*'];
    }

    return this.fileStackClient
      .picker({
        ...this.fromDefaults(stackOptions),
        onUploadDone: resp => {
          this.ngZone.run(() => {
            return cb(resp.filesUploaded[0]);
          });
        },
      })
      .open();
  }

  pickMany(opts: PickOptions, cb?: ApiMultiFileStackCallBack): Promise<void> {
    const stackOptions: Partial<PickerOptions> = {
      maxFiles: 25,
      cleanupImageExif: {
        keepOrientation: true,
        keepICCandAPP: true,
      },
      transformations: {
        rotate: true,
      },
      storeTo: {
        location: 's3',
        path: `/apps/cno/${opts.path}/`,
      },
    };

    if (opts.isImage) {
      stackOptions.accept = ['image/*'];
    }

    return this.fileStackClient
      .picker({
        ...this.fromDefaults(stackOptions),
        onUploadDone: resp => {
          this.ngZone.run(() => {
            return cb(resp.filesUploaded);
          });
        },
      })
      .open();
  }

  fromDefaults(opts: Partial<PickerOptions>): PickerOptions {
    const defaults = {
      fromSources: [
        'local_file_system',
        'url',
        'imagesearch',
        'facebook',
        'instagram',
        'googledrive',
        'dropbox',
        'webcam',
        'video',
        'audio',
        'box',
        'github',
        'gmail',
        'picasa',
        'onedrive',
        'onedriveforbusiness',
      ],
      minFiles: 1,
      maxFiles: 1,
      // accept: ['image/*', '.pdf', 'text/plain'],
      maxSize: 50 * 1024 * 1024,
      // dropPane: null
    };

    return { ...defaults, ...opts };
  }
}

@Component({
  selector: 'ds-file-preview',
  template: ` <div class="close" (click)="close()">
    <span> Close (esc) </span>
  </div>`,
  styles: [``],
})
export class FilePreviewComponent implements OnInit, AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<FilePreviewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      handle: string;
      client: Client;
    }
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.data.client.preview(this.data.handle, { id: 'preview' });
  }

  close() {
    this.dialogRef.close();
  }
}
