export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function convertToCSVAndDownload(
  data: any[],
  fileName: string,
  headers?: string[]
) {
  downloadFileInline(convertToCSVFile(data, headers), fileName);
}

export function convertToCSVFile(
  objArray: any[],
  headers: string[] = []
): string {
  const new_array = [];

  let _headers: string[];
  if (headers.length === 0) {
    _headers = Object.keys(objArray[0]);
  } else {
    _headers = headers;
  }

  new_array.push(_headers.join(','));
  objArray.forEach(item => {
    new_array.push(_headers.map(h => JSON.stringify(item[h] || '')).join(','));
  });

  return new_array.join('\r\n');
}

export function downloadFileInline(
  data: string,
  filename: string,
  type?: string
) {
  var blob = new Blob([data], { type: type || 'text/csv;charset=utf-8;' });

  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.download = filename;
  anchor.href = url;
  anchor.click();
  anchor.remove();
}

export function downloadUrl(url: string, filename: string) {
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.download = filename;
  anchor.href = url;
  anchor.target = '_blank';
  anchor.click();
  anchor.remove();
}

export function tabDelimitedToJson<T = any>(data: string): T[] {
  const rows = data.trim().split('\n');
  const json: any[] = [];
  let keys: string[] = [];

  rows.forEach((value, index) => {
    if (index < 1) {
      // get the keys from the first row in the tab space file
      keys = value.split('\t');
    } else {
      // put the values from the following rows into object literals
      let values = value.split('\t');
      json[index - 1] = values
        .map((value, index) => {
          return {
            [keys[index]]: value.trim(),
          };
        })
        .reduce((currentValue, previousValue) => {
          return {
            ...currentValue,
            ...previousValue,
          };
        });
    }
  });

  // convert array of objects into json str, and then write it back out to a file
  return json;
}

const specialChars = /[^\w\d]/gi;

export function replaceSpecialCharacters(val: string) {
  return val.replace(specialChars, '');
}

export function randomShortKey() {
  return (Math.random() + 1).toString(36).substring(7);
}

export function isEmail(val: string) {
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(val);
}

export const printableCss = `
  @media print { @page {  size: A4 landscape; margin: 0; } body { margin: 1.6cm; } }
  table {
    max-width: 100%;
    table: width: 100%;
    margin: 0;
  }
  mat-icon,
  button {
    display: none!important;
  }
  table.table-alpha:not(.not-stripped) tr:nth-child(odd) > td {
    border-top: 2px solid black;
  }
  `;
