import { Injectable } from '@angular/core';

import {
  Order,
  QueryConfig,
  QueryEntity,
} from '@datorama/akita';

import {
  UserState,
  UserStore,
} from './user.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'sortProp',
  sortByOrder: Order.ASC,
})
export class UserQuery extends QueryEntity<UserState> {
  constructor(protected store: UserStore) {
    super(store);
  }
}
