import { Injectable } from '@angular/core';

import { CollectionConfig, CollectionService } from 'akita-ng-fire';

import { RouterQuery } from '@datorama/akita-ng-router-store';

import { ApiFile } from './file.model';
import { FilesState, FilesStore } from './files.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'files' })
export class FilesService extends CollectionService<FilesState> {
  constructor(store: FilesStore, private routerQuery: RouterQuery) {
    super(store);
  }

  get path() {
    const companyId = this.routerQuery.getParams()?.companyId;
    return `companies/${companyId}/files`;
  }

  formatToFirestore(model: ApiFile) {
    return {
      ...this.routerQuery.getParams(),
      ...model,
    };
  }
}
