import { Injectable } from '@angular/core';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TrackJS } from 'trackjs';

import versionInfo from '../../version.json';
import { User } from '../store';
import { PromptService } from './';
import { AuthService } from './auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.isLoggedIn().pipe(
      map(v => {
        if (v) {
          if (TrackJS.isInstalled()) {
            console.log('attaching user to TrackJS');
            TrackJS.configure({
              userId: this.authService.currentUserId,
              version: versionInfo.date,
            });
          }
          return true;
        }
        return this.router.createUrlTree(['/login']);
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private prompt: PromptService,
    private firestore: Firestore
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const companyId = route.params.companyId;
    const userId = this.authService.currentUserId;
    const key = `companies/${companyId}/users/${userId}`;
    return from(getDoc(doc(this.firestore, key))).pipe(
      map(val => {
        if (val.exists()) {
          const user: User = val.data();
          if (user.access === 'disabled') {
            this.prompt.alert('Access denied');
            return false;
          }
          this.authService.projectUser = user;
          this.authService.projectUser.displayName = `${user.lastName}, ${user.firstName}`;
          this.authService.isProjectAdmin = user.access === 'admin';
          return true;
        }
        this.prompt.alert('Access denied');
        return false;
      })
    );
  }
}
