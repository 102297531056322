<app-title-bar header="Insurance Providers">
  <button mat-button (click)="edit()" color="primary">
    <mat-icon>add_box</mat-icon>
    <span>Add Provider</span>
  </button>
</app-title-bar>

<table class="table-alpha" mat-table #table [dataSource]="insuranceProviders" *ngIf="insuranceProviders.length">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let row">{{ row.name }}</td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row" class="actions">
      <button mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row class="pointer" (click)="edit(row)" *matRowDef="let row; columns: columns"></tr>
</table>
<app-no-results *ngIf="!insuranceProviders.length"></app-no-results>
