import { Component, OnInit } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

import { FileUxService } from '../services/file-ux.service';
import { ApiFile } from '../store';

@UntilDestroy()
@Component({
  selector: 'app-file-table',
  template: ` <table
      class="table-alpha"
      mat-table
      #table
      [dataSource]="files"
      *ngIf="files.length"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row">
          <a [href]="getLink(row)" target="_blank" [download]="row.name">{{
            row.name
          }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>Size (kb)</th>
        <td mat-cell *matCellDef="let row" class="right">
          {{ row.size / 1000 | number: '1.0-2' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="actions">
          <button mat-button (click)="delete(row)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        (click)="log(row)"
        class="pointer"
        *matRowDef="let row; columns: columns"
      ></tr>
    </table>
    <app-no-results *ngIf="!files?.length"></app-no-results>`,
  styles: [],
})
export class FileTableComponent implements OnInit {
  files: ApiFile[] = [];
  columns = ['name', 'size', 'actions'];

  constructor(private fileUx: FileUxService) {}

  ngOnInit(): void {
    this.fileUx.selectAllForActiveClient().subscribe(val => {
      this.files = val;
    });
  }

  getLink(row: ApiFile) {
    return 'https://s3.amazonaws.com/plakeside.com/' + row.location;
  }

  delete(file: ApiFile) {
    this.fileUx.remove(file);
  }

  log(stuff: ApiFile) {
    // console.log(stuff);
    // this.fileUx.preview(stuff);
  }
}
