import { Component, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-file',
  template: `
    <input type="file" [formControl]="control" [formlyAttributes]="field" />
  `,
  styles: [
    `
      :host {
        display: block;
        padding: 15px 10px;
        border: 1px solid lightgray;
        margin-bottom: 10px;
        border-radius: 4px;
      }
    `,
  ],
})
export class FormlyFieldFile extends FieldType {
  get control() {
    return this.formControl as UntypedFormControl;
  }

  constructor() {
    super();
  }
}

import { Directive } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { throwIfEmpty } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line
  selector: 'input[type=file]',
  host: {
    '(change)': 'onChange($event.target.files)',
    '(blur)': 'onTouched()',
  },
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FileValueAccessor, multi: true },
  ],
})
// https://github.com/angular/angular/issues/7341
export class FileValueAccessor implements ControlValueAccessor {
  value: any;
  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(value: any) {}
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
