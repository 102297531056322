import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { FormlyFieldConfig } from '@ngx-formly/core';

import {
  DialogFormComponent,
  DialogFormOptions,
} from '../formly/form-dialog.component';
import {
  convertListToOptions,
  convertModelsToOptions,
  formlyInput,
} from '../formly/formly-helpers';
import { Category, CategoryQuery, CategoryService } from '../store';
import { PromptService } from './';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class CategoryUxService {
  constructor(
    private service: CategoryService,
    private query: CategoryQuery,
    private dialog: MatDialog,
    private prompt: PromptService,
    private toast: ToastService
  ) {}

  sync() {
    return this.service.syncCollection({ reset: true });
  }

  getEntity = this.query.getEntity.bind(this.query);

  getAll = this.query.getAll.bind(this.query);

  selectEntity = this.query.selectEntity.bind(this.query);

  selectAll = this.query.selectAll.bind(this.query);

  getListOptions() {
    return convertListToOptions(this.getAll().map(v => v.name as string));
  }

  getModelOptions() {
    return convertModelsToOptions(this.getAll(), 'name');
  }

  getEditFields(
    entry: Partial<Category> = {},
    keys: string[] = []
  ): FormlyFieldConfig[] {
    return ([formlyInput({ key: 'name' })] as FormlyFieldConfig[]).filter(
      thing => (keys && keys.length ? keys.includes(thing.key as string) : true)
    );
  }

  edit(category: Category = {}) {
    const options: DialogFormOptions = {
      fields: this.getEditFields(category),
      model: category,
      title: category.id ? 'Edit Category' : 'Add Category',
      remove: category.id ? () => this.remove(category) : undefined,
    };

    const dialog = this.dialog.open(DialogFormComponent, {
      data: options,
    });

    return dialog.afterClosed().pipe(
      switchMap(val => {
        if (!val) {
          this.toast.open(
            `${category.id ? 'Edit category' : 'Add category'} cancelled`
          );
          return of();
        }
        return from(this.saveRecord(val));
      })
    );
  }

  async saveRecord(model: Category): Promise<string> {
    if (model.id) {
      return this.service.update(model).then(() => {
        this.toast.success(`"${model.name}" Updated`);
        return model.id as string;
      });
    }
    return this.service.add(model).then(val => {
      this.toast.success(`"${model.name}" Added`);
      return val;
    });
  }

  remove(model: Category) {
    this.prompt.confirm(`Delete category: "${model.name}"`).subscribe(val => {
      if (!val) {
        return;
      }
      this.dialog.closeAll();
      this.service
        .remove(model.id as string)
        .then(() => this.toast.success(`"${model.name}" removed`));
    });
  }
}
