import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ServiceLogStore, ServiceLogState } from './service-log.store';

@Injectable({ providedIn: 'root' })
export class ServiceLogQuery extends QueryEntity<ServiceLogState> {

  constructor(protected store: ServiceLogStore) {
    super(store);
  }

}
