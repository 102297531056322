<app-title-bar header="Unit Report" [showBack]="true" [backRoute]="['../']">
  <button [disabled]="!resultsAvailable" mat-button (click)="print()">
    <mat-icon>print</mat-icon>
    <span>Print</span>
  </button>
</app-title-bar>

<app-report-filters type="unit"></app-report-filters>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<table
  id="print-table"
  class="table-alpha mat-table not-stripped"
  role="table"
  *ngIf="resultsAvailable"
>
  <thead>
    <tr class="mat-header-row">
      <th class="mat-cell">{{ mainLabel }}</th>
      <th class="mat-cell">Month</th>
      <th class="mat-cell">Category</th>
      <th class="mat-cell right">Units <app-unit-info></app-unit-info></th>
      <th class="mat-cell right">Hours</th>
    </tr>
  </thead>
  <tbody>
    <ng-container
      *ngFor="let main of results; first as mainFirst; odd as isOdd"
    >
      <ng-container *ngFor="let month of main.months; first as monthFirst">
        <tr
          *ngFor="let category of month.categories; first as catFirst"
          class="mat-row"
          [class.odd-stripe]="isOdd"
          [class.event-stripe]="!isOdd"
        >
          <td class="mat-cell">
            <span *ngIf="monthFirst && catFirst">{{
              main.groupedByLabel
            }}</span>
          </td>
          <td class="mat-cell">
            <span *ngIf="catFirst">
              {{ monthNames[month.month - 1] }}
              {{ month.year }}
            </span>
          </td>
          <td class="mat-cell">
            <span>{{ category.category }}</span>
          </td>
          <td class="mat-cell right">
            <span>{{ category.categoryTotalUnits }}</span>
          </td>
          <td class="mat-cell right">
            <span>{{ category.categoryTotalMinutes / 60 | number:'1.0-2' }}</span>
          </td>
        </tr>
        <tr
          class="mat-row subtotal"
          [class.odd-stripe]="isOdd"
          [class.event-stripe]="!isOdd"
        >
          <th class="mat-cell right subtotal" colspan="3">
            {{ monthNames[month.month - 1] }} {{ month.year }} total
          </th>
          <th class="mat-cell right subtotal">
            {{ month.monthTotalUnits }}
          </th>
          <td class="mat-cell right">
            <span>{{ month.monthTotalMinutes / 60 | number:'1.0-2' }}</span>
          </td>
        </tr>
      </ng-container>
      <tr
        class="mat-row subtotal"
        [class.odd-stripe]="isOdd"
        [class.event-stripe]="!isOdd"
      >
        <th class="mat-cell right subtotal" colspan="3">
          {{ main.groupedByLabel }} total
        </th>
        <th class="mat-cell right subtotal">
          {{ main.groupedByTotalUnits }}
        </th>
        <td class="mat-cell right">
          <span>{{ main.groupByTotalMinutes / 60 | number:'1.0-2' }}</span>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<mat-table style="display: none">
  <mat-header-row *matHeaderRowDef="[]"></mat-header-row>
</mat-table>
<app-no-results *ngIf="!loading && results && !results.length"></app-no-results>
