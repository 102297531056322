import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Payment } from '../store';

@Component({
  selector: 'app-payment-table',
  template: `
    <table
      class="table-alpha"
      mat-table
      #table
      [dataSource]="payments"
      *ngIf="payments.length"
    >
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let row">{{ row.amount | currency }}</td>
      </ng-container>

      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>Notes</th>
        <td mat-cell *matCellDef="let row">{{ row.notes }}</td>
      </ng-container>

      <ng-container matColumnDef="paidAt">
        <th mat-header-cell *matHeaderCellDef>PaidAt</th>
        <td mat-cell *matCellDef="let row">{{ row.paidAt | date }}</td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="actions">
          <button mat-button>
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        (click)="log(row)"
        class="pointer"
        *matRowDef="let row; columns: columns"
      ></tr>
    </table>
    <app-no-results *ngIf="!payments?.length"></app-no-results>
  `,
  styles: [],
})
export class PaymentTableComponent implements OnInit {
  @Input() payments: Payment[] = [];
  @Output() selected = new EventEmitter<Payment>();

  columns = ['paidAt', 'amount', 'notes'];

  constructor() {}

  ngOnInit(): void {}

  log(row: Payment) {
    this.selected.emit(row);
  }
}
